import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AnalysisH2h } from 'src/app/core/interface/analysis-h2h';
import { DateTime } from 'src/app/core/interface/date-time';
import { Event } from 'src/app/core/interface/event';
import { JackpotBookmaker } from 'src/app/core/interface/jackpot-bookmaker';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';


@Component({
  selector: 'app-analysis-event',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './analysis-event.component.html',
  styleUrls: ['./analysis-event.component.scss']
})
export class AnalysisEventComponent implements OnInit, OnDestroy {
  percentageInput: any;
  subscription: Subscription = new Subscription();
  fixtureDate: string;
  id: number;
  todayDate: DateTime;
  weekNumberToday: string;
  matchEvent: Event;
  table: any;
  eventOdds: any;
  eventSquad: any;
  analysisH2H: AnalysisH2h;
  analysisLastX: any;
  analysisAwayLastX: any;
  analysisHomeLastX: any;

  constructor(
    private dateTimeService: DateTimeService,
    private footballService: FootballService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subscription.add(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams.pipe(first()).subscribe(params => {
          let date = params['date'];
          let date1 = new Date(date);
          console.log("date1:", date1);
          let year = date1.getFullYear();
          let month = ((date1.getMonth() + 1).toString()).padStart(2, '0');
          let day = (date1.getDate().toString()).padStart(2, '0');

          this.fixtureDate = `${year}-${month}-${day}`;
        })

        this.id = Number(this.route.snapshot.paramMap.get('id1'));
        console.log("id:", this.id);
        console.log("eDate:", this.fixtureDate);
        this.subscription.add(dateTimeService.getCurrentDate().subscribe(
          (dateTime) => {
            this.todayDate = dateTime;
            this.weekNumberToday = ((dateTime.yearWeek).toString()).padStart(2, '0');

            this.footballService.getMatchById(this.fixtureDate, this.id).subscribe((event) => {
              this.matchEvent = event;

              if (event) {
                /* get H2H analysis */
                this.subscription.add(
                  this.footballService.getH2HAnalysis(this.fixtureDate, this.id).subscribe((h2h) => {
                    this.analysisH2H = h2h;
                  }));
                /* get last X analysis */
                this.subscription.add(
                  this.footballService.getLastXPoints(this.fixtureDate, event.homeTeamId).subscribe((homeLastX) => {
                    this.analysisHomeLastX = homeLastX;
                  }));
                this.subscription.add(
                  this.footballService.getLastXPoints(this.fixtureDate, event.awayTeamId).subscribe((awayLastX) => {
                    this.analysisAwayLastX = awayLastX;
                  }));
              }
            })

          }));
      }
    }))
  }
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {

    this.percentageInput = "100";
  }

}
