<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Top Scorer Analysis Points</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Top Scorer analysis points against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>

<div *ngIf="!isSearchMode">
    <table class="table table-sm flex-fill" *ngFor="let competition of competition$;let i = index">
        <thead>
            <tr class="small-tx">
                <h5>
                    <span class="fw-bold fst-italic"
                        *ngIf="competition.competitionName">{{competition.competitionName}}<br></span>
                    <span class="fst-italic" *ngIf="competition.countryName">{{competition.countryName}}</span>
                </h5>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Result</th>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">7</th>
                <th class="teams text-start">8</th>
                <th class="teams text-start">Adv</th>
                <th class="teams text-start">Result 1X2</th>
            </tr>
        </thead>
        <tbody *ngIf="eventObject">
            <tr class="cursor-pointer status-live small-tx"
            *ngFor="let event of (competition.events | keyvalue);let i = index;">
            <td class="time text-start">
                <div class="row text-bold" *ngIf="eventObject[event.key]"
                    [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                    <div class="col-xs" *ngIf="eventObject[event.key]"
                        [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                        <span
                            *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                            Vs</span><br>
                        <span
                            *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                    </div>
                </div>
            </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scorerCountAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scorerCountAdvantage">{{topScorerPointsObject$[event.key].scorerCountAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredAdvantage">{{topScorerPointsObject$[event.key].scoredAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredHomeAdvantage">{{topScorerPointsObject$[event.key].scoredHomeAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredAwayAdvantage">{{topScorerPointsObject$[event.key].scoredAwayAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredH1AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredH1Advantage">{{topScorerPointsObject$[event.key].scoredH1Advantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredH2AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredH2Advantage">{{topScorerPointsObject$[event.key].scoredH2Advantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredLastGameAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredLastGameAdvantage">{{topScorerPointsObject$[event.key].scoredLastGameAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span [class]="topScorerPointsObject$[event.key].scoredTeamLastGameAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topScorerPointsObject$[event.key].scoredTeamLastGameAdvantage">{{topScorerPointsObject$[event.key].scoredTeamLastGameAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                        <span [class]="topScorerPointsObject$[event.key].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="topScorerPointsObject$[event.key].adv1X2CountAdvantage">{{topScorerPointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                        <span *ngIf="topScorerPointsObject$[event.key].adv1Count">1 - {{topScorerPointsObject$[event.key].adv1Count}}
                            <span *ngIf="topScorerPointsObject$[event.key].adv1CountPercentage">({{topScorerPointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="topScorerPointsObject$[event.key].advXCount">X - {{topScorerPointsObject$[event.key].advXCount}}
                            <span *ngIf="topScorerPointsObject$[event.key].advXCountPercentage">({{topScorerPointsObject$[event.key].advXCountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="topScorerPointsObject$[event.key].adv2Count">2 - {{topScorerPointsObject$[event.key].adv2Count}}
                            <span *ngIf="topScorerPointsObject$[event.key].adv2CountPercentage">({{topScorerPointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                        </span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topScorerPointsObject$[event.key]">
                    <span *ngIf="topScorerPointsObject$[event.key].result1X2">{{topScorerPointsObject$[event.key].result1X2}}</span></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill">

        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">7</th>
                <th class="teams text-start">8</th>
                <th class="teams text-start">Result 1X2</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx"
                *ngFor="let event of topScorerAnalysisPoints$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scorerCountAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scorerCountAdvantage">{{event.scorerCountAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredAdvantage">{{event.scoredAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredHomeAdvantage">{{event.scoredHomeAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredAwayAdvantage">{{event.scoredAwayAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredH1AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredH1Advantage">{{event.scoredH1Advantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredH2AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredH2Advantage">{{event.scoredH2Advantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredLastGameAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredLastGameAdvantage">{{event.scoredLastGameAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.scoredTeamLastGameAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.scoredTeamLastGameAdvantage">{{event.scoredTeamLastGameAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>1 : Scorer Count<br></Span>
    <Span>2 : Scored<br></Span>
    <Span>3 : Scored Home<br></Span>
    <Span>4 : Scored Away<br></Span>
    <Span>5 : Scored H1<br></Span>
    <Span>6 : Scored H2<br></Span>
    <Span>7 : Scored at Last Game<br></Span>
    <Span>8 : Scored at Last Team Game<br></Span>
</p>