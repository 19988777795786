import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { DateTime } from '../interface/date-time';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor(
    private fns: AngularFireFunctions
  ) { }

  getCurrentDate(): Observable<DateTime> {
    const callable = this.fns.httpsCallable('getCurrentDate');
    return callable({ type: 'currentDateTime' });
  }

  copyData(date:string, endpoint:string, id:string): Observable<any> {
    const callable = this.fns.httpsCallable('copyData');
    return callable({ 
      date: date,
      endpoint: endpoint,
      id: id 
    });
  }
}
