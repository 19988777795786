<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Analysis Points List</h1>
    <div class="col-lg-6 mx-auto">
            <p class="lead mb-4">Compiled analysis points list against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-4">
            <div class="input-group input-group-sm mb-3">
                    <label class="input-group-text" for="date">Pick a Date</label>
                    <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                            aria-describedby="inputGroup-sizing-sm" required>
            </div>
    </div>
    <div class="col-4" [hidden]="!datePicked">
            <select class="form-select form-select-sm mb-3" id="competitionSelect"
                    formControlName="competitionSelect" required>
                    <option value="">Choose Competition...</option>
                    <option *ngFor="let t of competitionList; let i = index" [value]="t.id">{{i+1}}. {{t.name}}
                    </option>
            </select>
            <div class="invalid-feedback">
                    Please select a competition.
            </div>
    </div>
    <div class="col-4">
            <div class="input-group input-group-sm mb-3">
                    <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
            </div>
    </div>
</form>

<div *ngIf="!isSearchMode">
    <div *ngIf="competitionPointsObject$">
            <table class="table table-sm  flex-fill">
                    <!-- <thead>
            <tr class="small-tx">
                <span *ngIf="competition.competitionName">{{competition.competitionName}}</span>
                <span *ngIf="competition.countryName">{{competition.countryName}}</span>
            </tr>
        </thead> -->
                    <thead class="table-light">
                            <tr class="small-tx">
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start">Ov/Un</th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start"></th>
                                    <th class="teams text-start">GG/GN/NN</th>
                <th class="teams text-start"></th>
                                    <th class="teams text-start">Result</th>
                                    <th class="teams text-start"></th>
                            </tr>
                    </thead>
                    <thead class="table-light">
                            <tr class="small-tx">
                                    <th class="teams text-start">Match</th>
                                    <th class="teams text-start">1</th>
                                    <th class="teams text-start">2</th>
                                    <th class="teams text-start">3</th>
                                    <th class="teams text-start">4</th>
                                    <th class="teams text-start">5</th>
                                    <th class="teams text-start">6</th>
                                    <th class="teams text-start">7</th>
                                    <th class="teams text-start">8</th>
                                    <th class="teams text-start">9</th>
                                    <th class="teams text-start">10</th>
                                    <th class="teams text-start">11</th>
                                    <th class="teams text-start">Adv</th>
                                    <th class="teams text-start">0.5</th>
                                    <th class="teams text-start">1.5</th>
                                    <th class="teams text-start">2.5</th>
                                    <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                                    <th class="teams text-start">1X2</th>
                                    <th class="teams text-start">Ov/Un</th>
                            </tr>
                    </thead>
                    <tbody *ngIf="eventObject">
                            <tr class="cursor-pointer status-live small-tx"
                                    *ngFor="let event of competitionPointsList$;let i = index;">
                                    <td class="time text-start">
                                            <div class="row text-bold">
                                                    <div class="col-xs" *ngIf="eventObject[event.key]"
                                                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">

                                                            <span
                                                                    *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                                                    Vs</span><br>
                                                            <span
                                                                    *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                                                    </div>
                                            </div>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hHomeAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hHomeAwayScoredAdvantage">{{competitionPointsObject$[event.key].h2hHomeAwayScoredAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayScoredAdvantage">{{competitionPointsObject$[event.key].h2hHomeHomeAwayAwayScoredAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXHomeAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXHomeAwayScoredAdvantage">{{competitionPointsObject$[event.key].lastXHomeAwayScoredAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayScoredAdvantage">{{competitionPointsObject$[event.key].lastXHomeHomeAwayAwayScoredAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredAdvantage">{{competitionPointsObject$[event.key].topScorerScoredAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredAwayAdvantage">{{competitionPointsObject$[event.key].topScorerScoredAwayAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredH1AdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredH1Advantage">{{competitionPointsObject$[event.key].topScorerScoredH1Advantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredH2AdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredH2Advantage">{{competitionPointsObject$[event.key].topScorerScoredH2Advantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredHAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredHomeAdvantage">{{competitionPointsObject$[event.key].topScorerScoredHomeAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredLastGameAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredLastGameAdvantage">{{competitionPointsObject$[event.key].topScorerScoredLastGameAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topScorerScoredTeamLastGameAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topScorerScoredTeamLastGameAdvantage">{{competitionPointsObject$[event.key].topScorerScoredTeamLastGameAdvantage}}</span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hHomeAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hHomeAwayConcededAdvantage">{{competitionPointsObject$[event.key].h2hHomeAwayConcededAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayConcededAdvantage">{{competitionPointsObject$[event.key].h2hHomeHomeAwayAwayConcededAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXHAConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXHAConcededAdvantage">{{competitionPointsObject$[event.key].lastXHAConcededAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayConcededAdvantage">{{competitionPointsObject$[event.key].lastXHomeHomeAwayAwayConcededAdvantage}}</span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hHomeAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hHomeAwayWinDrawLossAdvantage">{{competitionPointsObject$[event.key].h2hHomeAwayWinDrawLossAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayWinDrawLossAdvantage">{{competitionPointsObject$[event.key].h2hHomeHomeAwayAwayWinDrawLossAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXHAWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXHAWinDrawLossAdvantage">{{competitionPointsObject$[event.key].lastXHAWinDrawLossAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayWinDrawLossAdvantage">{{competitionPointsObject$[event.key].lastXHomeHomeAwayAwayWinDrawLossAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXHWinDrawAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXHWinDrawAdvantage">{{competitionPointsObject$[event.key].seasonLastXHWinDrawAdvantage}}</span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXConformWithOddsAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXConformWithOddsAdvantage">{{competitionPointsObject$[event.key].seasonLastXConformWithOddsAdvantage}}</span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXConformWithPositionAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXConformWithPositionAdvantage">{{competitionPointsObject$[event.key].seasonLastXConformWithPositionAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXConformWithPositionHomeAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXConformWithPositionHomeAwayAdvantage">{{competitionPointsObject$[event.key].seasonLastXConformWithPositionHomeAwayAdvantage}}</span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableWinAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableWinAdvantage">{{competitionPointsObject$[event.key].tableWinAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableWinAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableWinAwayAdvantage">{{competitionPointsObject$[event.key].tableWinAwayAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableWinHAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableWinHomeAdvantage">{{competitionPointsObject$[event.key].tableWinHomeAdvantage}}<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableLossAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableLossAdvantage">{{competitionPointsObject$[event.key].tableLossAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableLossAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableLossAwayAdvantage">{{competitionPointsObject$[event.key].tableLossAwayAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableLossHAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableLossHomeAdvantage">{{competitionPointsObject$[event.key].tableLossHomeAdvantage}}<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableDrawAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableDrawAdvantage">{{competitionPointsObject$[event.key].tableDrawAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableDrawAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableDrawAwayAdvantage">{{competitionPointsObject$[event.key].tableDrawAwayAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tableDrawHAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tableDrawHomeAdvantage">{{competitionPointsObject$[event.key].tableDrawHomeAdvantage}}<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tablePosAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tablePosAdvantage">{{competitionPointsObject$[event.key].tablePosAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tablePosAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tablePosAwayAdvantage">{{competitionPointsObject$[event.key].tablePosAwayAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].tablePosHAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].tablePosHomeAdvantage">{{competitionPointsObject$[event.key].tablePosHomeAdvantage}}<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topAssistCumulativeAssistAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topAssistCumulativeAssistAdvantage">{{competitionPointsObject$[event.key].topAssistCumulativeAssistAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topAssistPlayerCountAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topAssistPlayerCountAdvantage">{{competitionPointsObject$[event.key].topAssistPlayerCountAdvantage}}<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topCardCardsCountAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topCardCardsCountAdvantage">{{competitionPointsObject$[event.key].topCardCardsCountAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topCardCardsH1AdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topCardCardsH1Advantage">{{competitionPointsObject$[event.key].topCardCardsH1Advantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topCardCardsH2AdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topCardCardsH2Advantage">{{competitionPointsObject$[event.key].topCardCardsH2Advantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topCardPlayerCountAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topCardPlayerCountAdvantage">{{competitionPointsObject$[event.key].topCardPlayerCountAdvantage}}<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].topCardRedCardAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].topCardRedCardAdvantage">{{competitionPointsObject$[event.key].topCardRedCardAdvantage}}<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].adv1X2CountAdvantage">{{competitionPointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                                                    <span *ngIf="competitionPointsObject$[event.key].adv1Count">1 -
                                                            {{competitionPointsObject$[event.key].adv1Count}}
                                                            <span
                                                                    *ngIf="competitionPointsObject$[event.key].adv1CountPercentage">({{competitionPointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                                                    </span>
                                                    <span *ngIf="competitionPointsObject$[event.key].advXCount">X -
                                                            {{competitionPointsObject$[event.key].advXCount}}
                                                            <span
                                                                    *ngIf="competitionPointsObject$[event.key].advXCountPercentage">({{competitionPointsObject$[event.key].advXCountPercentage}}%)<br></span>
                                                    </span>
                                                    <span *ngIf="competitionPointsObject$[event.key].adv2Count">2 -
                                                            {{competitionPointsObject$[event.key].adv2Count}}
                                                            <span
                                                                    *ngIf="competitionPointsObject$[event.key].adv2CountPercentage">({{competitionPointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                                                    </span>
                                            </span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hOv05Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hOv05">Ov0.5<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXOv05Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXOv05">Ov0.5<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXOv05Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXOv05">Ov0.5<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hOv15Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hOv15">Ov1.5<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXOv15Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXOv15">Ov1.5<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXOv15Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXOv15">Ov1.5<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].h2hOv25Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].h2hOv25">Ov2.5<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].lastXOv25Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].lastXOv25">Ov2.5<br></span></span>
                                                    <span
                                                            [class]="competitionPointsObject$[event.key].seasonLastXOv25Rslt?'green-bd':'red-bg'"><span
                                                                    *ngIf="competitionPointsObject$[event.key].seasonLastXOv25">Ov2.5<br></span></span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                <span
                                                    [class]="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayGG">GG<br></span></span>
                                                <span
                                                    [class]="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayGN">GN<br></span></span>
                                                <span
                                                    [class]="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].h2hHomeHomeAwayAwayNN">NN<br></span></span>
                                                <span
                                                    [class]="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayGG">GG<br></span></span>
                                                <span
                                                    [class]="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayGN">GN<br></span></span>
                                                <span
                                                    [class]="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].lastXHomeHomeAwayAwayNN">NN<br></span></span>
                                            </span>
                                        </td>
                                        <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                <span [class]="competitionPointsObject$[event.key].h2hHomeAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].h2hHomeAwayGG">GG<br></span></span>
                                                <span [class]="competitionPointsObject$[event.key].h2hHomeAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].h2hHomeAwayGN">GN<br></span></span>
                                                <span [class]="competitionPointsObject$[event.key].h2hHomeAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].h2hHomeAwayNN">NN<br></span></span>
                                                <span [class]="competitionPointsObject$[event.key].lastXHAGGAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].lastXHAGG">GG<br></span></span>
                                                <span [class]="competitionPointsObject$[event.key].lastXHAGNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].lastXHAGN">GN<br></span></span>
                                                <span [class]="competitionPointsObject$[event.key].lastXHANNAdvRslt?'green-bd':'red-bg'"><span
                                                        *ngIf="competitionPointsObject$[event.key].lastXHANN">NN<br></span></span>
                                            </span>
                                        </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            *ngIf="competitionPointsObject$[event.key].result1X2">{{competitionPointsObject$[event.key].result1X2}}</span></span>
                                    </td>
                                    <td class="teams text-start">
                                            <span *ngIf="competitionPointsObject$[event.key]">
                                                    <span
                                                            *ngIf="competitionPointsObject$[event.key].resultOv25">Ov2.5</span>
                                                    <span *ngIf="!competitionPointsObject$[event.key].resultOv25">
                                                            <span
                                                                    *ngIf="competitionPointsObject$[event.key].resultOv15">Ov1.5</span>
                                                            <span
                                                                    *ngIf="!competitionPointsObject$[event.key].resultOv15">
                                                                    <span
                                                                            *ngIf="competitionPointsObject$[event.key].resultOv05">Ov0.5</span>
                                                            </span>
                                                    </span></span>
                                    </td>
                            </tr>
                    </tbody>
            </table>
    </div>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>Home Vs Away (Cumulative)<br></Span>
    <Span>1 : Scored<br></Span>
    <Span>2 : Conceded<br></Span>
    <Span>3 : Win Loss Draw<br></Span>
    <Span>Home Team at Home Vs Away Team at Away<br></Span>
    <Span>4 : Scored<br></Span>
    <Span>5 : Conceded<br></Span>
    <Span>6 : Win Loss Draw<br></Span>
</p>

<pre *ngIf="items">
    {{items|json}}
</pre>