import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-analysis-totals',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './analysis-totals.component.html',
  styleUrls: ['./analysis-totals.component.scss']
})
export class AnalysisTotalsComponent {

}
