import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// auth guard
import { AuthGuard } from './core/guards/auth.guard';

// components
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: "",
    component: PrivateLayoutComponent,
    canActivate: [
      //AuthGuard,
      //VerificationGuard
    ],
    children: [

      // New
      { path: 'admin', loadChildren: () => import('./admin/admin-route').then(m => m.ADMIN_ROUTES) },
      { path: 'analysis', loadChildren: () => import('./analysis/analysis-route').then(m => m.ANALYSIS_ROUTES) },
      { path: 'support', loadChildren: () => import('./support/support-route').then(m => m.SUPPORT_ROUTES) },
    ]
  },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      { path: 'account', loadChildren: () => import('./accounts/accounts-route').then(m => m.ACCOUNTS_ROUTES) },
      { path: 'error', loadChildren: () => import('./pages/error/error-route').then(m => m.ERROR_ROUTES) },
      { path: 'maintenance', loadChildren: () => import('./pages/maintenance/maintenance-route').then(m => m.MAINTENANCE_ROUTES) },
      { path: 'home', loadComponent: () => import('./pages/landing/landing.component').then(m => m.LandingComponent) },
      { path: 'privacy', loadComponent: () => import('./pages/privacy/privacy.component').then(m => m.PrivacyComponent) },
      { path: 'faq', loadComponent: () => import('./pages/faq/faq.component').then(m => m.FaqComponent) },


      // New
      
      { path: 'football', loadChildren: () => import('./football/football-route').then(m => m.FOOTBALL_ROUTES) },
    ]
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
    anchorScrolling: 'enabled',
    //  enableTracing: true //<-- debugging purposes only
    //  ,
    //relativeLinkResolution: 'legacy'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
