<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Top Card Analysis Points</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Top Card analysis points against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>

<div *ngIf="!isSearchMode">
    <div *ngIf="topCardPointsObject$">
    <table class="table table-sm flex-fill" *ngFor="let competition of competition$;let i = index">
        <thead>
            <tr class="small-tx">
                <h5>
                    <span class="fw-bold fst-italic"
                        *ngIf="competition.competitionName">{{competition.competitionName}}<br></span>
                    <span class="fst-italic" *ngIf="competition.countryName">{{competition.countryName}}</span>
                </h5>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Result</th>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">Adv</th>
                <th class="teams text-start">1X2</th>
            </tr>
        </thead>
        <tbody *ngIf="eventObject">
            <tr class="cursor-pointer status-live small-tx" *ngFor="let event of (competition.events | keyvalue);let i = index;">
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject[event.key]"
                        [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                    <span [class]="topCardPointsObject$[event.key].cardsCountAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topCardPointsObject$[event.key].cardsCountAdvantage">{{topCardPointsObject$[event.key].cardsCountAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                    <span [class]="topCardPointsObject$[event.key].redCardAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topCardPointsObject$[event.key].redCardAdvantage">{{topCardPointsObject$[event.key].redCardAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                    <span [class]="topCardPointsObject$[event.key].cardsH1AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topCardPointsObject$[event.key].cardsH1Advantage">{{topCardPointsObject$[event.key].cardsH1Advantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                    <span [class]="topCardPointsObject$[event.key].cardsH2AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topCardPointsObject$[event.key].cardsH2Advantage">{{topCardPointsObject$[event.key].cardsH2Advantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                    <span [class]="topCardPointsObject$[event.key].playerCountAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="topCardPointsObject$[event.key].playerCountAdvantage">{{topCardPointsObject$[event.key].playerCountAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                        <span [class]="topCardPointsObject$[event.key].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="topCardPointsObject$[event.key].adv1X2CountAdvantage">{{topCardPointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                        <span *ngIf="topCardPointsObject$[event.key].adv1Count">1 - {{topCardPointsObject$[event.key].adv1Count}}
                            <span *ngIf="topCardPointsObject$[event.key].adv1CountPercentage">({{topCardPointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="topCardPointsObject$[event.key].advXCount">X - {{topCardPointsObject$[event.key].advXCount}}
                            <span *ngIf="topCardPointsObject$[event.key].advXCountPercentage">({{topCardPointsObject$[event.key].advXCountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="topCardPointsObject$[event.key].adv2Count">2 - {{topCardPointsObject$[event.key].adv2Count}}
                            <span *ngIf="topCardPointsObject$[event.key].adv2CountPercentage">({{topCardPointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                        </span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="topCardPointsObject$[event.key]">
                    <span *ngIf="topCardPointsObject$[event.key].result1X2">{{topCardPointsObject$[event.key].result1X2}}</span></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>

<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill">

        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">Result 1X2</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx" *ngFor="let event of topCardAnalysisPoints$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span [class]="event.cardsCountAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.cardsCountAdvantage">{{event.cardsCountAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.redCardAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.redCardAdvantage">{{event.redCardAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.cardsH1AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.cardsH1Advantage">{{event.cardsH1Advantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.cardsH2AdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.cardsH2Advantage">{{event.cardsH2Advantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.playerCountAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.playerCountAdvantage">{{event.playerCountAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>1 : Card Count<br></Span>
    <Span>2 : Red Card Count<br></Span>
    <Span>3 : Card H1<br></Span>
    <Span>4 : Card H2<br></Span>
    <Span>5 : Carded Player Count<br></Span>
</p>