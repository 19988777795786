<div class="graph">
    <div [style.height.%]="percentageInput" class="bar"></div>
    <!--
    -->
    <div style="height: 11px;" class="bar"></div>
    <!--
    -->
    <div style="height: 6px;" class="bar"></div>
    <!--
    -->
    <div [style.height.%]="percentageInput" class="bar"></div>
    <!--
    -->
    <div style="height: 28px;" class="bar"></div>
</div>


<div class="box">
    <h1>Skills Bar</h1>
    <div class="skillbox">
        <p>HTML</p>
        <p>90%</p>
        <div class="skill">
            <div class="level" style="width: 90%; background: #2fc4b2;"></div>
        </div>
    </div>
    <div class="skillbox">
        <p>CSS</p>
        <p>80%</p>
        <div class="skill">
            <div class="level" style="width: 80%; background: #d32626;"></div>
        </div>
    </div>
    <div class="skillbox">
        <p>JavaScript</p>
        <p>85%</p>
        <div class="skill">
            <div class="level" style="width: 85%; background: #79d70f;"></div>
        </div>
    </div>
    <div class="skillbox">
        <p>JAVA</p>
        <p>75%</p>
        <div class="skill">
            <div class="level" style="width: 75%; background: #b5076b;"></div>
        </div>
    </div>
    <div class="skillbox">
        <p>Python</p>
        <p>70%</p>
        <div class="skill">
            <div class="level" style="width: 70%; background: #fed330;"></div>
        </div>
    </div>


</div>

<div class="px-4 my-5 text-center" *ngIf="analysisH2H">
    <h1 class="display-5 fw-bold">H2H Analysis</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Data on teams head to head.</p>
    </div>
</div>

<table class="table table-sm flex-fill" *ngIf="analysisH2H">
    <caption>{{analysisH2H.homeTeam}}</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Win</th>
            <th class="teams text-start w-25">Draw</th>
            <th class="teams text-start w-25">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>All</td>
            <td class="time text-start w-25">{{((analysisH2H.homeTeamAwayWin +
                analysisH2H.homeTeamHomeWin)/(analysisH2H.homeTeamGamesAtHome +
                analysisH2H.homeTeamGamesAtAway))|percent}}
            </td>
            <td class="teams text-start w-25">{{((analysisH2H.homeTeamAwayDraw +
                analysisH2H.homeTeamHomeDraw)/(analysisH2H.homeTeamGamesAtHome +
                analysisH2H.homeTeamGamesAtAway))|percent}}
            </td>
            <td class="teams text-start w-25">{{((analysisH2H.homeTeamAwayLoss +
                analysisH2H.homeTeamHomeLoss)/(analysisH2H.homeTeamGamesAtHome +
                analysisH2H.homeTeamGamesAtAway))|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>Home</td>
            <td class="time text-start w-25">
                {{(analysisH2H.homeTeamHomeWin/analysisH2H.homeTeamGamesAtHome)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.homeTeamHomeDraw/analysisH2H.homeTeamGamesAtHome)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.homeTeamHomeLoss/analysisH2H.homeTeamGamesAtHome)|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>Away</td>
            <td class="time text-start w-25">
                {{(analysisH2H.homeTeamAwayWin/analysisH2H.homeTeamGamesAtAway)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.homeTeamAwayDraw/analysisH2H.homeTeamGamesAtAway)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.homeTeamAwayLoss/analysisH2H.homeTeamGamesAtAway)|percent}}
            </td>
        </tr>
    </tbody>
</table>

<table class="table table-sm flex-fill" *ngIf="analysisH2H">
    <caption>{{analysisH2H.awayTeam}}</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Win</th>
            <th class="teams text-start w-25">Draw</th>
            <th class="teams text-start w-25">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>All</td>
            <td class="time text-start w-25">{{((analysisH2H.awayTeamAwayWin +
                analysisH2H.awayTeamHomeWin)/(analysisH2H.awayTeamGamesAtHome +
                analysisH2H.awayTeamGamesAtAway))|percent}}
            </td>
            <td class="teams text-start w-25">{{((analysisH2H.awayTeamAwayDraw +
                analysisH2H.awayTeamHomeDraw)/(analysisH2H.awayTeamGamesAtHome +
                analysisH2H.awayTeamGamesAtAway))|percent}}
            </td>
            <td class="teams text-start w-25">{{((analysisH2H.awayTeamAwayLoss +
                analysisH2H.awayTeamHomeLoss)/(analysisH2H.awayTeamGamesAtHome +
                analysisH2H.awayTeamGamesAtAway))|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>Home</td>
            <td class="time text-start w-25">
                {{(analysisH2H.awayTeamHomeWin/analysisH2H.awayTeamGamesAtHome)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.awayTeamHomeDraw/analysisH2H.awayTeamGamesAtHome)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.awayTeamHomeLoss/analysisH2H.awayTeamGamesAtHome)|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>Away</td>
            <td class="time text-start w-25">
                {{(analysisH2H.awayTeamAwayWin/analysisH2H.awayTeamGamesAtAway)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.awayTeamAwayDraw/analysisH2H.awayTeamGamesAtAway)|percent}}
            </td>
            <td class="teams text-start w-25">
                {{(analysisH2H.awayTeamAwayLoss/analysisH2H.awayTeamGamesAtAway)|percent}}
            </td>
        </tr>
    </tbody>
</table>

<table class="table table-sm flex-fill" *ngIf="analysisH2H">
    <caption>Over/Under Goals</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Ov0.5</th>
            <th class="teams text-start w-25">Ov1.5</th>
            <th class="teams text-start w-25">Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>Games</td>
            <td class="time text-start w-25">{{analysisH2H.gamesOv05}}<br>
                {{(analysisH2H.gamesOv05Percentage/100)|percent}}
            </td>
            <td class="teams text-start w-25">{{analysisH2H.gamesOv15}}<br>
                {{(analysisH2H.gamesOv15Percentage/100)|percent}}
            </td>
            <td class="teams text-start w-25">{{analysisH2H.gamesOv25}}<br>
                {{(analysisH2H.gamesOv25Percentage/100)|percent}}
            </td>
        </tr>
    </tbody>
</table>

<table class="table table-sm flex-fill" *ngIf="analysisH2H">
    <caption>Goals</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>{{analysisH2H.homeTeam}}</td>
            <td class="time text-start w-25">{{analysisH2H.homeTeamGoalsAtHome}}<br>
                {{(analysisH2H.homeTeamGoalsAtHomePercentage/100)|percent}}
            </td>
            <td class="teams text-start w-25">{{analysisH2H.homeTeamGoalsAtAway}}<br>
                {{(analysisH2H.homeTeamGoalsAtAwayPercentage/100)|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td>{{analysisH2H.awayTeam}}</td>
            <td class="time text-start w-25">{{analysisH2H.awayTeamGoalsAtHome}}<br>
                {{(analysisH2H.awayTeamGoalsAtHomePercentage/100)|percent}}
            </td>
            <td class="teams text-start w-25">{{analysisH2H.awayTeamGoalsAtAway}}<br>
                {{(analysisH2H.awayTeamGoalsAtAwayPercentage/100)|percent}}
            </td>
        </tr>
    </tbody>
</table>

<pre>
      {{analysisHomeLastX | json}}
  </pre>