import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(
    private fns: AngularFireFunctions,
  ) { }

  requestJpData(pageNumber: number): Observable<any> {
    const callable = this.fns.httpsCallable('JpArchieveData');
    return callable({
      pageNumber: pageNumber
    });
  }

  requestJpFormatData(): Observable<any> {
    const callable = this.fns.httpsCallable('requestFormatJpData');
    return callable({});
  }

  // New March 31

  requestMaintenanceDateData(date: string): Observable<any> {
    const callable = this.fns.httpsCallable('dataRCTQ');
    return callable({
      date: date
    });
  }

  requestSP13Data(date: string, week: number, bookMakerId: string, endpoint: string): Observable<any> {
    const callable = this.fns.httpsCallable('requestSP13Data');
    return callable({
      date: date,
      week: week,
      bookMakerId: bookMakerId,
      endpoint: endpoint,
    });
  }

  requestSP17Data(date: string, week: number, bookMakerId: string, endpoint: string): Observable<any> {
    const callable = this.fns.httpsCallable('requestSP17Data');
    return callable({
      date: date,
      week: week,
      bookMakerId: bookMakerId,
      endpoint: endpoint,
    });
  }

  startEventTask(date: string): Observable<any> {
    const callable = this.fns.httpsCallable('eDDTQOne');
    return callable({
      date: date,
    });
  }

  requestDateEventResult(date: string): Observable<any> {
    const callable = this.fns.httpsCallable('requestMDR');
    return callable({
      date: date,
    });
  }

  repeatAnalysisPoints(date: string, stage: string): Observable<any> {
    const callable = this.fns.httpsCallable('repeatDAP');
    return callable({
      date: date,
      stage: stage,
    });
  }

  deleteBloatData(date: string): Observable<any> {
    const callable = this.fns.httpsCallable('delBD');
    return callable({
      date: date,
    });
  }

  deleteDateData(date: string): Observable<any> {
    const callable = this.fns.httpsCallable('delDD');
    return callable({
      date: date,
    });
  }

  reRequestData(date: string): Observable<any> {
    const callable = this.fns.httpsCallable('repeatDR');
    return callable({
      date: date,
    });
  }

  excludeCompetition(date: string, id: number) {
    const callable = this.fns.httpsCallable('excludeCompetition');
    return callable({
      date: date,
      id: id,
    });
  }
}

