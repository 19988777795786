import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTime } from 'src/app/core/interface/date-time';
import { Event } from 'src/app/core/interface/event';
import { JackpotBookmaker } from 'src/app/core/interface/jackpot-bookmaker';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';


@Component({
  selector: 'app-analysis-h2h',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './analysis-h2h.component.html',
  styleUrls: ['./analysis-h2h.component.scss']
})
export class AnalysisH2hComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  fixtureDate: string;
  id: number;
  todayDate: DateTime;
  weekNumberToday: string;
  matchEvent: Event;
  matchH2H: any;
  h2hData: any;
  h2hAnalysis: any;
  h2hAnalysisPoints$: any;

  constructor(
    private dateTimeService: DateTimeService,
    private footballService: FootballService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subscription.add(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams.pipe(first()).subscribe(params => {
          let date = params['date'];
          let date1 = new Date(date);
          console.log("date1:", date1);
          let year = date1.getFullYear();
          let month = ((date1.getMonth() + 1).toString()).padStart(2, '0');
          let day = (date1.getDate().toString()).padStart(2, '0');

          this.fixtureDate = `${year}-${month}-${day}`;
        })

        this.id = Number(this.route.snapshot.paramMap.get('id1'));
        console.log("id:", this.id);
        console.log("eDate:", this.fixtureDate);
        this.subscription.add(dateTimeService.getCurrentDate().subscribe(
          (dateTime) => {
            this.todayDate = dateTime;
            this.weekNumberToday = ((dateTime.yearWeek).toString()).padStart(2, '0');

            this.footballService.getMatchById(this.fixtureDate, this.id).subscribe((event) => {
              this.matchEvent = event;
              console.log("Event", event);

              if (event) {
                // get H2H
                this.subscription.add(
                  this.footballService.getEventH2HListByEvent(this.fixtureDate, this.id).subscribe((h2h) => {
                    this.matchH2H = h2h;
                  }));
                // get H2H data
                this.subscription.add(
                  this.footballService.getH2HData(this.fixtureDate, this.id).subscribe((h2hData) => {
                    this.h2hData = h2hData;
                  }));
                // get H2H analysis
                this.subscription.add(
                  this.footballService.getH2HAnalysis(this.fixtureDate, this.id).subscribe((h2hAnalysis) => {
                    this.h2hAnalysis = h2hAnalysis;
                  }));
                // get H2H analysisPoint
                this.subscription.add(this.footballService.getH2HEventAnalysisPointsByMatch(this.fixtureDate, this.id).pipe(first())
                  .subscribe((h2hAnalysisPoints) => {
                    //this.responses$ = response;
                    this.h2hAnalysisPoints$ = h2hAnalysisPoints;
                  }))
              }
            })

          }));
      }
    }))
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

}

