<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Season Table Analysis Points</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Season Table analysis points against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>

<div *ngIf="!isSearchMode">
    <div *ngIf="tablePointsObject$">
    <table class="table table-sm flex-fill" *ngFor="let competition of competition$;let i = index">
        <thead>
            <tr class="small-tx">
                <h5>
                    <span class="fw-bold fst-italic"
                        *ngIf="competition.competitionName">{{competition.competitionName}}<br></span>
                    <span class="fst-italic" *ngIf="competition.countryName">{{competition.countryName}}</span>
                </h5>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Result</th>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">7</th>
                <th class="teams text-start">8</th>
                <th class="teams text-start">9</th>
                <th class="teams text-start">10</th>
                <th class="teams text-start">11</th>
                <th class="teams text-start">12</th>
                <th class="teams text-start">Adv</th>
                <th class="teams text-start">Result 1X2</th>
            </tr>
        </thead>
        <tbody *ngIf="eventObject">
            <tr class="cursor-pointer status-live small-tx"
            *ngFor="let event of (competition.events | keyvalue);let i = index;">
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject[event.key]">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].winsHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].winsHomeAdvantage">{{tablePointsObject$[event.key].winsHomeAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].drawHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].drawHomeAdvantage">{{tablePointsObject$[event.key].drawHomeAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].lossHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].lossHomeAdvantage">{{tablePointsObject$[event.key].lossHomeAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].posHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].posHomeAdvantage">{{tablePointsObject$[event.key].posHomeAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].winsAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].winsAwayAdvantage">{{tablePointsObject$[event.key].winsAwayAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].drawAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].drawAwayAdvantage">{{tablePointsObject$[event.key].drawAwayAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].lossAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].lossAwayAdvantage">{{tablePointsObject$[event.key].lossAwayAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].posAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].posAwayAdvantage">{{tablePointsObject$[event.key].posAwayAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].winsAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].winsAdvantage">{{tablePointsObject$[event.key].winsAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].drawAdvRslt?'green-bd':'red-bg'"> <span
                            *ngIf="tablePointsObject$[event.key].drawAdvantage">{{tablePointsObject$[event.key].drawAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].lossAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].lossAdvantage">{{tablePointsObject$[event.key].lossAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span [class]="tablePointsObject$[event.key].posAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="tablePointsObject$[event.key].posAdvantage">{{tablePointsObject$[event.key].posAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                        <span [class]="tablePointsObject$[event.key].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="tablePointsObject$[event.key].adv1X2CountAdvantage">{{tablePointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                        <span *ngIf="tablePointsObject$[event.key].adv1Count">1 - {{tablePointsObject$[event.key].adv1Count}}
                            <span *ngIf="tablePointsObject$[event.key].adv1CountPercentage">({{tablePointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="tablePointsObject$[event.key].advXCount">X - {{tablePointsObject$[event.key].advXCount}}
                            <span *ngIf="tablePointsObject$[event.key].advXCountPercentage">({{tablePointsObject$[event.key].advXCountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="tablePointsObject$[event.key].adv2Count">2 - {{tablePointsObject$[event.key].adv2Count}}
                            <span *ngIf="tablePointsObject$[event.key].adv2CountPercentage">({{tablePointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                        </span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="tablePointsObject$[event.key]">
                    <span *ngIf="tablePointsObject$[event.key].result1X2">{{tablePointsObject$[event.key].result1X2}}</span></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>

<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill">
        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">7</th>
                <th class="teams text-start">8</th>
                <th class="teams text-start">9</th>
                <th class="teams text-start">10</th>
                <th class="teams text-start">11</th>
                <th class="teams text-start">12</th>
                <th class="teams text-start">Result 1X2</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx"
                *ngFor="let event of tableAnalysisPoints$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span [class]="event.winsHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.winsHomeAdvantage">{{event.winsHomeAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.drawHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.drawHomeAdvantage">{{event.drawHomeAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.lossHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.lossHomeAdvantage">{{event.lossHomeAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.posHAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.posHomeAdvantage">{{event.posHomeAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.winsAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.winsAwayAdvantage">{{event.winsAwayAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.drawAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.drawAwayAdvantage">{{event.drawAwayAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.lossAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.lossAwayAdvantage">{{event.lossAwayAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.posAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.posAwayAdvantage">{{event.posAwayAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.winsAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.winsAdvantage">{{event.winsAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.drawAdvRslt?'green-bd':'red-bg'"> <span
                            *ngIf="event.drawAdvantage">{{event.drawAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.lossAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.lossAdvantage">{{event.lossAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.posAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.posAdvantage">{{event.posAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>While at Home<br></Span>
    <Span>1 : Win<br></Span>
    <Span>2 : Draw<br></Span>
    <Span>3 : Loss<br></Span>
    <Span>4 : Position<br></Span>
    <Span>While Away<br></Span>
    <Span>5 : Win<br></Span>
    <Span>6 : Draw<br></Span>
    <Span>7 : Loss<br></Span>
    <Span>8 : Position<br></Span>
    <Span>Cumulative<br></Span>
    <Span>9 : Win<br></Span>
    <Span>10 : Draw<br></Span>
    <Span>11 : Loss<br></Span>
    <Span>12 : Position<br></Span>
</p>