<div class="btn-toolbar" role="group" aria-label="Basic radio toggle button group" *ngIf="seasonLastXList">
    <span *ngFor="let round of seasonLastXList; let i = index;">
        <input type="radio" class="btn-check" name="btnradio" [id]="'btnradio'+round.key" autocomplete="off"
            (click)="pickRound(round.key)">
        <label class="btn btn-outline-primary" [for]="'btnradio'+round.key">Round {{round.key}}</label>
    </span>
</div>

<!-- table -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="seasonLastXRound">
    <h4 class="display-6 fw-normal mb-0">Season Games Round {{roundPicked}}</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="seasonLastXRound">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Time</th>
            <th scope="col">Teams</th>
            <th scope="col">Start<br>Position<br>Home/Away</th>
            <th scope="col">Start<br>Position</th>
            <th scope="col">1</th>
            <th scope="col">X</th>
            <th scope="col">2</th>
            <th scope="col">Result</th>
            <th scope="col">Odds<br>Conform</th>
            <th scope="col">Start<br>Position<br>Home/Away<br>Conform</th>
            <th scope="col">Start<br>Position<br>Conform</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let match of seasonLastXRound;let i = index;">
            <td scope="col">{{i+1}}</td>
            <td scope="col">
                <span *ngIf="match.matchDate">{{match.matchDate}}<br></span>
                <span *ngIf="match.matchTime">{{match.matchTime}}</span>
            </td>
            <td scope="col">
                <span *ngIf="match.matchHT">{{match.matchHT}}<br></span>
                <span *ngIf="match.matchAT">{{match.matchAT}}</span>
            </td>
            <td scope="col">
                <span *ngIf="match.homeTeamHomePosition">{{match.homeTeamHomePosition}}<br></span>
                <span *ngIf="match.awayTeamAwayPosition">{{match.awayTeamAwayPosition}}</span>
            </td>
            <td scope="col">
                <span *ngIf="match.homeTeamPosition">{{match.homeTeamPosition}}<br></span>
                <span *ngIf="match.awayTeamPosition">{{match.awayTeamPosition}}</span>
            </td>
            <td scope="col">
                <div *ngIf="match.homeOdds">
                    <button type="button" class="btn btn-outline-light"
                        [class.btn-outline-dark]="match.result1x2 === '1'">{{match.homeOdds}}</button>
                </div>
            </td>
            <td scope="col">
                <div *ngIf="match.drawOdds">
                    <button type="button" class="btn btn-outline-light"
                        [class.btn-outline-dark]="match.result1x2 === 'X'">{{match.drawOdds}}</button>
                </div>
            </td>
            <td scope="col">
                <div *ngIf="match.awayOdds">
                    <button type="button" class="btn btn-outline-light"
                        [class.btn-outline-dark]="match.result1x2 === '2'">{{match.awayOdds}}</button>
                </div>
            </td>
            <td scope="col">
                <span *ngIf="match.matchHTResult">{{match.matchHTResult}}</span>
                <span *ngIf="!match.matchHTResult">0</span>:
                <span *ngIf="match.matchATResult">{{match.matchATResult}}</span>
                <span *ngIf="!match.matchATResult">0</span>
            </td>
            <td scope="col">
                <div *ngIf="match.conformWithOdds">
                    <button type="button" class="btn" [class.btn-outline-danger]="!match.conformWithOdds"
                        [class.btn-outline-success]="match.conformWithOdds">{{match.conformWithOdds}}</button>
                </div>
            </td>
            <td scope="col">
                <div *ngIf="match.conformWithHAPosition">
                    <button type="button" class="btn" [class.btn-outline-danger]="!match.conformWithHAPosition"
                        [class.btn-outline-success]="match.conformWithHAPosition">{{match.conformWithHAPosition}}</button>
                </div>
            </td>
            <td scope="col">
                <div *ngIf="match.conformWithPosition">
                    <button type="button" class="btn" [class.btn-outline-danger]="!match.conformWithPosition"
                        [class.btn-outline-success]="match.conformWithPosition">{{match.conformWithPosition}}</button>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<!-- 2nd last vs 3rd Last round points -->
<table class="table table-sm flex-fill" *ngIf="seasonLastXPoints">
    <caption>2nd Last Round Points Vs<br>3rd Last Round Points</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">2nd Last</th>
            <th class="teams text-start w-25">3rd Last</th>
        </tr>
    </thead>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundHWin10 || 
    seasonLastXPoints.secondLastRoundHWin51 ||
    seasonLastXPoints.secondLastRoundHWin60 ||
    seasonLastXPoints.secondLastRoundHWin70 ||
    seasonLastXPoints.secondLastRoundHWin80 ||
    seasonLastXPoints.secondLastRoundHWin90 ||
    seasonLastXPoints.secondLastRoundHWin100 ||
    seasonLastXPoints.thirdLastRoundHWin10 || 
    seasonLastXPoints.thirdLastRoundHWin51 ||
    seasonLastXPoints.thirdLastRoundHWin60 ||
    seasonLastXPoints.thirdLastRoundHWin70 ||
    seasonLastXPoints.thirdLastRoundHWin80 ||
    seasonLastXPoints.thirdLastRoundHWin90 ||
    seasonLastXPoints.thirdLastRoundHWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home Win</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundHWin100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundHWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundAWin10 || 
    seasonLastXPoints.secondLastRoundAWin51 ||
    seasonLastXPoints.secondLastRoundAWin60 ||
    seasonLastXPoints.secondLastRoundAWin70 ||
    seasonLastXPoints.secondLastRoundAWin80 ||
    seasonLastXPoints.secondLastRoundAWin90 ||
    seasonLastXPoints.secondLastRoundAWin100 ||
    seasonLastXPoints.thirdLastRoundAWin10 || 
    seasonLastXPoints.thirdLastRoundAWin51 ||
    seasonLastXPoints.thirdLastRoundAWin60 ||
    seasonLastXPoints.thirdLastRoundAWin70 ||
    seasonLastXPoints.thirdLastRoundAWin80 ||
    seasonLastXPoints.thirdLastRoundAWin90 ||
    seasonLastXPoints.thirdLastRoundAWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away Win</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundAWin100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundAWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundDraw10 || 
    seasonLastXPoints.secondLastRoundDraw51 ||
    seasonLastXPoints.secondLastRoundDraw60 ||
    seasonLastXPoints.secondLastRoundDraw70 ||
    seasonLastXPoints.secondLastRoundDraw80 ||
    seasonLastXPoints.secondLastRoundDraw90 ||
    seasonLastXPoints.secondLastRoundDraw100 ||
    seasonLastXPoints.thirdLastRoundDraw10 || 
    seasonLastXPoints.thirdLastRoundDraw51 ||
    seasonLastXPoints.thirdLastRoundDraw60 ||
    seasonLastXPoints.thirdLastRoundDraw70 ||
    seasonLastXPoints.thirdLastRoundDraw80 ||
    seasonLastXPoints.thirdLastRoundDraw90 ||
    seasonLastXPoints.thirdLastRoundDraw100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundDraw100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundDraw100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundConformWithHAPosition10 || 
    seasonLastXPoints.secondLastRoundConformWithHAPosition51 ||
    seasonLastXPoints.secondLastRoundConformWithHAPosition60 ||
    seasonLastXPoints.secondLastRoundConformWithHAPosition70 ||
    seasonLastXPoints.secondLastRoundConformWithHAPosition80 ||
    seasonLastXPoints.secondLastRoundConformWithHAPosition90 ||
    seasonLastXPoints.secondLastRoundConformWithHAPosition100 ||
    seasonLastXPoints.thirdLastRoundConformWithHAPosition10 || 
    seasonLastXPoints.thirdLastRoundConformWithHAPosition51 ||
    seasonLastXPoints.thirdLastRoundConformWithHAPosition60 ||
    seasonLastXPoints.thirdLastRoundConformWithHAPosition70 ||
    seasonLastXPoints.thirdLastRoundConformWithHAPosition80 ||
    seasonLastXPoints.thirdLastRoundConformWithHAPosition90 ||
    seasonLastXPoints.thirdLastRoundConformWithHAPosition100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conform Home/Away Position</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithHAPosition100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithHAPosition100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundConformWithPosition10 || 
    seasonLastXPoints.secondLastRoundConformWithPosition51 ||
    seasonLastXPoints.secondLastRoundConformWithPosition60 ||
    seasonLastXPoints.secondLastRoundConformWithPosition70 ||
    seasonLastXPoints.secondLastRoundConformWithPosition80 ||
    seasonLastXPoints.secondLastRoundConformWithPosition90 ||
    seasonLastXPoints.secondLastRoundConformWithPosition100 ||
    seasonLastXPoints.thirdLastRoundConformWithPosition10 || 
    seasonLastXPoints.thirdLastRoundConformWithPosition51 ||
    seasonLastXPoints.thirdLastRoundConformWithPosition60 ||
    seasonLastXPoints.thirdLastRoundConformWithPosition70 ||
    seasonLastXPoints.thirdLastRoundConformWithPosition80 ||
    seasonLastXPoints.thirdLastRoundConformWithPosition90 ||
    seasonLastXPoints.thirdLastRoundConformWithPosition100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conform Position</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithPosition100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithPosition100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundConformWithOdds10 || 
    seasonLastXPoints.secondLastRoundConformWithOdds51 ||
    seasonLastXPoints.secondLastRoundConformWithOdds60 ||
    seasonLastXPoints.secondLastRoundConformWithOdds70 ||
    seasonLastXPoints.secondLastRoundConformWithOdds80 ||
    seasonLastXPoints.secondLastRoundConformWithOdds90 ||
    seasonLastXPoints.secondLastRoundConformWithOdds100 ||
    seasonLastXPoints.thirdLastRoundConformWithOdds10 || 
    seasonLastXPoints.thirdLastRoundConformWithOdds51 ||
    seasonLastXPoints.thirdLastRoundConformWithOdds60 ||
    seasonLastXPoints.thirdLastRoundConformWithOdds70 ||
    seasonLastXPoints.thirdLastRoundConformWithOdds80 ||
    seasonLastXPoints.thirdLastRoundConformWithOdds90 ||
    seasonLastXPoints.thirdLastRoundConformWithOdds100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conform Odds</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundConformWithOdds100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundConformWithOdds100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundOv0510 || 
    seasonLastXPoints.secondLastRoundOv0551 ||
    seasonLastXPoints.secondLastRoundOv0560 ||
    seasonLastXPoints.secondLastRoundOv0570 ||
    seasonLastXPoints.secondLastRoundOv0580 ||
    seasonLastXPoints.secondLastRoundOv0590 ||
    seasonLastXPoints.secondLastRoundOv05100 ||
    seasonLastXPoints.thirdLastRoundOv0510 || 
    seasonLastXPoints.thirdLastRoundOv0551 ||
    seasonLastXPoints.thirdLastRoundOv0560 ||
    seasonLastXPoints.thirdLastRoundOv0570 ||
    seasonLastXPoints.thirdLastRoundOv0580 ||
    seasonLastXPoints.thirdLastRoundOv0590 ||
    seasonLastXPoints.thirdLastRoundOv05100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Ov0.5</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundOv0510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv0551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv0560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv0570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv0580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv0590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv05100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv0510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv0551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv0560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv0570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv0580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv0590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv05100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundOv1510 || 
    seasonLastXPoints.secondLastRoundOv1551 ||
    seasonLastXPoints.secondLastRoundOv1560 ||
    seasonLastXPoints.secondLastRoundOv1570 ||
    seasonLastXPoints.secondLastRoundOv1580 ||
    seasonLastXPoints.secondLastRoundOv1590 ||
    seasonLastXPoints.secondLastRoundOv15100 ||
    seasonLastXPoints.thirdLastRoundOv1510 || 
    seasonLastXPoints.thirdLastRoundOv1551 ||
    seasonLastXPoints.thirdLastRoundOv1560 ||
    seasonLastXPoints.thirdLastRoundOv1570 ||
    seasonLastXPoints.thirdLastRoundOv1580 ||
    seasonLastXPoints.thirdLastRoundOv1590 ||
    seasonLastXPoints.thirdLastRoundOv15100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Ov1.5</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundOv1510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv1551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv1560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv1570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv1580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv1590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv15100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv1510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv1551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv1560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv1570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv1580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv1590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv15100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.secondLastRoundOv2510 || 
    seasonLastXPoints.secondLastRoundOv2551 ||
    seasonLastXPoints.secondLastRoundOv2560 ||
    seasonLastXPoints.secondLastRoundOv2570 ||
    seasonLastXPoints.secondLastRoundOv2580 ||
    seasonLastXPoints.secondLastRoundOv2590 ||
    seasonLastXPoints.secondLastRoundOv25100 ||
    seasonLastXPoints.thirdLastRoundOv2510 || 
    seasonLastXPoints.thirdLastRoundOv2551 ||
    seasonLastXPoints.thirdLastRoundOv2560 ||
    seasonLastXPoints.thirdLastRoundOv2570 ||
    seasonLastXPoints.thirdLastRoundOv2580 ||
    seasonLastXPoints.thirdLastRoundOv2590 ||
    seasonLastXPoints.thirdLastRoundOv25100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Ov2.5</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.secondLastRoundOv2510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv2551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv2560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv2570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv2580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv2590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.secondLastRoundOv25100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv2510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv2551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv2560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv2570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv2580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv2590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.thirdLastRoundOv25100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- season last x analysis points -->
<table class="table table-sm flex-fill" *ngIf="seasonLastXPoints">
    <caption>Season Last X Analysis Points</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Trend</th>
        </tr>
    </thead>
    <tbody *ngIf="
    seasonLastXPoints.homeWin10 || 
    seasonLastXPoints.homeWin51 ||
    seasonLastXPoints.homeWin60 ||
    seasonLastXPoints.homeWin70 ||
    seasonLastXPoints.homeWin80 ||
    seasonLastXPoints.homeWin90 ||
    seasonLastXPoints.homeWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home Win</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.homeWin10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.homeWin51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.homeWin60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.homeWin70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.homeWin80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.homeWin90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.homeWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.awayWin10 || 
    seasonLastXPoints.awayWin51 ||
    seasonLastXPoints.awayWin60 ||
    seasonLastXPoints.awayWin70 ||
    seasonLastXPoints.awayWin80 ||
    seasonLastXPoints.awayWin90 ||
    seasonLastXPoints.awayWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away Win</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.awayWin10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.awayWin51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.awayWin60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.awayWin70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.awayWin80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.awayWin90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.awayWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.draw10 || 
    seasonLastXPoints.draw51 ||
    seasonLastXPoints.draw60 ||
    seasonLastXPoints.draw70 ||
    seasonLastXPoints.draw80 ||
    seasonLastXPoints.draw90 ||
    seasonLastXPoints.draw100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.draw10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.draw51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.draw60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.draw70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.draw80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.draw90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.draw100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.conformWithHAPosition10 || 
    seasonLastXPoints.conformWithHAPosition51 ||
    seasonLastXPoints.conformWithHAPosition60 ||
    seasonLastXPoints.conformWithHAPosition70 ||
    seasonLastXPoints.conformWithHAPosition80 ||
    seasonLastXPoints.conformWithHAPosition90 ||
    seasonLastXPoints.conformWithHAPosition100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conform Home/Away Position</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.conformWithHAPosition10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHAPosition51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHAPosition60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHAPosition70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHAPosition80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHAPosition90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHAPosition100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.conformWithHomePosition10 || 
    seasonLastXPoints.conformWithHomePosition51 ||
    seasonLastXPoints.conformWithHomePosition60 ||
    seasonLastXPoints.conformWithHomePosition70 ||
    seasonLastXPoints.conformWithHomePosition80 ||
    seasonLastXPoints.conformWithHomePosition90 ||
    seasonLastXPoints.conformWithHomePosition100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conform Position</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.conformWithHomePosition10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHomePosition51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHomePosition60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHomePosition70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHomePosition80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHomePosition90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithHomePosition100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.conformWithOdds10 || 
    seasonLastXPoints.conformWithOdds51 ||
    seasonLastXPoints.conformWithOdds60 ||
    seasonLastXPoints.conformWithOdds70 ||
    seasonLastXPoints.conformWithOdds80 ||
    seasonLastXPoints.conformWithOdds90 ||
    seasonLastXPoints.conformWithOdds100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conform Odds</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.conformWithOdds10">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithOdds51">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithOdds60">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithOdds70">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithOdds80">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithOdds90">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.conformWithOdds100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.ov0510 || 
    seasonLastXPoints.ov0551 ||
    seasonLastXPoints.ov0560 ||
    seasonLastXPoints.ov0570 ||
    seasonLastXPoints.ov0580 ||
    seasonLastXPoints.ov0590 ||
    seasonLastXPoints.ov05100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Ov0.5</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.ov0510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.ov0551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.ov0560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.ov0570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.ov0580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.ov0590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.ov05100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.ov1510 || 
    seasonLastXPoints.ov1551 ||
    seasonLastXPoints.ov1560 ||
    seasonLastXPoints.ov1570 ||
    seasonLastXPoints.ov1580 ||
    seasonLastXPoints.ov1590 ||
    seasonLastXPoints.ov15100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Ov1.5</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.ov1510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.ov1551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.ov1560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.ov1570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.ov1580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.ov1590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.ov15100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    seasonLastXPoints.ov2510 || 
    seasonLastXPoints.ov2551 ||
    seasonLastXPoints.ov2560 ||
    seasonLastXPoints.ov2570 ||
    seasonLastXPoints.ov2580 ||
    seasonLastXPoints.ov2590 ||
    seasonLastXPoints.ov25100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Ov2.5</td>
            <td class="time text-start w-25">
                <span *ngIf="seasonLastXPoints.ov2510">Ov0%<br></span>
                <span *ngIf="seasonLastXPoints.ov2551">Ov51%<br></span>
                <span *ngIf="seasonLastXPoints.ov2560">Ov60%<br></span>
                <span *ngIf="seasonLastXPoints.ov2570">Ov70%<br></span>
                <span *ngIf="seasonLastXPoints.ov2580">Ov80%<br></span>
                <span *ngIf="seasonLastXPoints.ov2590">Ov90%<br></span>
                <span *ngIf="seasonLastXPoints.ov25100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points -->
<table class="table table-sm flex-fill" *ngIf="seasonLastXAnalysisPoints$">
    <caption>
        <Span>Key<br></Span>
        <Span><br></Span>
        <Span>1 :    Win/Loss/Draw<br></Span>
        <Span>2 :    Conform with Odds<br></Span>
        <Span>3 :    Conform with Position<br></Span>
        <Span>4 :    Conform with Position Home/Away<br></Span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">3</th>
            <th class="teams text-start">4</th>
            <th class="teams text-start">Ov 0.5</th>
            <th class="teams text-start">Ov 1.5</th>
            <th class="teams text-start">Ov 2.5</th>
            <th class="teams text-start">Result 1X2</th>
            <th class="teams text-start">Result Ov/Un</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.winDrawAdvantage">{{seasonLastXAnalysisPoints$.winDrawAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.conformWithOddsAdvantage">{{seasonLastXAnalysisPoints$.conformWithOddsAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.conformWithPositionAdvantage">{{seasonLastXAnalysisPoints$.conformWithPositionAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.conformWithPositionHomeAwayAdvantage">{{seasonLastXAnalysisPoints$.conformWithPositionHomeAwayAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.Ov05">Ov0.5</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.Ov15">Ov1.5</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="seasonLastXAnalysisPoints$.Ov25">Ov2.5</span>
            </td>
            <td class="teams text-start">
                <span></span>
            </td>
            <td class="teams text-start">
                <span></span>
            </td>
        </tr>
    </tbody>
</table>