import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTime } from 'src/app/core/interface/date-time';
import { ApiService } from 'src/app/core/service/api.service';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';


@Component({
  selector: 'app-analysis-events',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './analysis-events.component.html',
  styleUrls: ['./analysis-events.component.scss']
})
export class AnalysisEventsComponent implements OnInit, OnDestroy {
  filteredEvent$: any;
  id: any;
  dateForm: FormGroup;
  event$: any[];
  /* dateToday: string; */
  subscription: Subscription = new Subscription();
  todayData: any;
  dates = [];
  datePicked: string;
  requestClick: boolean;
  date$: string;

  constructor(
    private footballService: FootballService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('id1');
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.checkDataAvailableToday();
  }
  checkDataAvailableToday() {
    /* get date today */
    /* check if events today are more than 1 */
    /* date today add day six times while checking more than 1 event */
    /* display date boxes green/red */

    this.subscription.add(this.dateTimeService.getCurrentDate().pipe(first()).subscribe(
      (dateTime: DateTime) => {
        
        this.date$ = dateTime.date;
        let workingDate = new Date(dateTime.date);
        workingDate.setDate(workingDate.getDate());
        
        let monthWords;
        let gameNumber;
        /* for loop add a day 6 times */
        for (let index = 0; index < 6; index++) {
          /* add a day to date */
          /* add to array */
          let year = workingDate.getFullYear();
          let month = ((workingDate.getMonth() + 1).toString()).padStart(2, '0');
          let day = ((workingDate.getDate()).toString()).padStart(2, '0');

          /* get number of events of day */
          this.subscription.add(this.footballService.getAllEventsByDate_List(`${year}-${month}-${day}`).subscribe((list) => {
            gameNumber = list.length;
          }));

          switch (workingDate.getUTCMonth()) {
            case 0:
              monthWords = "January";
              break;
            case 1:
              monthWords = "February";
              break;
            case 2:
              monthWords = "March";
              break;
            case 3:
              monthWords = "April";
              break;
            case 4:
              monthWords = "May";
              break;
            case 5:
              monthWords = "June";
              break;
            case 6:
              monthWords = "July";
              break;
            case 7:
              monthWords = "August";
              break;
            case 8:
              monthWords = "September";
              break;
            case 9:
              monthWords = "October";
              break;
            case 10:
              monthWords = "November";
              break;
            case 11:
              monthWords = "December";
              break;
            default:
              console.log("Date Month Error!");
          }

          this.dates.push({
            date: day,
            month: monthWords || "",
            games: gameNumber || 0
          });

          workingDate.setDate(workingDate.getDate() + 1);
        }

        /* save to array */
        /* array[] for each check if games schedule exists and number of games */

      }));
  }

  initializeForm() {
    this.dateForm = this.fb.group({
      date: [0],
    });

    this.dateForm.get("date").valueChanges.subscribe(x => {
      this.requestClick = false;

      let day = x.slice(8, 10);
      let month = x.slice(5, 7);
      let year = x.slice(0, 4);
      let date = `${year}-${month}-${day}`;
      this.datePicked = date;
      this.subscription.add(this.footballService.getEventList(date).pipe(first())
        .subscribe((events) => {
          //this.responses$ = response;
          this.filteredEvent$ = this.event$ = events;
        }))
    })
  }

  edit(countryId: string) {

  }

  requestEventDateData(){
    this.requestClick = true;
    console.log("requestEventDateData");
    console.log("date:",this.datePicked);
  }

  filter(query) {
    this.filteredEvent$ = (query) ?
      this.event$.filter(c => (c.homeTeamNameLong + " " + c.awayTeamNameLong).toLowerCase().includes(query.toLowerCase())) :
      this.event$;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}



