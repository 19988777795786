import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTime } from 'src/app/core/interface/date-time';
import { Event } from 'src/app/core/interface/event';
import { EventTableAnalysis } from 'src/app/core/interface/event-table-analysis';
import { JackpotBookmaker } from 'src/app/core/interface/jackpot-bookmaker';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';

@Component({
  selector: 'app-analysis-season-last-x',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './analysis-season-last-x.component.html',
  styleUrls: ['./analysis-season-last-x.component.scss']
})
export class AnalysisSeasonLastXComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  todayDate: DateTime;
  weekNumberToday: string;
  fixtureDate: string;
  id: any;
  matchEvent: Event;
  seasonLastXRound: any;
  seasonLastXList: any[];
  roundPicked: any;
  seasonLastXPoints: any;
  seasonLastXAnalysisPoints$: any;

  constructor(
    private dateTimeService: DateTimeService,
    private footballService: FootballService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subscription.add(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams.pipe(first()).subscribe(params => {
          let date = params['date'];
          let date1 = new Date(date);
          console.log("date1:", date1);
          let year = date1.getFullYear();
          let month = ((date1.getMonth() + 1).toString()).padStart(2, '0');
          let day = (date1.getDate().toString()).padStart(2, '0');

          this.fixtureDate = `${year}-${month}-${day}`;
        })

        this.id = Number(this.route.snapshot.paramMap.get('id1'));
        console.log("id:", this.id);
        console.log("eDate:", this.fixtureDate);
        this.subscription.add(dateTimeService.getCurrentDate().subscribe(
          (dateTime) => {
            this.todayDate = dateTime;
            this.weekNumberToday = ((dateTime.yearWeek).toString()).padStart(2, '0');

            this.footballService.getMatchById(this.fixtureDate, this.id).subscribe((event) => {
              this.matchEvent = event;

              console.log(`Date - ${this.fixtureDate} Season - ${event.seasonId} - Home Team Id - ${event.homeTeamId}`);

              if (event) {
                this.roundPicked = 1;

                /* get Season Last X Round List */
                this.subscription.add(
                  this.footballService.getSeasonLastXRoundList(this.fixtureDate, event.seasonId).subscribe((seasonLastXList) => {
                    this.seasonLastXList = seasonLastXList;
                  })
                );
                /* get Season Last X By Round */
                this.subscription.add(
                  this.footballService.getSeasonLastXByRound(this.fixtureDate, event.seasonId, this.roundPicked).subscribe((seasonLastXRound) => {
                    this.seasonLastXRound = seasonLastXRound;
                  })
                );
                /* get Season Last X Analysis */
                this.subscription.add(
                  this.footballService.getSeasonLastXPoints(this.fixtureDate, event.eventId, this.roundPicked).subscribe((seasonLastXPoints) => {
                    this.seasonLastXPoints = seasonLastXPoints;
                  })
                );
                // get analysis points
                this.subscription.add(this.footballService.getSeasonLastXAnalysisPointsByMatch(this.fixtureDate, event.eventId).pipe(first())
                  .subscribe((seasonLastXAnalysisPoints) => {
                    //this.responses$ = response;
                    this.seasonLastXAnalysisPoints$ = seasonLastXAnalysisPoints;
                  }))
              }
            })

          }));
      }
    }))
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  pickRound(round) {
    this.roundPicked = round;
    if (this.fixtureDate && this.matchEvent.seasonId && this.roundPicked) {
      /* get Season Last X */
      this.subscription.add(
        this.footballService.getSeasonLastXByRound(this.fixtureDate, this.matchEvent.seasonId, this.roundPicked).subscribe((seasonLastXRound) => {
          this.seasonLastXRound = seasonLastXRound;
        })
      );
    }
  }

  ngOnInit(): void {
  }

}
