<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Analysis Points List</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Compiled analysis points list against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>
<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill">
        <thead class="table-light">
            <tr class="small-tx">
                <th></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Ov/Un</th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Result</th>
                <th class="teams text-start"></th>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">7</th>
                <th class="teams text-start">8</th>
                <th class="teams text-start">9</th>
                <th class="teams text-start">10</th>
                <th class="teams text-start">11</th>
                <th class="teams text-start">0.5</th>
                <th class="teams text-start">1.5</th>
                <th class="teams text-start">2.5</th>
                <th class="teams text-start">1X2</th>
                <th class="teams text-start">Ov/Un</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx"
                *ngFor="let event of filteredAnalysisPointsList$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>

                <td class="teams text-start">
                    <span *ngIf="event.h2hHomeAwayScoredAdvantage">{{event.h2hHomeAwayScoredAdvantage}}<br></span>
                    <span *ngIf="event.h2hHomeHomeAwayAwayScoredAdvantage">{{event.h2hHomeHomeAwayAwayScoredAdvantage}}<br></span>
                    <span *ngIf="event.lastXHomeAwayScoredAdvantage">{{event.lastXHomeAwayScoredAdvantage}}<br></span>
                    <span
                        *ngIf="event.lastXHomeHomeAwayAwayScoredAdvantage">{{event.lastXHomeHomeAwayAwayScoredAdvantage}}<br></span>
                    <span *ngIf="event.topScorerScoredAdvantage">{{event.topScorerScoredAdvantage}}<br></span>
                    <span *ngIf="event.topScorerScoredAwayAdvantage">{{event.topScorerScoredAwayAdvantage}}<br></span>
                    <span *ngIf="event.topScorerScoredH1Advantage">{{event.topScorerScoredH1Advantage}}<br></span>
                    <span *ngIf="event.topScorerScoredH2Advantage">{{event.topScorerScoredH2Advantage}}<br></span>
                    <span *ngIf="event.topScorerScoredHomeAdvantage">{{event.topScorerScoredHomeAdvantage}}<br></span>
                    <span *ngIf="event.topScorerScoredLastGameAdvantage">{{event.topScorerScoredLastGameAdvantage}}<br></span>
                    <span *ngIf="event.topScorerScoredTeamLastGameAdvantage">{{event.topScorerScoredTeamLastGameAdvantage}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.h2hHomeAwayConcededAdvantage">{{event.h2hHomeAwayConcededAdvantage}}<br></span>
                    <span
                        *ngIf="event.h2hHomeHomeAwayAwayConcededAdvantage">{{event.h2hHomeHomeAwayAwayConcededAdvantage}}<br></span>
                    <span *ngIf="event.lastXHAConcededAdvantage">{{event.lastXHAConcededAdvantage}}<br></span>
                    <span
                        *ngIf="event.lastXHomeHomeAwayAwayConcededAdvantage">{{event.lastXHomeHomeAwayAwayConcededAdvantage}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.h2hHomeAwayWinDrawLossAdvantage">{{event.h2hHomeAwayWinDrawLossAdvantage}}<br></span>
                    <span
                        *ngIf="event.h2hHomeHomeAwayAwayWinDrawLossAdvantage">{{event.h2hHomeHomeAwayAwayWinDrawLossAdvantage}}<br></span>
                    <span *ngIf="event.lastXHAWinDrawLossAdvantage">{{event.lastXHAWinDrawLossAdvantage}}<br></span>
                    <span
                        *ngIf="event.lastXHomeHomeAwayAwayWinDrawLossAdvantage">{{event.lastXHomeHomeAwayAwayWinDrawLossAdvantage}}<br></span>
                    <span *ngIf="event.seasonLastXHWinDrawAdvantage">{{event.seasonLastXHWinDrawAdvantage}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.seasonLastXConformWithOddsAdvantage">{{event.seasonLastXConformWithOddsAdvantage}}</span>
                </td>
                <td class="teams text-start">
                    <span
                        *ngIf="event.seasonLastXConformWithPositionAdvantage">{{event.seasonLastXConformWithPositionAdvantage}}<br></span>
                    <span
                        *ngIf="event.seasonLastXConformWithPositionHomeAwayAdvantage">{{event.seasonLastXConformWithPositionHomeAwayAdvantage}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.tableWinAdvantage">{{event.tableWinAdvantage}}<br></span>
                    <span *ngIf="event.tableWinAwayAdvantage">{{event.tableWinAwayAdvantage}}<br></span>
                    <span *ngIf="event.tableWinHomeAdvantage">{{event.tableWinHomeAdvantage}}<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.tableLossAdvantage">{{event.tableLossAdvantage}}<br></span>
                    <span *ngIf="event.tableLossAwayAdvantage">{{event.tableLossAwayAdvantage}}<br></span>
                    <span *ngIf="event.tableLossHomeAdvantage">{{event.tableLossHomeAdvantage}}<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.tableDrawAdvantage">{{event.tableDrawAdvantage}}<br></span>
                    <span *ngIf="event.tableDrawAwayAdvantage">{{event.tableDrawAwayAdvantage}}<br></span>
                    <span *ngIf="event.tableDrawHomeAdvantage">{{event.tableDrawHomeAdvantage}}<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.tablePosAdvantage">{{event.tablePosAdvantage}}<br></span>
                    <span *ngIf="event.tablePosAwayAdvantage">{{event.tablePosAwayAdvantage}}<br></span>
                    <span *ngIf="event.tablePosHomeAdvantage">{{event.tablePosHomeAdvantage}}<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.topAssistCumulativeAssistAdvantage">{{event.topAssistCumulativeAssistAdvantage}}<br></span>
                    <span *ngIf="event.topAssistPlayerCountAdvantage">{{event.topAssistPlayerCountAdvantage}}<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.topCardCardsCountAdvantage">{{event.topCardCardsCountAdvantage}}<br></span>
                    <span *ngIf="event.topCardCardsH1Advantage">{{event.topCardCardsH1Advantage}}<br></span>
                    <span *ngIf="event.topCardCardsH2Advantage">{{event.topCardCardsH2Advantage}}<br></span>
                    <span *ngIf="event.topCardPlayerCountAdvantage">{{event.topCardPlayerCountAdvantage}}<br></span>
                    <span *ngIf="event.topCardRedCardAdvantage">{{event.topCardRedCardAdvantage}}<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.h2hOv05">Ov0.5<br></span>
                    <span *ngIf="event.lastXOv05">Ov0.5<br></span>
                    <span *ngIf="event.seasonLastXOv05">Ov0.5<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.h2hOv15">Ov1.5<br></span>
                    <span *ngIf="event.lastXOv15">Ov1.5<br></span>
                    <span *ngIf="event.seasonLastXOv15">Ov1.5<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.h2hOv25">Ov2.5<br></span>
                    <span *ngIf="event.lastXOv25">Ov2.5<br></span>
                    <span *ngIf="event.seasonLastXOv25">Ov2.5<br></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.resultOv25">Ov2.5</span>
                    <span *ngIf="!event.resultOv25">
                        <span *ngIf="event.resultOv15">Ov1.5</span>
                        <span *ngIf="!event.resultOv15">
                            <span *ngIf="analysisPointsObject$[event.key].resultOv05">Ov0.5</span>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="!isSearchMode">
    <div *ngIf="analysisPointsObject$">
        <table class="table table-sm  flex-fill " *ngFor="let competition of competition$;let i = index">
            <thead>
                <tr class="small-tx">
                    <span *ngIf="competition.competitionName">{{competition.competitionName}}</span>
                    <span *ngIf="competition.countryName">{{competition.countryName}}</span>
                </tr>
            </thead>
            <thead class="table-light">
                <tr class="small-tx">
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Ov/Un</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">GG/GN/NN</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Result</th>
                    <th class="teams text-start"></th>
                </tr>
            </thead>
            <thead class="table-light">
                <tr class="small-tx">
                    <th class="teams text-start">Match</th>
                    <th class="teams text-start">0.5</th>
                    <th class="teams text-start">1.5</th>
                    <th class="teams text-start">2.5</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">1X2</th>
                    <th class="teams text-start">Ov/Un</th>
                </tr>
            </thead>
            <tbody *ngIf="eventObject">
                <tr class="cursor-pointer status-live small-tx" 
                    *ngFor="let event of (competition.events | keyvalue);let i = index;">
                    <td class="time text-start">
                        <div class="row text-bold">
                            <div class="col-xs" *ngIf="eventObject[event.key]"
                                [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                                <span
                                    *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                    Vs</span><br>
                                <span
                                    *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span [class.green-bd]="analysisPointsObject$[event.key].h2hOv05Rslt && analysisPointsObject$[event.key].rsltA" [class.red-bg]="!(analysisPointsObject$[event.key].h2hOv05Rslt) && analysisPointsObject$[event.key].rsltA"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hOv05">Ov0.5<br></span></span>
                            <span [class.green-bd]="analysisPointsObject$[event.key].lastXOv05Rslt && analysisPointsObject$[event.key].rsltA" [class.red-bg]="!(analysisPointsObject$[event.key].lastXOv05Rslt) && analysisPointsObject$[event.key].rsltA"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXOv05">Ov0.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].seasonLastXOv05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].seasonLastXOv05">Ov0.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].h2hUn05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hUn05">Un0.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].lastXUn05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXUn05">Un0.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].seasonLastXUn05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].seasonLastXUn05">Un0.5<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span [class]="analysisPointsObject$[event.key].h2hOv15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hOv15">Ov1.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].lastXOv15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXOv15">Ov1.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].seasonLastXOv15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].seasonLastXOv15">Ov1.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].h2hUn15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hUn15">Un1.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].lastXUn15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXUn15">Un1.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].seasonLastXUn15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].seasonLastXUn15">Un1.5<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span [class]="analysisPointsObject$[event.key].h2hOv25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hOv25">Ov2.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].lastXOv25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXOv25">Ov2.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].seasonLastXOv25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].seasonLastXOv25">Ov2.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].h2hUn25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hUn25">Un2.5<br></span></span>
                            <span [class]="analysisPointsObject$[event.key].lastXUn25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXUn25">Un2.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].seasonLastXUn25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].seasonLastXUn25">Un2.5<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span
                                [class]="analysisPointsObject$[event.key].h2hHomeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hHomeHomeAwayAwayGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].h2hHomeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hHomeHomeAwayAwayGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].h2hHomeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hHomeHomeAwayAwayNN">NN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].lastXHomeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXHomeHomeAwayAwayGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].lastXHomeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXHomeHomeAwayAwayGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].lastXHomeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXHomeHomeAwayAwayNN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span
                                [class]="analysisPointsObject$[event.key].h2hHomeAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hHomeAwayGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].h2hHomeAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hHomeAwayGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].h2hHomeAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].h2hHomeAwayNN">NN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].lastXHAGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXHAGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].lastXHAGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXHAGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[event.key].lastXHANNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[event.key].lastXHANN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span
                                *ngIf="analysisPointsObject$[event.key].result1X2">{{analysisPointsObject$[event.key].result1X2}}</span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[event.key]">
                            <span *ngIf="analysisPointsObject$[event.key].resultOv25">Ov2.5</span>
                            <span *ngIf="!analysisPointsObject$[event.key].resultOv25">
                                <span *ngIf="analysisPointsObject$[event.key].resultOv15">Ov1.5</span>
                                <span *ngIf="!analysisPointsObject$[event.key].resultOv15">
                                    <span *ngIf="analysisPointsObject$[event.key].resultOv05">Ov0.5</span>
                                </span><br>
                            </span>
                            <span *ngIf="analysisPointsObject$[event.key].resultUn25">Un2.5</span>
                            <span *ngIf="!analysisPointsObject$[event.key].resultUn25">
                                <span *ngIf="analysisPointsObject$[event.key].resultUn15">Un1.5</span>
                                <span *ngIf="!analysisPointsObject$[event.key].resultUn15">
                                    <span *ngIf="analysisPointsObject$[event.key].resultUn05">Un0.5</span>
                                </span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>Home Vs Away (Cumulative)<br></Span>
    <Span>1 : Scored<br></Span>
    <Span>2 : Conceded<br></Span>
    <Span>3 : Win Loss Draw<br></Span>
    <Span>Home Team at Home Vs Away Team at Away<br></Span>
    <Span>4 : Scored<br></Span>
    <Span>5 : Conceded<br></Span>
    <Span>6 : Win Loss Draw<br></Span>
</p>