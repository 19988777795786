<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Analysis Points List</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Compiled analysis points list against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>
<div *ngIf="oneXTwoS4Object$">
    <table class="table table-sm flex-fill" *ngFor="let event of (filteredOneXTwoS4List$|keyvalue);let i = index;">
        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Time</th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Tip</th>
                <th class="teams text-start">Result</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx" *ngFor="let e of oneXTwoS4Object$[event.key];let ind = index;">
                <td>
                    <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                    {{ind+1}}.
                    </span>
                </td>
                <td class="teams text-start">
                    <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                        <span *ngIf="e.eventTime">{{e.eventTime}}<br></span>
                        <span *ngIf="e.eventStatus">{{e.eventStatus}}</span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                        <button class="btn btn-sm btn-outline-danger" (click)="excludeCompetition(e.seasonId)" *ngIf="e.seasonId && e.cmpName">{{e.cmpName}}</button>
                        <span *ngIf="e.cntryName"><br>{{e.cntryName}}</span>
                    </span>
                </td>
                <td class="time text-start w-25">
                    <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                        <div class="row text-bold">
                            <div class="col-xs"
                                [class.text-decoration-line-through]="e.eventStatus === 'Cancelled' || e.eventStatus === 'Postponed'">
                                <button class="btn btn-sm btn-outline-danger" *ngIf="e.eHTVirtual">V</button>
                                <span *ngIf="e.homeTeamNameLong">{{e.homeTeamNameLong}}
                                    Vs</span><br>
                                <button class="btn btn-sm btn-outline-danger" *ngIf="e.eATVirtual">V</button>
                                <span *ngIf="e.awayTeamNameLong">{{e.awayTeamNameLong}}</span>
                            </div>
                        </div>
                    </span>
                </td>
                <td class="teams text-start">
                    <button class="btn btn-sm btn-outline-danger" *ngIf="e.drby">D</button>
                </td>
                <td class="teams text-start">
                    <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                        <span *ngIf="e.tip">{{e.tip}}<br></span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                        <span *ngIf="e.eventStatus === 'Cancelled' || e.eventStatus === 'Postponed' || e.eventStatus === 'Not Started'">
                            <span *ngIf="e.rsltHT">{{e.rsltHT}}</span><span *ngIf="!e.rsltHT">--</span>:<span *ngIf="!e.rsltAT">--</span><span *ngIf="e.rsltAT">{{e.rsltAT}}</span>
                        </span>
                        <span *ngIf="e.eventStatus === 'Ended'">
                            <span *ngIf="e.rsltHT">{{e.rsltHT}}</span><span *ngIf="!e.rsltHT">0</span>:<span *ngIf="!e.rsltAT">0</span><span *ngIf="e.rsltAT">{{e.rsltAT}}</span>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>