<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Analysis Points List</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Compiled analysis points list against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-4"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4"></div>
    <div class="col-4"></div>
    <div class="col-4">
        <div class="form-check form-check-inline  mb-3">
            <input class="form-check-input" formControlName="view_pick" name="view_pick" value="1" type="radio"
                id="inlineFormCheck">
            <label class="form-check-label" for="inlineFormCheck">
                Default view
            </label>
        </div>
        <div class="form-check form-check-inline  mb-3">
            <input class="form-check-input" formControlName="view_pick" name="view_pick" value="2" type="radio"
                id="inlineFormCheck2">
            <label class="form-check-label" for="inlineFormCheck">
                Points View
            </label>
        </div>
    </div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>
<div *ngIf="defaultView">
    <div *ngIf="oneXTwoS1Object$">
        <table class="table table-sm flex-fill caption-top"
            *ngFor="let event of (filteredOneXTwoS1List$|keyvalue);let i = index;">
            <caption>Slip No.{{event.key}}</caption>
            <thead class="table-light">
                <tr class="small-tx">
                    <th>#</th>
                    <th class="teams text-start">Time</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Match</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Tip</th>
                    <th class="teams text-start">Result</th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor-pointer status-live small-tx"
                    *ngFor="let e of oneXTwoS1Object$[event.key];let ind = index;">
                    <td>
                        <span [class.green-bd]="e.tipRslt">
                            {{ind+1}}.
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                            <span *ngIf="e.eventTime">{{e.eventTime}}<br></span>
                            <span *ngIf="e.eventStatus">{{e.eventStatus}}</span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                            <button class="btn btn-sm btn-outline-danger" (click)="excludeCompetition(e.seasonId)"
                                *ngIf="e.seasonId && e.cmpName">{{e.cmpName}}</button>
                            <span *ngIf="e.cntryName"><br>{{e.cntryName}}</span>
                        </span>
                    </td>
                    <td class="time text-start w-25">
                        <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                            <div class="row text-bold">
                                <div class="col-xs"
                                    [class.text-decoration-line-through]="e.eventStatus === 'Cancelled' || e.eventStatus === 'Postponed'">
                                    <button class="btn btn-sm btn-outline-danger" *ngIf="e.eHTVirtual">V</button>
                                    <span *ngIf="e.homeTeamNameLong">{{e.homeTeamNameLong}}
                                        Vs</span><br>
                                    <button class="btn btn-sm btn-outline-danger" *ngIf="e.eATVirtual">V</button>
                                    <span *ngIf="e.awayTeamNameLong">{{e.awayTeamNameLong}}</span>
                                </div>
                            </div>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <button class="btn btn-sm btn-outline-danger" *ngIf="e.drby">D</button>
                    </td>
                    <td class="teams text-start">
                        <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                            <span *ngIf="e.tip">{{e.tip}}<br></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                            <span
                                *ngIf="e.eventStatus === 'Cancelled' || e.eventStatus === 'Postponed' || e.eventStatus === 'Not Started'">
                                <span *ngIf="e.rsltHT">{{e.rsltHT}}</span><span *ngIf="!e.rsltHT">--</span>:<span
                                    *ngIf="!e.rsltAT">--</span><span *ngIf="e.rsltAT">{{e.rsltAT}}</span>
                            </span>
                            <span *ngIf="e.eventStatus === 'Ended'">
                                <span *ngIf="e.rsltHT">{{e.rsltHT}}</span><span *ngIf="!e.rsltHT">0</span>:<span
                                    *ngIf="!e.rsltAT">0</span><span *ngIf="e.rsltAT">{{e.rsltAT}}</span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="pointView">
    <div *ngIf="oneXTwoS1Object$ && analysisPointsObject$">
        <table class="table table-sm flex-fill caption-top"
            *ngFor="let event of (filteredOneXTwoS1List$|keyvalue);let i = index;">
            <caption>Slip No.{{event.key}}</caption>
            <thead class="table-light">
                <tr class="small-tx">
                    <th>#</th>
                    <th class="teams text-start">1</th>
                    <th class="teams text-start">2</th>
                    <th class="teams text-start">3</th>
                    <th class="teams text-start">4</th>
                    <th class="teams text-start">5</th>
                    <th class="teams text-start">6</th>
                    <th class="teams text-start">7</th>
                    <th class="teams text-start">8</th>
                    <th class="teams text-start">9</th>
                    <th class="teams text-start">10</th>
                    <th class="teams text-start">11</th>
                    <th class="teams text-start">12</th>
                    <th class="teams text-start">1</th>
                    <th class="teams text-start">2</th>
                    <th class="teams text-start">3</th>
                    <th class="teams text-start">4</th>
                    <th class="teams text-start">5</th>
                    <th class="teams text-start">6</th>
                    <th class="teams text-start">7</th>
                    <th class="teams text-start">8</th>
                    <th class="teams text-start">9</th>
                    <th class="teams text-start">10</th>
                    <th class="teams text-start">11</th>
                    <th class="teams text-start">12</th>
                    <th class="teams text-start">1</th>
                    <th class="teams text-start">2</th>
                    <th class="teams text-start">3</th>
                    <th class="teams text-start">4</th>
                    <th class="teams text-start">5</th>
                    <th class="teams text-start">6</th>
                    <th class="teams text-start">7</th>
                    <th class="teams text-start">8</th>
                    <th class="teams text-start">9</th>
                    <th class="teams text-start">10</th>
                    <th class="teams text-start">11</th>
                    <th class="teams text-start">12</th>
                    <th class="teams text-start">1</th>
                    <th class="teams text-start">2</th>
                    <th class="teams text-start">3</th>
                    <th class="teams text-start">4</th>
                    <th class="teams text-start">5</th>
                    <th class="teams text-start">6</th>
                    <th class="teams text-start">7</th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor-pointer status-live small-tx"
                    *ngFor="let e of oneXTwoS1Object$[event.key];let ind = index;">
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].h2hHomeAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeAwayScoredAdvantage">{{analysisPointsObject$[e.eventId].h2hHomeAwayScoredAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">

                            <span [class]="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayScoredAdvantage">{{analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayScoredAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].lastXHomeAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeAwayScoredAdvantage">{{analysisPointsObject$[e.eventId].lastXHomeAwayScoredAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayScoredAdvantage">{{analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayScoredAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredAdvantage">{{analysisPointsObject$[e.eventId].topScorerScoredAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredAwayAdvantage">{{analysisPointsObject$[e.eventId].topScorerScoredAwayAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredH1AdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredH1Advantage">{{analysisPointsObject$[e.eventId].topScorerScoredH1Advantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredH2AdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredH2Advantage">{{analysisPointsObject$[e.eventId].topScorerScoredH2Advantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredHAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredHomeAdvantage">{{analysisPointsObject$[e.eventId].topScorerScoredHomeAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredLastGameAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredLastGameAdvantage">{{analysisPointsObject$[e.eventId].topScorerScoredLastGameAdvantage}}<br></span></span>
                        </span>
                    </td><!-- 10 -->
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topScorerScoredTeamLastGameAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topScorerScoredTeamLastGameAdvantage">{{analysisPointsObject$[e.eventId].topScorerScoredTeamLastGameAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].h2hHomeAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeAwayConcededAdvantage">{{analysisPointsObject$[e.eventId].h2hHomeAwayConcededAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayConcededAdvantage">{{analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayConcededAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHAConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHAConcededAdvantage">{{analysisPointsObject$[e.eventId].lastXHAConcededAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayConcededAdvantage">{{analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayConcededAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeAwayWinDrawLossAdvantage">{{analysisPointsObject$[e.eventId].h2hHomeAwayWinDrawLossAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayWinDrawLossAdvantage">{{analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayWinDrawLossAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHAWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHAWinDrawLossAdvantage">{{analysisPointsObject$[e.eventId].lastXHAWinDrawLossAdvantage}}<br></span></span>
                           </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayWinDrawLossAdvantage">{{analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayWinDrawLossAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXHWinDrawAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXHWinDrawAdvantage">{{analysisPointsObject$[e.eventId].seasonLastXHWinDrawAdvantage}}</span></span></span>
                    </td><!-- 20 -->
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXConformWithOddsAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXConformWithOddsAdvantage">{{analysisPointsObject$[e.eventId].seasonLastXConformWithOddsAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXConformWithPositionHomeAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXConformWithPositionHomeAwayAdvantage">{{analysisPointsObject$[e.eventId].seasonLastXConformWithPositionHomeAwayAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXConformWithPositionAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXConformWithPositionAdvantage">{{analysisPointsObject$[e.eventId].seasonLastXConformWithPositionAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableWinAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableWinAdvantage">{{analysisPointsObject$[e.eventId].tableWinAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableWinAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableWinAwayAdvantage">{{analysisPointsObject$[e.eventId].tableWinAwayAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableWinHAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableWinHomeAdvantage">{{analysisPointsObject$[e.eventId].tableWinHomeAdvantage}}<br></span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableLossAdvantage">{{analysisPointsObject$[e.eventId].tableLossAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableLossAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableLossAwayAdvantage">{{analysisPointsObject$[e.eventId].tableLossAwayAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableLossHAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableLossHomeAdvantage">{{analysisPointsObject$[e.eventId].tableLossHomeAdvantage}}<br></span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableDrawAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableDrawAdvantage">{{analysisPointsObject$[e.eventId].tableDrawAdvantage}}<br></span></span>
                            </span>
                    </td><!-- 30 -->
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableDrawAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableDrawAwayAdvantage">{{analysisPointsObject$[e.eventId].tableDrawAwayAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tableDrawHAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tableDrawHomeAdvantage">{{analysisPointsObject$[e.eventId].tableDrawHomeAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tablePosAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tablePosAdvantage">{{analysisPointsObject$[e.eventId].tablePosAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tablePosAwayAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tablePosAwayAdvantage">{{analysisPointsObject$[e.eventId].tablePosAwayAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].tablePosHAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].tablePosHomeAdvantage">{{analysisPointsObject$[e.eventId].tablePosHomeAdvantage}}<br></span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]"><span
                                [class]="analysisPointsObject$[e.eventId].topAssistPlayerCountAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topAssistPlayerCountAdvantage">{{analysisPointsObject$[e.eventId].topAssistPlayerCountAdvantage}}<br></span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topAssistCumulativeAssistAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topAssistCumulativeAssistAdvantage">{{analysisPointsObject$[e.eventId].topAssistCumulativeAssistAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topCardCardsCountAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topCardCardsCountAdvantage">{{analysisPointsObject$[e.eventId].topCardCardsCountAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].topCardCardsH1AdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topCardCardsH1Advantage">{{analysisPointsObject$[e.eventId].topCardCardsH1Advantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].topCardCardsH2AdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topCardCardsH2Advantage">{{analysisPointsObject$[e.eventId].topCardCardsH2Advantage}}<br></span></span>
                            </span>
                    </td><!-- 40 -->
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].topCardPlayerCountAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topCardPlayerCountAdvantage">{{analysisPointsObject$[e.eventId].topCardPlayerCountAdvantage}}<br></span></span>
                            </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].topCardRedCardAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].topCardRedCardAdvantage">{{analysisPointsObject$[e.eventId].topCardRedCardAdvantage}}<br></span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].adv1X2CountAdvantage">{{analysisPointsObject$[e.eventId].adv1X2CountAdvantage}}<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span [class.green-bd]="e.tipRslt && e.rsltA" [class.red-bg]="!(e.tipRslt) && e.rsltA">
                            <span *ngIf="e.tip">{{e.tip}}<br></span>
                        </span>
                    </td>
                    <!-- <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].h2hOv05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hOv05">Ov0.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].lastXOv05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXOv05">Ov0.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXOv05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXOv05">Ov0.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].h2hUn05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hUn05">Un0.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].lastXUn05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXUn05">Un0.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXUn05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXUn05">Un0.5<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].h2hOv15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hOv15">Ov1.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].lastXOv15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXOv15">Ov1.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXOv15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXOv15">Ov1.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].h2hUn15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hUn15">Un1.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].lastXUn15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXUn15">Un1.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXUn15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXUn15">Un1.5<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span [class]="analysisPointsObject$[e.eventId].h2hOv25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hOv25">Ov2.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].lastXOv25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXOv25">Ov2.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXOv25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXOv25">Ov2.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].h2hUn25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hUn25">Un2.5<br></span></span>
                            <span [class]="analysisPointsObject$[e.eventId].lastXUn25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXUn25">Un2.5<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].seasonLastXUn25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].seasonLastXUn25">Un2.5<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeHomeAwayAwayNN">NN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHomeHomeAwayAwayNN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="analysisPointsObject$[e.eventId]">
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeAwayGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeAwayGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].h2hHomeAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].h2hHomeAwayNN">NN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHAGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHAGG">GG<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHAGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHAGN">GN<br></span></span>
                            <span
                                [class]="analysisPointsObject$[e.eventId].lastXHANNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="analysisPointsObject$[e.eventId].lastXHANN">NN<br></span></span>
                        </span>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>