<!-- team intro -->
<div class="d-inline-flex my-md-3" *ngIf="matchEvent">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span
                    *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- topScorer -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="topScorer && topScorer.players">
    <h4 class="display-6 fw-normal mb-0">Season Top Scorer</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light"
    *ngIf="topScorer && topScorer.players">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Played</th>
            <th scope="col">1st Half</th>
            <th scope="col">2nd Half</th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topScorer.players;let i = index;">
            <td scope="col">{{i+1}}</td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.player.name">{{t.player.name}}</span><br>
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId]"
                        *ngIf="t.teams[matchEvent.homeTeamId]">{{matchEvent.homeTeamNameLong}}</span>
                    <span [class.fw-bold]="t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.teams[matchEvent.awayTeamId]">{{matchEvent.awayTeamNameLong}}</span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.player.position">
                        <span *ngIf="t.player.position.abbr">{{t.player.position.abbr}}</span>
                        <span *ngIf="!t.player.position.abbr">-</span>
                    </span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total"><span
                        [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.matches">{{t.total.matches}}</span></span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.firsthalf"><span
                        [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.firsthalf.goals">{{t.firsthalf.goals}}</span></span>
                <span *ngIf="!t.firsthalf">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.secondhalf"><span
                        [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.secondhalf.goals">{{t.secondhalf.goals}}</span></span>
                <span *ngIf="!t.secondhalf">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.home"><span
                        [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.home.goals">{{t.home.goals}}</span></span>
                <span *ngIf="!t.home">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.away"><span
                        [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.away.goals">{{t.away.goals}}</span></span>
                <span *ngIf="!t.away">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total"><span
                        [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.goals">{{t.total.goals}}</span></span>
                <span *ngIf="!t.total">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- Home Top Scorer -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="homeTeamTopScorer">
    <h4 class="display-6 fw-normal mb-0">{{matchEvent.homeTeamNameLong}}</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="homeTeamTopScorer">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Played</th>
            <th scope="col">1st Half</th>
            <th scope="col">2nd Half</th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of homeTeamTopScorer|keyvalue;let i = index;">
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].positionNumber">
                    {{homeTeamTopScorer[t.key].positionNumber}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].positionNumber">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].playerName">
                    {{homeTeamTopScorer[t.key].playerName}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].playerName">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].position">
                    {{homeTeamTopScorer[t.key].position}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].position">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].matches">
                    {{homeTeamTopScorer[t.key].matches}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].matches">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].H1Goals">
                    {{homeTeamTopScorer[t.key].H1Goals}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].H1Goals">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].H2Goals">
                    {{homeTeamTopScorer[t.key].H2Goals}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].H2Goals">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].homeGoals">
                    {{homeTeamTopScorer[t.key].homeGoals}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].homeGoals">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].awayGoals">
                    {{homeTeamTopScorer[t.key].awayGoals}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].awayGoals">-</span>
            </td>
            <td scope="col" *ngIf="homeTeamTopScorer[t.key]">
                <span *ngIf="homeTeamTopScorer[t.key].goals">
                    {{homeTeamTopScorer[t.key].goals}}
                </span>
                <span *ngIf="!homeTeamTopScorer[t.key].goals">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- Home Top Scorer Data -->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="homeTeamTopScorerTeams">
    <caption>Cumulative top scorers data</caption>
    <thead>
        <tr>
            <th scope="col">Highest Rank</th>
            <th scope="col">Scorers</th>
            <th scope="col">Goals</th>
            <th scope="col">Positions</th>
            <th scope="col">1st Half</th>
            <th scope="col">2nd Half</th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">Last Game Goals</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="homeTeamTopScorerTeams">
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.highestScorerPosition">
                    <span>{{homeTeamTopScorerTeams.highestScorerPosition + 1}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.highestScorerPosition">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <div *ngFor="let scorer of homeTeamTopScorerTeams.scorersName">
                    <span>{{scorer}}</span><br>
                </div>
            </td>
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.cumulativeGoals">
                    <span>{{homeTeamTopScorerTeams.cumulativeGoals}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.cumulativeGoals">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <div *ngFor="let position of homeTeamTopScorerTeams.scorersPosition">
                    <span>{{position}}</span>
                </div>
            </td>
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.cumulativeGoalsH1">
                    <span>{{homeTeamTopScorerTeams.cumulativeGoalsH1}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.cumulativeGoalsH1">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.cumulativeGoalsH2">
                    <span>{{homeTeamTopScorerTeams.cumulativeGoalsH2}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.cumulativeGoalsH2">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.cumulativeGoalsHome">
                    <span>{{homeTeamTopScorerTeams.cumulativeGoalsHome}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.cumulativeGoalsHome">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.cumulativeGoalsAway">
                    <span>{{homeTeamTopScorerTeams.cumulativeGoalsAway}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.cumulativeGoalsAway">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="homeTeamTopScorerTeams.cumulativeLastTeamGameGoals">
                    <span>{{homeTeamTopScorerTeams.cumulativeLastTeamGameGoals}}</span>
                </span>
                <span *ngIf="!homeTeamTopScorerTeams.cumulativeLastTeamGameGoals">
                    <span>-</span>
                </span>
            </td>
        </tr>
    </tbody>
</table>

<!-- Away Top Scorer -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="awayTeamTopScorer">
    <h4 class="display-6 fw-normal mb-0">{{matchEvent.awayTeamNameLong}}</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="awayTeamTopScorer">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Played</th>
            <th scope="col">1st Half</th>
            <th scope="col">2nd Half</th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of awayTeamTopScorer|keyvalue;let i = index;">
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].positionNumber">
                    {{awayTeamTopScorer[t.key].positionNumber}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].positionNumber">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].playerName">
                    {{awayTeamTopScorer[t.key].playerName}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].playerName">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].position">
                    {{awayTeamTopScorer[t.key].position}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].position">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].matches">
                    {{awayTeamTopScorer[t.key].matches}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].matches">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].H1Goals">
                    {{awayTeamTopScorer[t.key].H1Goals}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].H1Goals">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].H2Goals">
                    {{awayTeamTopScorer[t.key].H2Goals}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].H2Goals">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].awayGoals">
                    {{awayTeamTopScorer[t.key].awayGoals}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].awayGoals">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].awayGoals">
                    {{awayTeamTopScorer[t.key].awayGoals}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].awayGoals">-</span>
            </td>
            <td scope="col" *ngIf="awayTeamTopScorer[t.key]">
                <span *ngIf="awayTeamTopScorer[t.key].goals">
                    {{awayTeamTopScorer[t.key].goals}}
                </span>
                <span *ngIf="!awayTeamTopScorer[t.key].goals">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- Away Top Scorer Data -->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="awayTeamTopScorerTeams">
    <caption>Cumulative top scorers data</caption>
    <thead>
        <tr>
            <th scope="col">Highest Rank</th>
            <th scope="col">Scorers</th>
            <th scope="col">Goals</th>
            <th scope="col">Positions</th>
            <th scope="col">1st Half</th>
            <th scope="col">2nd Half</th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">Last Game Goals</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="awayTeamTopScorerTeams">
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.highestScorerPosition">
                    <span>{{awayTeamTopScorerTeams.highestScorerPosition + 1}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.highestScorerPosition">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <div *ngFor="let scorer of awayTeamTopScorerTeams.scorersName">
                    <span>{{scorer}}</span><br>
                </div>
            </td>
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.cumulativeGoals">
                    <span>{{awayTeamTopScorerTeams.cumulativeGoals}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.cumulativeGoals">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <div *ngFor="let position of awayTeamTopScorerTeams.scorersPosition">
                    <span>{{position}}</span>
                </div>
            </td>
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.cumulativeGoalsH1">
                    <span>{{awayTeamTopScorerTeams.cumulativeGoalsH1}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.cumulativeGoalsH1">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.cumulativeGoalsH2">
                    <span>{{awayTeamTopScorerTeams.cumulativeGoalsH2}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.cumulativeGoalsH2">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.cumulativeGoalsHome">
                    <span>{{awayTeamTopScorerTeams.cumulativeGoalsHome}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.cumulativeGoalsHome">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.cumulativeGoalsAway">
                    <span>{{awayTeamTopScorerTeams.cumulativeGoalsAway}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.cumulativeGoalsAway">
                    <span>-</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="awayTeamTopScorerTeams.cumulativeLastTeamGameGoals">
                    <span>{{awayTeamTopScorerTeams.cumulativeLastTeamGameGoals}}</span>
                </span>
                <span *ngIf="!awayTeamTopScorerTeams.cumulativeLastTeamGameGoals">
                    <span>-</span>
                </span>
            </td>
        </tr>
    </tbody>
</table>

<!-- Top Scorer Points -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="topScorerPoints">
    <h4 class="display-6 fw-normal mb-0">Points</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topScorerPoints">
    <caption>Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">H1</th>
            <th scope="col">H2</th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">Rank</th>
            <th scope="col">Last Game Goals</th>
            <th scope="col">Goal Count</th>
            <th scope="col">Scorers Count</th>
            <th scope="col">Goal Per Game</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSGoalsH1Advantage">
                    {{topScorerPoints.tSGoalsH1Advantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSGoalsH2Advantage">
                    {{topScorerPoints.tSGoalsH2Advantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSGoalsHAdvantage">
                    {{topScorerPoints.tSGoalsHAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSGoalsAwayAdvantageantage">
                    {{topScorerPoints.tSGoalsAwayAdvantageantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSHighestScorerRankAdvantage">
                    {{topScorerPoints.tSHighestScorerRankAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSTeamLastGameGoalsAdvantage">
                    {{topScorerPoints.tSTeamLastGameGoalsAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSGoalsAdvantage">
                    {{topScorerPoints.tSGoalsAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSScorersAdvantage">
                    {{topScorerPoints.tSScorersAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.tSGoalsPerMatchAdvantage">
                    {{topScorerPoints.tSGoalsPerMatchAdvantage}}
                </div>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topScorerPoints">
    <caption>Cumulative Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col">Neutral</th>
            <th scope="col">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Count</span>
            </td>
            <td scope="col">
                <span *ngIf="topScorerPoints.hAdvantageCount">
                    {{topScorerPoints.hAdvantageCount}}<br>
                </span>
                <span *ngIf="topScorerPoints.hAdvantageCountPercentage">
                    {{topScorerPoints.hAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topScorerPoints.hAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!topScorerPoints.hAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topScorerPoints.neutralAdvantageCount">
                    {{topScorerPoints.neutralAdvantageCount}}<br>
                </span>
                <span *ngIf="topScorerPoints.neutralAdvantageCountPercentage">
                    {{topScorerPoints.neutralAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topScorerPoints.neutralAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!topScorerPoints.neutralAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topScorerPoints.aAdvantageCount">
                    {{topScorerPoints.aAdvantageCount}}<br>
                </span>
                <span *ngIf="!topScorerPoints.aAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="topScorerPoints.aAdvantageCountPercentage">
                    {{topScorerPoints.aAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topScorerPoints.aAdvantageCountPercentage">
                    0%
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topScorerPoints">
    <caption>Conclusion</caption>
    <thead>
        <tr>
            <th scope="col">Greater Than</th>
            <th scope="col">50%</th>
            <th scope="col">60%</th>
            <th scope="col">70%</th>
            <th scope="col">80%</th>
            <th scope="col">90%</th>
            <th scope="col">100%</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.advantage51">
                    {{topScorerPoints.advantage51}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.advantage60">
                    {{topScorerPoints.advantage60}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.advantage70">
                    {{topScorerPoints.advantage70}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.advantage80">
                    {{topScorerPoints.advantage80}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.advantage90">
                    {{topScorerPoints.advantage90}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topScorerPoints.advantage100">
                    {{topScorerPoints.advantage100}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

<!-- top scorer analysis -->
<table class="table table-sm flex-fill" *ngIf="topScorerAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} Vs<br></span>
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                </span>
            </th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                </span>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="
    topScorerAnalysis.goalDiffHHomePercentage01 || 
    topScorerAnalysis.goalDiffHHomePercentage20 ||
    topScorerAnalysis.goalDiffHHomePercentage30 ||
    topScorerAnalysis.goalDiffHHomePercentage40 ||
    topScorerAnalysis.goalDiffHHomePercentage50 ||
    topScorerAnalysis.goalDiffAHomePercentage01 || 
    topScorerAnalysis.goalDiffAHomePercentage20 ||
    topScorerAnalysis.goalDiffAHomePercentage30 ||
    topScorerAnalysis.goalDiffAHomePercentage40 ||
    topScorerAnalysis.goalDiffAHomePercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffHHomePercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHHomePercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHHomePercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHHomePercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffAHomePercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAHomePercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAHomePercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAHomePercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAHomePercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topScorerAnalysis.goalDiffHAwayPercentage01 || 
    topScorerAnalysis.goalDiffHAwayPercentage20 ||
    topScorerAnalysis.goalDiffHAwayPercentage30 ||
    topScorerAnalysis.goalDiffHAwayPercentage40 ||
    topScorerAnalysis.goalDiffHAwayPercentage50 ||
    topScorerAnalysis.goalDiffAAwayPercentage01 || 
    topScorerAnalysis.goalDiffAAwayPercentage20 ||
    topScorerAnalysis.goalDiffAAwayPercentage30 ||
    topScorerAnalysis.goalDiffAAwayPercentage40 ||
    topScorerAnalysis.goalDiffAAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffHAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHAwayPercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffAAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topScorerAnalysis.goalDiffHH1Percentage01 || 
    topScorerAnalysis.goalDiffHH1Percentage20 ||
    topScorerAnalysis.goalDiffHH1Percentage30 ||
    topScorerAnalysis.goalDiffHH1Percentage40 ||
    topScorerAnalysis.goalDiffHH1Percentage50 ||
    topScorerAnalysis.goalDiffAH1Percentage01 || 
    topScorerAnalysis.goalDiffAH1Percentage20 ||
    topScorerAnalysis.goalDiffAH1Percentage30 ||
    topScorerAnalysis.goalDiffAH1Percentage40 ||
    topScorerAnalysis.goalDiffAH1Percentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>H1</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffHH1Percentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH1Percentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH1Percentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH1Percentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH1Percentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffAH1Percentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH1Percentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH1Percentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH1Percentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH1Percentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topScorerAnalysis.goalDiffHH2Percentage01 || 
    topScorerAnalysis.goalDiffHH2Percentage20 ||
    topScorerAnalysis.goalDiffHH2Percentage30 ||
    topScorerAnalysis.goalDiffHH2Percentage40 ||
    topScorerAnalysis.goalDiffHH2Percentage50 ||
    topScorerAnalysis.goalDiffAH2Percentage01 || 
    topScorerAnalysis.goalDiffAH2Percentage20 ||
    topScorerAnalysis.goalDiffAH2Percentage30 ||
    topScorerAnalysis.goalDiffAH2Percentage40 ||
    topScorerAnalysis.goalDiffAH2Percentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>H2</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffHH2Percentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH2Percentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH2Percentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH2Percentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHH2Percentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffAH2Percentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH2Percentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH2Percentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH2Percentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAH2Percentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topScorerAnalysis.goalDiffHLastTeamGamePercentage01 || 
    topScorerAnalysis.goalDiffHLastTeamGamePercentage20 ||
    topScorerAnalysis.goalDiffHLastTeamGamePercentage30 ||
    topScorerAnalysis.goalDiffHLastTeamGamePercentage40 ||
    topScorerAnalysis.goalDiffHLastTeamGamePercentage50 ||
    topScorerAnalysis.goalDiffALastTeamGamePercentage01 || 
    topScorerAnalysis.goalDiffALastTeamGamePercentage20 ||
    topScorerAnalysis.goalDiffALastTeamGamePercentage30 ||
    topScorerAnalysis.goalDiffALastTeamGamePercentage40 ||
    topScorerAnalysis.goalDiffALastTeamGamePercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Last Game</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffHLastTeamGamePercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHLastTeamGamePercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHLastTeamGamePercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHLastTeamGamePercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHLastTeamGamePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffALastTeamGamePercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffALastTeamGamePercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffALastTeamGamePercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffALastTeamGamePercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffALastTeamGamePercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topScorerAnalysis.goalDiffHomePercentage01 || 
    topScorerAnalysis.goalDiffHomePercentage20 ||
    topScorerAnalysis.goalDiffHomePercentage30 ||
    topScorerAnalysis.goalDiffHomePercentage40 ||
    topScorerAnalysis.goalDiffHomePercentage50 ||
    topScorerAnalysis.goalDiffAwayPercentage01 || 
    topScorerAnalysis.goalDiffAwayPercentage20 ||
    topScorerAnalysis.goalDiffAwayPercentage30 ||
    topScorerAnalysis.goalDiffAwayPercentage40 ||
    topScorerAnalysis.goalDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.goalDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.goalDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topScorerAnalysis.scorerCountDiffHomePercentage01 || 
    topScorerAnalysis.scorerCountDiffHomePercentage20 ||
    topScorerAnalysis.scorerCountDiffHomePercentage30 ||
    topScorerAnalysis.scorerCountDiffHomePercentage40 ||
    topScorerAnalysis.scorerCountDiffHomePercentage50 ||
    topScorerAnalysis.scorerCountDiffAwayPercentage01 || 
    topScorerAnalysis.scorerCountDiffAwayPercentage20 ||
    topScorerAnalysis.scorerCountDiffAwayPercentage30 ||
    topScorerAnalysis.scorerCountDiffAwayPercentage40 ||
    topScorerAnalysis.scorerCountDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Scorers Count</td>
            <td class="time text-start w-25">
                <span *ngIf="topScorerAnalysis.scorerCountDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topScorerAnalysis.scorerCountDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topScorerAnalysis.scorerCountDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points -->
<table class="table table-sm flex-fill" *ngIf="topScorerAnalysisPoints$">
    <caption>
        <Span>Key<br></Span>
        <Span><br></Span>
        <Span>1 :    Scorer Count<br></Span>
        <Span>2 :    Scored<br></Span>
        <Span>3 :    Scored Home<br></Span>
        <Span>4 :    Scored Away<br></Span>
        <Span>5 :    Scored H1<br></Span>
        <Span>6 :    Scored H2<br></Span>
        <Span>7 :    Scored at Last Game<br></Span>
        <Span>8 :    Scored at Last Team Game<br></Span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">3</th>
            <th class="teams text-start">4</th>
            <th class="teams text-start">5</th>
            <th class="teams text-start">6</th>
            <th class="teams text-start">7</th>
            <th class="teams text-start">8</th>
            <th class="teams text-start">Result 1X2</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scorerCountAdvantage">{{topScorerAnalysisPoints$.scorerCountAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredAdvantage">{{topScorerAnalysisPoints$.scoredAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredHomeAdvantage">{{topScorerAnalysisPoints$.scoredHomeAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredAwayAdvantage">{{topScorerAnalysisPoints$.scoredAwayAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredH1Advantage">{{topScorerAnalysisPoints$.scoredH1Advantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredH2Advantage">{{topScorerAnalysisPoints$.scoredH2Advantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredLastGameAdvantage">{{topScorerAnalysisPoints$.scoredLastGameAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topScorerAnalysisPoints$.scoredTeamLastGameAdvantage">{{topScorerAnalysisPoints$.scoredTeamLastGameAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span></span>
            </td>
        </tr>
    </tbody>
</table>