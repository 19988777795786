import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Competition } from 'src/app/core/interface/competition';
import { DateTime } from 'src/app/core/interface/date-time';
import { ApiService } from 'src/app/core/service/api.service';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';


@Component({
  selector: 'app-analysis-competition-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './analysis-competition-list.component.html',
  styleUrls: ['./analysis-competition-list.component.scss']
})
export class AnalysisCompetitionListComponent implements OnInit, OnDestroy {
  id: any;
  dateForm: FormGroup;
  /* dateToday: string; */
  subscription: Subscription = new Subscription();
  todayData: any;
  dates = [];
  datePicked: string;
  requestClick: boolean;
  date$: string;
  eventObject: any;
  isSearchMode: boolean = false;
  competitionList: any[];
  filteredCompetitionPointsList$: any[];
  competitionPointsList$: any[];
  competitionPointsObject$: any;
  items: Competition & { key: string; };

  constructor(
    private footballService: FootballService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('id1');
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.checkDataAvailableToday();
  }
  checkDataAvailableToday() {
    /* get date today */
    /* check if events today are more than 1 */
    /* date today add day six times while checking more than 1 event */
    /* display date boxes green/red */

    this.subscription.add(this.dateTimeService.getCurrentDate().pipe(first()).subscribe(
      (dateTime: DateTime) => {
        this.date$ = dateTime.date;
      }));
  }

  initializeForm() {
    this.dateForm = this.fb.group({
      date: [0],
      competitionSelect: [""],
    });

    this.dateForm.get("date").valueChanges.subscribe(x => {
      this.requestClick = false;

      let day = x.slice(8, 10);
      let month = x.slice(5, 7);
      let year = x.slice(0, 4);
      let date = `${year}-${month}-${day}`;
      this.datePicked = date;

      this.footballService.getAllSummaryCompetitionByDate(date)
        .pipe(first()).subscribe((competitionsList) => {
          //this.responses$ = response;
          let listA = [];
          competitionsList.forEach((item) => {
            if (Number(item.seasonId) > 0) {
              if (item.competitionName && item.countryName) { listA.push({ "id": item.seasonId, "name": item.countryName + " - " + item.competitionName, }); };
            }
          });
          this.competitionList = listA.sort((a, b) => a.name.localeCompare(b.name));
        });


    })

    this.dateForm.get("competitionSelect").valueChanges.subscribe(x => {
      //Get H2H Date List
      if (this.datePicked) {
        this.subscription.add(this.footballService.getSummaryCompetitionList(this.datePicked, x).pipe(first())
          .subscribe((competitionPointsList) => {
            //this.responses$ = response;
            this.filteredCompetitionPointsList$ = this.competitionPointsList$ = competitionPointsList;
          }))
        this.subscription.add(this.footballService.getSummaryCompetitionList_Object(this.datePicked, x).pipe(first())
          .subscribe((competitionPointsObject) => {
            //this.responses$ = response;
            this.competitionPointsObject$ = competitionPointsObject;
          }))
        this.subscription.add(this.footballService.getAllEventsByDate_Object(this.datePicked)
          .subscribe((eventObject) => {
            //this.responses$ = response;
            this.eventObject = eventObject;
          }));
      }
    });
  }

  edit(countryId: string) {

  }

  filter(query) {
    if (query === "" || !query) {
      this.isSearchMode = false;
    } else {
      this.isSearchMode = true;
    }

    this.filteredCompetitionPointsList$ = (query) ?
      this.competitionPointsList$.filter(c => (c.homeTeamNameLong + " " + c.awayTeamNameLong).toLowerCase().includes(query.toLowerCase())) :
      this.competitionPointsList$;
  }

  redirect(eDate, eventKey: number | string) {
    //Create interest data for date
    //Record Click ${date}/interest/${gameId}
    //*Note Time ZOne Matters!!!
    if (eDate && eventKey) {
      this.router.navigate([`/football/match/${eventKey}`], { queryParams: { "date": eDate } });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}




