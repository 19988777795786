import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AnalysisLastX } from 'src/app/core/interface/analysis-last-x';
import { DateTime } from 'src/app/core/interface/date-time';
import { Event } from 'src/app/core/interface/event';
import { EventLastXPoints } from 'src/app/core/interface/event-last-x-points';
import { JackpotBookmaker } from 'src/app/core/interface/jackpot-bookmaker';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';


@Component({
  selector: 'app-analysis-last-x',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './analysis-last-x.component.html',
  styleUrls: ['./analysis-last-x.component.scss']
})
export class AnalysisLastXComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  fixtureDate: string;
  id: number;
  todayDate: DateTime;
  weekNumberToday: string;
  matchEvent: Event;
  homeLastX: any;
  awayLastX: any;
  analysisH2H: any;
  lastXAnalysisAway: any;
  lastXAnalysisHome: any;
  lastXPoints: EventLastXPoints;
  lastXEventAnalysis: any;
  lastXAnalysisPoints$: any;

  constructor(
    private dateTimeService: DateTimeService,
    private footballService: FootballService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subscription.add(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams.pipe(first()).subscribe(params => {
          let date = params['date'];
          let date1 = new Date(date);
          let year = date1.getFullYear();
          let month = ((date1.getMonth() + 1).toString()).padStart(2, '0');
          let day = (date1.getDate().toString()).padStart(2, '0');

          this.fixtureDate = `${year}-${month}-${day}`;
        })

        this.id = Number(this.route.snapshot.paramMap.get('id1'));
        this.subscription.add(dateTimeService.getCurrentDate().subscribe(
          (dateTime) => {
            this.todayDate = dateTime;
            this.weekNumberToday = ((dateTime.yearWeek).toString()).padStart(2, '0');

            this.footballService.getMatchById(this.fixtureDate, this.id).subscribe((event) => {
              this.matchEvent = event;

              if (event) {
                /* get Home LastX */
                this.subscription.add(
                  this.footballService.getHLastX(this.fixtureDate, event.homeTeamId).subscribe((homeLastX) => {
                    this.homeLastX = homeLastX;
                  })
                );
                /* get Away LastX */
                this.subscription.add(
                  this.footballService.getALastX(this.fixtureDate, event.awayTeamId).subscribe((awayLastX) => {
                    this.awayLastX = awayLastX;
                  }));
                /* get Last X analysis Home*/
                this.subscription.add(
                  this.footballService.getLastXPoints(this.fixtureDate, event.homeTeamId).subscribe((lastXAnalysisHome) => {
                    this.lastXAnalysisHome = lastXAnalysisHome;
                  }));
                /* get Last X analysis Away*/
                this.subscription.add(
                  this.footballService.getLastXPoints(this.fixtureDate, event.awayTeamId).subscribe((lastXAnalysisAway) => {
                    this.lastXAnalysisAway = lastXAnalysisAway;
                  }));
                /* get Last X analysis Away*/
                this.subscription.add(
                  this.footballService.getLastXEventPoints(this.fixtureDate, event.eventId).subscribe((lastXPoints) => {
                    this.lastXPoints = lastXPoints;
                  }));
                //Get Last X Overall Analysis
                this.subscription.add(
                  this.footballService.getLastXEventAnalysis(this.fixtureDate, event.eventId).subscribe((lastXEventAnalysis) => {
                    this.lastXEventAnalysis = lastXEventAnalysis;
                  }));
                //
                this.subscription.add(this.footballService.getLastXAnalysisPointsByMatch(this.fixtureDate, event.eventId).pipe(first())
                  .subscribe((lastXAnalysisPoints) => {
                    //this.responses$ = response;
                    this.lastXAnalysisPoints$ = lastXAnalysisPoints;
                  }))
              }
            })

          }));
      }
    }))
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

}
