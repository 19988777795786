<!-- team intro -->
<div class="d-inline-flex my-md-3" *ngIf="matchEvent">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span
                    *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- matchH2H -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchH2H">
    <h4 class="display-6 fw-normal mb-0">Head 2 Head</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="matchH2H">
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let m of matchH2H.matches;let i = index;">
            <td scope="col">
                <span *ngIf="m.time"><span *ngIf="m.time.date">{{m.time.date}}</span></span>
            </td>
            <td scope="col">
                <span *ngIf="m.teams">
                    <span *ngIf="m.teams.home">
                        <span [class.fw-bold]="m.result.winner === 'home'"
                            *ngIf="m.teams.home.mediumname">{{m.teams.home.mediumname}}</span>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="m.result">
                    <span [class.fw-bold]="m.result.winner === 'home'" *ngIf="m.result.home">{{m.result.home}}</span>
                    <span *ngIf="!m.result.home">0</span>
                </span>
            </td>
            <td scope="col" [class.fw-bold]="!m.result.winner">:</td>
            <td scope="col">
                <span *ngIf="m.result">
                    <span [class.fw-bold]="m.result.winner === 'away'" *ngIf="m.result.away">{{m.result.away}}</span>
                    <span *ngIf="!m.result.away">0</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="m.teams">
                    <span *ngIf="m.teams.away">
                        <span [class.fw-bold]="m.result.winner === 'away'"
                            *ngIf="m.teams.away.mediumname">{{m.teams.away.mediumname}}</span>
                    </span>
                </span>
            </td>
        </tr>
    </tbody>
</table>
<!-- title -->
<div class="px-4 my-5 text-center" *ngIf="h2hData">
    <h1 class="display-5 fw-bold">H2H Analysis</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Data on teams head to head focus on<br> Last 3 years</p>
    </div>
</div>
<!-- home win/loss/draw -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>{{h2hData.homeTeam}}</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Win</th>
            <th class="teams text-start w-25">Draw</th>
            <th class="teams text-start w-25">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25"
                [class.fw-bold]="h2hData.homeTeamHomeWinPercentage > 79 || h2hData.homeTeamHomeWinPercentage < 11">
                <span>{{h2hData.homeTeamHomeWin}}</span><br>
                <span *ngIf="h2hData.homeTeamHomeWinPercentage">{{h2hData.homeTeamHomeWinPercentage}}%</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.homeTeamHomeDrawPercentage > 79 || h2hData.homeTeamHomeDrawPercentage < 11">
                <span>{{h2hData.homeTeamHomeDraw}}</span><br>
                <span *ngIf="h2hData.homeTeamHomeDrawPercentage">{{h2hData.homeTeamHomeDrawPercentage}}%</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.homeTeamHomeLossPercentage > 79 || h2hData.homeTeamHomeLossPercentage < 11">
                <span>{{h2hData.homeTeamHomeLoss}}</span><br>
                <span *ngIf="h2hData.homeTeamHomeLossPercentage">{{h2hData.homeTeamHomeLossPercentage}}%</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25"
                [class.fw-bold]="h2hData.homeTeamAwayWinPercentage > 79 || h2hData.homeTeamAwayWinPercentage < 11">
                <span *ngIf="h2hData.homeTeamAwayWin">{{h2hData.homeTeamAwayWin}}</span><br>
                <span *ngIf="h2hData.homeTeamAwayWinPercentage">{{h2hData.homeTeamAwayWinPercentage}}%</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.homeTeamAwayDrawPercentage > 79 || h2hData.homeTeamAwayDrawPercentage < 11">
                <span *ngIf="h2hData.homeTeamAwayDraw">{{h2hData.homeTeamAwayDraw}}</span><br>
                <span *ngIf="h2hData.homeTeamAwayDrawPercentage">{{h2hData.homeTeamAwayDrawPercentage}}%</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.homeTeamAwayLossPercentage > 79 || h2hData.homeTeamAwayLossPercentage < 11">
                <span *ngIf="h2hData.homeTeamAwayLoss">{{h2hData.homeTeamAwayLoss}}</span><br>
                <span *ngIf="h2hData.homeTeamAwayLossPercentage">{{h2hData.homeTeamAwayLossPercentage}}%</span>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>All</td>
            <td class="time text-start w-25" [class.fw-bold]="h2hData.homeTeamAwayWinPercentage > 79 || h2hData.homeTeamAwayWinPercentage < 11">
                <span>{{h2hData.homeTeamWin}}</span><br>
                {{h2hData.homeTeamWinPercentage}}%
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="h2hData.homeTeamDrawPercentage > 79 || h2hData.homeTeamDrawPercentage < 11">
                <span>{{h2hData.homeTeamDraw}}</span><br>
                {{h2hData.homeTeamDrawPercentage}}%
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="h2hData.homeTeamLossPercentage > 79 || h2hData.homeTeamLossPercentage < 11">
                <span>{{h2hData.homeTeamLoss}}</span><br>
                {{h2hData.homeTeamLossPercentage}}%
            </td>
        </tr>
    </tbody>
</table>
<!-- away win/loss/draw -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>{{h2hData.awayTeam}}</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Win</th>
            <th class="teams text-start w-25">Draw</th>
            <th class="teams text-start w-25">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25"
                [class.fw-bold]="h2hData.awayTeamHomeWinPercentage > 79 || h2hData.awayTeamHomeWinPercentage < 11">
                <span>{{h2hData.awayTeamHomeWin}}</span><br>
                {{h2hData.awayTeamHomeWinPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.awayTeamHomeDrawPercentage > 79 || h2hData.awayTeamHomeDrawPercentage < 11">
                <span>{{h2hData.awayTeamHomeDraw}}</span><br>
                {{h2hData.awayTeamHomeDrawPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.awayTeamHomeLossPercentage > 79 || h2hData.awayTeamHomeLossPercentage < 11">
                <span>{{h2hData.awayTeamHomeLoss}}</span><br>
                {{h2hData.awayTeamHomeLossPercentage}}%
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25"
                [class.fw-bold]="h2hData.awayTeamAwayWinPercentage > 79 || h2hData.awayTeamAwayWinPercentage < 11">
                <span>{{h2hData.awayTeamAwayWin}}</span><br>
                {{h2hData.awayTeamAwayWinPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.awayTeamAwayDrawPercentage > 79 || h2hData.awayTeamAwayDrawPercentage < 11">
                <span>{{h2hData.awayTeamAwayDraw}}</span><br>
                {{h2hData.awayTeamAwayDrawPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="h2hData.awayTeamAwayLossPercentage > 79 || h2hData.awayTeamAwayLossPercentage < 11">
                <span>{{h2hData.awayTeamAwayLoss}}</span><br>
                {{h2hData.awayTeamAwayLossPercentage}}%
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>All</td>
            <td class="time text-start w-25" [class.fw-bold]="h2hData.awayTeamWinPercentage > 79 || h2hData.awayTeamWinPercentage < 11">
                <span>{{h2hData.awayTeamWin}}</span><br>
                {{h2hData.awayTeamWinPercentage}}%
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="h2hData.awayTeamDrawPercentage > 79 || h2hData.awayTeamDrawPercentage < 11">
                <span>{{h2hData.awayTeamDraw}}</span><br>
                {{h2hData.awayTeamDrawPercentage}}%
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="h2hData.awayTeamLossPercentage > 79 || h2hData.awayTeamLossPercentage < 11">
                <span>{{h2hData.awayTeamLoss}}</span><br>
                {{h2hData.awayTeamLossPercentage}}%
            </td>
        </tr>
    </tbody>
</table>

<!-- home GG/GN/NN -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>{{h2hData.homeTeam}}</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">GG</th>
            <th class="teams text-start w-25">GN</th>
            <th class="teams text-start w-25">NN</th>
            <th class="teams text-start w-25">Adv</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamHomeGGPercentage > 79) || (h2hData.homeTeamHomeGGPercentage < 11)">
                <span>{{h2hData.homeTeamHomeGG}}</span><br>
                {{h2hData.homeTeamHomeGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamHomeGNPercentage > 79) || (h2hData.homeTeamHomeGNPercentage < 11)">
                <span>{{h2hData.homeTeamHomeGN}}</span><br>
                {{h2hData.homeTeamHomeGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamHomeNNPercentage > 79) || (h2hData.homeTeamHomeNNPercentage < 11)">
                <span>{{h2hData.homeTeamHomeNN}}</span><br>
                {{h2hData.homeTeamHomeNNPercentage}}%
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="true">
                <span>{{h2hData.homeTeamHomeGGGNNNAdvantage}}</span>
            </td>
            
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamAwayGGPercentage > 79) || (h2hData.homeTeamAwayGGPercentage < 11)">
                <span>{{h2hData.homeTeamAwayGG}}</span><br>
                {{h2hData.homeTeamAwayGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamAwayGNPercentage > 79) || (h2hData.homeTeamAwayGNPercentage < 11)">
                <span>{{h2hData.homeTeamAwayGN}}</span><br>
                {{h2hData.homeTeamAwayGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamAwayNNPercentage > 79) || (h2hData.homeTeamAwayNNPercentage < 11)">
                <span>{{h2hData.homeTeamAwayNN}}</span><br>
                {{h2hData.homeTeamAwayNNPercentage}}%
            </td>
            <td class="teams text-start w-25">
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>All</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamGGPercentage > 79) || (h2hData.homeTeamGGPercentage < 11)">
                <span>{{h2hData.homeTeamGG}}</span><br>
                {{h2hData.homeTeamGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamGNPercentage > 79) || (h2hData.homeTeamGNPercentage < 11)">
                <span>{{h2hData.homeTeamGN}}</span><br>
                {{h2hData.homeTeamGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamNNPercentage > 79) || (h2hData.homeTeamNNPercentage < 11)">
                <span>{{h2hData.homeTeamNN}}</span><br>
                {{h2hData.homeTeamNNPercentage}}%
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="true">
                <span>{{h2hData.homeTeamGGGNNNAdvantage}}</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- away GG/GN/NN -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>{{h2hData.awayTeam}}</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">GG</th>
            <th class="teams text-start w-25">GN</th>
            <th class="teams text-start w-25">NN</th>
            <th class="teams text-start w-25">Adv</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamHomeGGPercentage > 79) || (h2hData.awayTeamHomeGGPercentage < 11)">
                <span>{{h2hData.awayTeamHomeGG}}</span><br>
                {{h2hData.awayTeamHomeGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamHomeGNPercentage > 79) || (h2hData.awayTeamHomeGNPercentage < 11)">
                <span>{{h2hData.awayTeamHomeGN}}</span><br>
                {{h2hData.awayTeamHomeGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamHomeNNPercentage > 79) || (h2hData.awayTeamHomeNNPercentage < 11)">
                <span>{{h2hData.awayTeamHomeNN}}</span><br>
                {{h2hData.awayTeamHomeNNPercentage}}%
            </td>
            <td class="teams text-start w-25">
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamAwayGGPercentage > 79) || (h2hData.awayTeamAwayGGPercentage < 11)">
                <span>{{h2hData.awayTeamAwayGG}}</span><br>
                {{h2hData.awayTeamAwayGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamAwayGNPercentage > 79) || (h2hData.awayTeamAwayGNPercentage < 11)">
                <span>{{h2hData.awayTeamAwayGN}}</span><br>
                {{h2hData.awayTeamAwayGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamAwayNNPercentage > 79) || (h2hData.awayTeamAwayNNPercentage < 11)">
                <span>{{h2hData.awayTeamAwayNN}}</span><br>
                {{h2hData.awayTeamAwayNNPercentage}}%
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="true">
                <span>{{h2hData.awayTeamAwayGGGNNNAdvantage}}</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>All</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamGGPercentage > 79) || (h2hData.awayTeamGGPercentage < 11)">
                <span>{{h2hData.awayTeamGG}}</span><br>
                {{h2hData.awayTeamGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamGNPercentage > 79) || (h2hData.awayTeamGNPercentage < 11)">
                <span>{{h2hData.awayTeamGN}}</span><br>
                {{h2hData.awayTeamGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamNNPercentage > 79) || (h2hData.awayTeamNNPercentage < 11)">
                <span>{{h2hData.awayTeamNN}}</span><br>
                {{h2hData.awayTeamNNPercentage}}%
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="true">
                <span>{{h2hData.awayTeamGGGNNNAdvantage}}</span>
            </td>
        </tr>
    </tbody>
</table>


<!-- ov/un -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>Over/Under Goals</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Ov0.5</th>
            <th class="teams text-start w-25">Ov1.5</th>
            <th class="teams text-start w-25">Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Games</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.gamesOv05Percentage/100)*100 > 79 || (h2hData.gamesOv05Percentage/100)*100 < 11">
                <span *ngIf="h2hData.gamesOv05Percentage">{{h2hData.gamesOv05}}<br></span>
                <span *ngIf="h2hData.gamesOv05Percentage">{{(h2hData.gamesOv05Percentage/100)|percent}}</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.gamesOv15Percentage/100)*100 > 79 || (h2hData.gamesOv15Percentage/100)*100 < 11">
                <span *ngIf="h2hData.gamesOv15Percentage">{{h2hData.gamesOv15}}<br></span>
                <span *ngIf="h2hData.gamesOv15Percentage">{{(h2hData.gamesOv15Percentage/100)|percent}}</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.gamesOv25Percentage/100)*100 > 79 || (h2hData.gamesOv25Percentage/100)*100 < 11">
                <span *ngIf="h2hData.gamesOv25Percentage">{{h2hData.gamesOv25}}<br></span>
                <span *ngIf="h2hData.gamesOv25Percentage">{{(h2hData.gamesOv25Percentage/100)|percent}}<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- ov/un -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>Over/Under Goals</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Un0.5</th>
            <th class="teams text-start w-25">Un1.5</th>
            <th class="teams text-start w-25">Un2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Games</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.gamesUn05Percentage/100)*100 > 79 || (h2hData.gamesUn05Percentage/100)*100 < 11">
                <span *ngIf="h2hData.gamesUn05Percentage">{{h2hData.gamesUn05}}<br></span>
                <span *ngIf="h2hData.gamesUn05Percentage">{{(h2hData.gamesUn05Percentage/100)|percent}}</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.gamesUn15Percentage/100)*100 > 79 || (h2hData.gamesUn15Percentage/100)*100 < 11">
                <span *ngIf="h2hData.gamesUn15Percentage">{{h2hData.gamesUn15}}<br></span>
                <span *ngIf="h2hData.gamesUn15Percentage">{{(h2hData.gamesUn15Percentage/100)|percent}}</span>
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.gamesUn25Percentage/100)*100 > 79 || (h2hData.gamesUn25Percentage/100)*100 < 11">
                <span *ngIf="h2hData.gamesUn25Percentage">{{h2hData.gamesUn25}}<br></span>
                <span *ngIf="h2hData.gamesUn25Percentage">{{(h2hData.gamesUn25Percentage/100)|percent}}<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- scored -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>Scored</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>{{h2hData.homeTeam}}</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamGoalsAtHomePercentage/100)*100 > 79 || (h2hData.homeTeamGoalsAtHomePercentage/100)*100 < 11">
                {{h2hData.homeTeamGoalsAtHome}}<br>
                {{(h2hData.homeTeamGoalsAtHomePercentage/100)|percent}}
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamGoalsAtAwayPercentage/100)*100 > 79 || (h2hData.homeTeamGoalsAtAwayPercentage/100)*100 < 11">
                {{h2hData.homeTeamGoalsAtAway}}<br>
                {{(h2hData.homeTeamGoalsAtAwayPercentage/100)|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>{{h2hData.awayTeam}}</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamGoalsAtHomePercentage/100)*100 > 79 || (h2hData.awayTeamGoalsAtHomePercentage/100)*100 < 11">
                {{h2hData.awayTeamGoalsAtHome}}<br>
                {{(h2hData.awayTeamGoalsAtHomePercentage/100)|percent}}
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamGoalsAtAwayPercentage/100)*100 > 79 || (h2hData.awayTeamGoalsAtAwayPercentage/100)*100 < 11">
                {{h2hData.awayTeamGoalsAtAway}}<br>
                {{(h2hData.awayTeamGoalsAtAwayPercentage/100)|percent}}
            </td>
        </tr>
    </tbody>
</table>
<!-- conceded -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <caption>Conceded</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>{{h2hData.homeTeam}}</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamConcededAtHomePercentage/100)*100 > 79 || (h2hData.homeTeamConcededAtHomePercentage/100)*100 < 11">
                {{h2hData.homeTeamConcededAtHome}}<br>
                {{(h2hData.homeTeamConcededAtHomePercentage/100)|percent}}
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.homeTeamConcededAtAwayPercentage/100)*100 > 79 || (h2hData.homeTeamConcededAtAwayPercentage/100)*100 < 11">
                {{h2hData.homeTeamConcededAtAway}}<br>
                {{(h2hData.homeTeamConcededAtAwayPercentage/100)|percent}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>{{h2hData.awayTeam}}</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamConcededAtHomePercentage/100)*100 > 79 || (h2hData.awayTeamConcededAtHomePercentage/100)*100 < 11">
                {{h2hData.awayTeamConcededAtHome}}<br>
                {{(h2hData.awayTeamConcededAtHomePercentage/100)|percent}}
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(h2hData.awayTeamConcededAtAwayPercentage/100)*100 > 79 || (h2hData.awayTeamConcededAtAwayPercentage/100)*100 < 11">
                {{h2hData.awayTeamConcededAtAway}}<br>
                {{(h2hData.awayTeamConcededAtAwayPercentage/100)|percent}}
            </td>
        </tr>
    </tbody>
</table>
<!-- highlights -->
<table class="table table-sm flex-fill" *ngIf="h2hData">
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start w-25">Highlights</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="time text-start w-25">
                <span *ngIf="h2hData.homeTeamHomeWinPercentageSuggestion">{{h2hData.homeTeamHomeWinPercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.homeTeamHomeDrawPercentageSuggestion">{{h2hData.homeTeamHomeDrawPercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.homeTeamHomeLossPercentageSuggestion">{{h2hData.homeTeamHomeLossPercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.awayTeamAwayWinPercentageSuggestion">{{h2hData.awayTeamAwayWinPercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.awayTeamAwayDrawPercentageSuggestion">{{h2hData.awayTeamAwayDrawPercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.awayTeamAwayLossPercentageSuggestion">{{h2hData.awayTeamAwayLossPercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.ov05PercentageSuggestion">{{h2hData.ov05PercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.ov15PercentageSuggestion">{{h2hData.ov15PercentageSuggestion}}<br></span>
                <span *ngIf="h2hData.ov25PercentageSuggestion">{{h2hData.ov25PercentageSuggestion}}<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home win/loss/draw -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Win/Loss/Draw</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    h2hAnalysis.homeTeamHomeWin00 || 
    h2hAnalysis.homeTeamHomeWin10 ||
    h2hAnalysis.homeTeamHomeWin51 ||
    h2hAnalysis.homeTeamHomeWin60 ||
    h2hAnalysis.homeTeamHomeWin70 ||
    h2hAnalysis.homeTeamHomeWin80 ||
    h2hAnalysis.homeTeamHomeWin90 || 
    h2hAnalysis.homeTeamHomeWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeWin00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeWin100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamAwayWin00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    h2hAnalysis.homeTeamHomeDraw00 || 
    h2hAnalysis.homeTeamHomeDraw10 ||
    h2hAnalysis.homeTeamHomeDraw51 ||
    h2hAnalysis.homeTeamHomeDraw60 ||
    h2hAnalysis.homeTeamHomeDraw70 ||
    h2hAnalysis.homeTeamHomeDraw80 ||
    h2hAnalysis.homeTeamHomeDraw90 || 
    h2hAnalysis.homeTeamHomeDraw100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeDraw100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayDraw100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    h2hAnalysis.homeTeamHomeLoss00 || 
    h2hAnalysis.homeTeamHomeLoss10 ||
    h2hAnalysis.homeTeamHomeLoss51 ||
    h2hAnalysis.homeTeamHomeLoss60 ||
    h2hAnalysis.homeTeamHomeLoss70 ||
    h2hAnalysis.homeTeamHomeLoss80 ||
    h2hAnalysis.homeTeamHomeLoss90 || 
    h2hAnalysis.homeTeamHomeLoss100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamHomeLoss100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamAwayLoss100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- away win/loss/draw -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} - Win/Loss/Draw</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    h2hAnalysis.awayTeamHomeWin00 || 
    h2hAnalysis.awayTeamHomeWin10 ||
    h2hAnalysis.awayTeamHomeWin51 ||
    h2hAnalysis.awayTeamHomeWin60 ||
    h2hAnalysis.awayTeamHomeWin70 ||
    h2hAnalysis.awayTeamHomeWin80 ||
    h2hAnalysis.awayTeamHomeWin90 || 
    h2hAnalysis.awayTeamHomeWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.awayTeamHomeWin00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeWin100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.awayTeamAwayWin00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    h2hAnalysis.awayTeamHomeDraw00 || 
    h2hAnalysis.awayTeamHomeDraw10 ||
    h2hAnalysis.awayTeamHomeDraw51 ||
    h2hAnalysis.awayTeamHomeDraw60 ||
    h2hAnalysis.awayTeamHomeDraw70 ||
    h2hAnalysis.awayTeamHomeDraw80 ||
    h2hAnalysis.awayTeamHomeDraw90 || 
    h2hAnalysis.awayTeamHomeDraw100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeDraw100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayDraw100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    h2hAnalysis.awayTeamHomeLoss00 || 
    h2hAnalysis.awayTeamHomeLoss10 ||
    h2hAnalysis.awayTeamHomeLoss51 ||
    h2hAnalysis.awayTeamHomeLoss60 ||
    h2hAnalysis.awayTeamHomeLoss70 ||
    h2hAnalysis.awayTeamHomeLoss80 ||
    h2hAnalysis.awayTeamHomeLoss90 || 
    h2hAnalysis.awayTeamHomeLoss100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamHomeLoss100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamAwayLoss100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- ov/un -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>H2H Match Goals Over/Under</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
    h2hAnalysis.h2hGamesOv0500 || 
    h2hAnalysis.h2hGamesOv0510 ||
    h2hAnalysis.h2hGamesOv0551 ||
    h2hAnalysis.h2hGamesOv0560 ||
    h2hAnalysis.h2hGamesOv0570 ||
    h2hAnalysis.h2hGamesOv0580 ||
    h2hAnalysis.h2hGamesOv0590 || 
    h2hAnalysis.h2hGamesOv05100 ">
                Ov0.5</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesOv1500 || 
            h2hAnalysis.h2hGamesOv1510 ||
            h2hAnalysis.h2hGamesOv1551 ||
            h2hAnalysis.h2hGamesOv1560 ||
            h2hAnalysis.h2hGamesOv1570 ||
            h2hAnalysis.h2hGamesOv1580 ||
            h2hAnalysis.h2hGamesOv1590 || 
            h2hAnalysis.h2hGamesOv15100 ">
                Ov1.5</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesOv2500 || 
            h2hAnalysis.h2hGamesOv2510 ||
            h2hAnalysis.h2hGamesOv2551 ||
            h2hAnalysis.h2hGamesOv2560 ||
            h2hAnalysis.h2hGamesOv2570 ||
            h2hAnalysis.h2hGamesOv2580 ||
            h2hAnalysis.h2hGamesOv2590 || 
            h2hAnalysis.h2hGamesOv25100 ">
                Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesOv0500 || 
            h2hAnalysis.h2hGamesOv0510 ||
            h2hAnalysis.h2hGamesOv0551 ||
            h2hAnalysis.h2hGamesOv0560 ||
            h2hAnalysis.h2hGamesOv0570 ||
            h2hAnalysis.h2hGamesOv0580 ||
            h2hAnalysis.h2hGamesOv0590 || 
            h2hAnalysis.h2hGamesOv05100 ">
                <span *ngIf="h2hAnalysis.h2hGamesOv0500">Un0%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv0511">Un10%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv0551">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv0560">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv0570">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv0580">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv0590">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv05100">Ov100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesOv1500 || 
            h2hAnalysis.h2hGamesOv1510 ||
            h2hAnalysis.h2hGamesOv1551 ||
            h2hAnalysis.h2hGamesOv1560 ||
            h2hAnalysis.h2hGamesOv1570 ||
            h2hAnalysis.h2hGamesOv1580 ||
            h2hAnalysis.h2hGamesOv1590 || 
            h2hAnalysis.h2hGamesOv15100 ">
                <span *ngIf="h2hAnalysis.h2hGamesOv1500">Un0%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv1511">Un10%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv1551">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv1560">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv1570">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv1580">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv1590">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv15100">Ov100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesOv2500 || 
            h2hAnalysis.h2hGamesOv2510 ||
            h2hAnalysis.h2hGamesOv2551 ||
            h2hAnalysis.h2hGamesOv2560 ||
            h2hAnalysis.h2hGamesOv2570 ||
            h2hAnalysis.h2hGamesOv2580 ||
            h2hAnalysis.h2hGamesOv2590 || 
            h2hAnalysis.h2hGamesOv25100 ">
                <span *ngIf="h2hAnalysis.h2hGamesOv2500">Un0%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv2511">Un10%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv2551">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv2560">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv2570">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv2580">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv2590">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesOv25100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- ov/un -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>H2H Match Goals Over/Under</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
    h2hAnalysis.h2hGamesUn0500 || 
    h2hAnalysis.h2hGamesUn0510 ||
    h2hAnalysis.h2hGamesUn0551 ||
    h2hAnalysis.h2hGamesUn0560 ||
    h2hAnalysis.h2hGamesUn0570 ||
    h2hAnalysis.h2hGamesUn0580 ||
    h2hAnalysis.h2hGamesUn0590 || 
    h2hAnalysis.h2hGamesUn05100 ">
                Un0.5</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesUn1500 || 
            h2hAnalysis.h2hGamesUn1510 ||
            h2hAnalysis.h2hGamesUn1551 ||
            h2hAnalysis.h2hGamesUn1560 ||
            h2hAnalysis.h2hGamesUn1570 ||
            h2hAnalysis.h2hGamesUn1580 ||
            h2hAnalysis.h2hGamesUn1590 || 
            h2hAnalysis.h2hGamesUn15100 ">
                Un1.5</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesUn2500 || 
            h2hAnalysis.h2hGamesUn2510 ||
            h2hAnalysis.h2hGamesUn2551 ||
            h2hAnalysis.h2hGamesUn2560 ||
            h2hAnalysis.h2hGamesUn2570 ||
            h2hAnalysis.h2hGamesUn2580 ||
            h2hAnalysis.h2hGamesUn2590 || 
            h2hAnalysis.h2hGamesUn25100 ">
                Un2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Uner/Under</td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesUn0500 || 
            h2hAnalysis.h2hGamesUn0510 ||
            h2hAnalysis.h2hGamesUn0551 ||
            h2hAnalysis.h2hGamesUn0560 ||
            h2hAnalysis.h2hGamesUn0570 ||
            h2hAnalysis.h2hGamesUn0580 ||
            h2hAnalysis.h2hGamesUn0590 || 
            h2hAnalysis.h2hGamesUn05100 ">
                <span *ngIf="h2hAnalysis.h2hGamesUn0500">Un0%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn0511">Un10%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn0551">Un51%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn0560">Un60%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn0570">Un70%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn0580">Un80%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn0590">Un90%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn05100">Un100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesUn1500 || 
            h2hAnalysis.h2hGamesUn1510 ||
            h2hAnalysis.h2hGamesUn1551 ||
            h2hAnalysis.h2hGamesUn1560 ||
            h2hAnalysis.h2hGamesUn1570 ||
            h2hAnalysis.h2hGamesUn1580 ||
            h2hAnalysis.h2hGamesUn1590 || 
            h2hAnalysis.h2hGamesUn15100 ">
                <span *ngIf="h2hAnalysis.h2hGamesUn1500">Un0%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn1511">Un10%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn1551">Un51%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn1560">Un60%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn1570">Un70%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn1580">Un80%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn1590">Un90%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn15100">Un100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.h2hGamesUn2500 || 
            h2hAnalysis.h2hGamesUn2510 ||
            h2hAnalysis.h2hGamesUn2551 ||
            h2hAnalysis.h2hGamesUn2560 ||
            h2hAnalysis.h2hGamesUn2570 ||
            h2hAnalysis.h2hGamesUn2580 ||
            h2hAnalysis.h2hGamesUn2590 || 
            h2hAnalysis.h2hGamesUn25100 ">
                <span *ngIf="h2hAnalysis.h2hGamesUn2500">Un0%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn2511">Un10%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn2551">Un51%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn2560">Un60%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn2570">Un70%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn2580">Un80%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn2590">Un90%<br></span>
                <span *ngIf="h2hAnalysis.h2hGamesUn25100">Un100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home scored -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Goals Scored Home Vs Away</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.homeTeamGoalsAtHomePercentage00 || 
            h2hAnalysis.homeTeamGoalsAtHomePercentage10 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage51 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage60 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage70 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage80 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage90 || 
            h2hAnalysis.homeTeamGoalsAtHomePercentage100"
            >Home</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.homeTeamGoalsAtAwayPercentage00 || 
            h2hAnalysis.homeTeamGoalsAtAwayPercentage10 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage51 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage60 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage70 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage80 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage90 || 
            h2hAnalysis.homeTeamGoalsAtAwayPercentage100"
            >Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    h2hAnalysis.homeTeamGoalsAtHomePercentage00 || 
    h2hAnalysis.homeTeamGoalsAtHomePercentage10 ||
    h2hAnalysis.homeTeamGoalsAtHomePercentage51 ||
    h2hAnalysis.homeTeamGoalsAtHomePercentage60 ||
    h2hAnalysis.homeTeamGoalsAtHomePercentage70 ||
    h2hAnalysis.homeTeamGoalsAtHomePercentage80 ||
    h2hAnalysis.homeTeamGoalsAtHomePercentage90 || 
    h2hAnalysis.homeTeamGoalsAtHomePercentage100 ||
    h2hAnalysis.homeTeamGoalsAtAwayPercentage00 || 
    h2hAnalysis.homeTeamGoalsAtAwayPercentage10 ||
    h2hAnalysis.homeTeamGoalsAtAwayPercentage51 ||
    h2hAnalysis.homeTeamGoalsAtAwayPercentage60 ||
    h2hAnalysis.homeTeamGoalsAtAwayPercentage70 ||
    h2hAnalysis.homeTeamGoalsAtAwayPercentage80 ||
    h2hAnalysis.homeTeamGoalsAtAwayPercentage90 || 
    h2hAnalysis.homeTeamGoalsAtAwayPercentage100">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>
                Scored
            </td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.homeTeamGoalsAtHomePercentage00 || 
            h2hAnalysis.homeTeamGoalsAtHomePercentage10 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage51 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage60 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage70 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage80 ||
            h2hAnalysis.homeTeamGoalsAtHomePercentage90 || 
            h2hAnalysis.homeTeamGoalsAtHomePercentage100">
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtHomePercentage100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25" *ngIf="
            h2hAnalysis.homeTeamGoalsAtAwayPercentage00 || 
            h2hAnalysis.homeTeamGoalsAtAwayPercentage10 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage51 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage60 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage70 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage80 ||
            h2hAnalysis.homeTeamGoalsAtAwayPercentage90 || 
            h2hAnalysis.homeTeamGoalsAtAwayPercentage100">
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.homeTeamGoalsAtAwayPercentage100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- away scored -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} - Goals Scored Home Vs Away</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.awayTeamGoalsAtHomePercentage00 || 
            h2hAnalysis.awayTeamGoalsAtHomePercentage10 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage51 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage60 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage70 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage80 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage90 || 
            h2hAnalysis.awayTeamGoalsAtHomePercentage100"
            >Home</th>
            <th class="teams text-start w-25" *ngIf="
            h2hAnalysis.awayTeamGoalsAtAwayPercentage00 || 
            h2hAnalysis.awayTeamGoalsAtAwayPercentage10 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage51 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage60 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage70 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage80 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage90 || 
            h2hAnalysis.awayTeamGoalsAtAwayPercentage100"
            >Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    h2hAnalysis.awayTeamGoalsAtHomePercentage00 || 
    h2hAnalysis.awayTeamGoalsAtHomePercentage10 ||
    h2hAnalysis.awayTeamGoalsAtHomePercentage51 ||
    h2hAnalysis.awayTeamGoalsAtHomePercentage60 ||
    h2hAnalysis.awayTeamGoalsAtHomePercentage70 ||
    h2hAnalysis.awayTeamGoalsAtHomePercentage80 ||
    h2hAnalysis.awayTeamGoalsAtHomePercentage90 || 
    h2hAnalysis.awayTeamGoalsAtHomePercentage100 ||
    h2hAnalysis.awayTeamGoalsAtAwayPercentage00 || 
    h2hAnalysis.awayTeamGoalsAtAwayPercentage10 ||
    h2hAnalysis.awayTeamGoalsAtAwayPercentage51 ||
    h2hAnalysis.awayTeamGoalsAtAwayPercentage60 ||
    h2hAnalysis.awayTeamGoalsAtAwayPercentage70 ||
    h2hAnalysis.awayTeamGoalsAtAwayPercentage80 ||
    h2hAnalysis.awayTeamGoalsAtAwayPercentage90 || 
    h2hAnalysis.awayTeamGoalsAtAwayPercentage100">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>
                Scored
            </td>
            <td class="time text-start w-25" *ngIf="
            h2hAnalysis.awayTeamGoalsAtHomePercentage00 || 
            h2hAnalysis.awayTeamGoalsAtHomePercentage10 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage51 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage60 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage70 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage80 ||
            h2hAnalysis.awayTeamGoalsAtHomePercentage90 || 
            h2hAnalysis.awayTeamGoalsAtHomePercentage100">
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtHomePercentage100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25" *ngIf="
            h2hAnalysis.awayTeamGoalsAtAwayPercentage00 || 
            h2hAnalysis.awayTeamGoalsAtAwayPercentage10 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage51 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage60 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage70 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage80 ||
            h2hAnalysis.awayTeamGoalsAtAwayPercentage90 || 
            h2hAnalysis.awayTeamGoalsAtAwayPercentage100">
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage00">Un0%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage10">Un10%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage51">Ov51%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage60">Ov60%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage70">Ov70%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage80">Ov80%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage90">Ov90%<br></span>
                <span *ngIf="h2hAnalysis.awayTeamGoalsAtAwayPercentage100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home at home vs away at away advantage -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} at Home<br></span>
            <span>Vs<br></span>
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} at Away</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25"></th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Scored Advantage</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayGoalAdvantageH">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayGoalAdvantageA">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Scored Advantage Ov80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayGoalAdvantageHAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayGoalAdvantageAAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Scored Advantage Un80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayGoalAdvantageHBelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayGoalAdvantageABelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conceded Advantage</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayConcededAdvantageantageH">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayConcededAdvantageantageA">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Conceded Advantage Ov80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayConcededAdvantageantageHAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayConcededAdvantageantageAAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Conceded Advantage Un80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayConcededAdvantageantageHBelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamHomeVsATAwayConcededAdvantageantageABelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home vs away advantage -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}<br></span>
            <span>Vs<br></span>
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25"></th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Scored Advantage</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATGoalAdvantageH">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATGoalAdvantageA">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Scored Advantage Ov80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATGoalAdvantageHAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATGoalAdvantageAAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Scored Advantage Un80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATGoalAdvantageHBelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATGoalAdvantageABelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Conceded Advantage</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATConcededAdvantageantageH">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATConcededAdvantageantageA">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Conceded Advantage Ov80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATConcededAdvantageantageHAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATConcededAdvantageantageAAbove80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td class="text-muted">Conceded Advantage Un80%</td>
            <td class="time text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATConcededAdvantageantageHBelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                    </span>
                </span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="h2hAnalysis.homeTeamVsATConcededAdvantageantageABelow80">
                    <span *ngIf="matchEvent">
                        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                    </span>
                </span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points advantage -->
<table class="table table-sm flex-fill" *ngIf="h2hAnalysisPoints$">
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">3</th>
            <th class="teams text-start">4</th>
            <th class="teams text-start">5</th>
            <th class="teams text-start">6</th>
            <th class="teams text-start">0.5</th>
            <th class="teams text-start">1.5</th>
            <th class="teams text-start">2.5</th>
            <th class="teams text-start">GG/GN/NN</th>
            <th class="teams text-start"></th>
            <th class="teams text-start">Result 1X2</th>
            <th class="teams text-start">Result Ov/Un</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span [class]="h2hAnalysisPoints$.hAScoredAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="h2hAnalysisPoints$.hAScoredAdvantage">{{h2hAnalysisPoints$.hAScoredAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span [class]="h2hAnalysisPoints$.hAConcededAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="h2hAnalysisPoints$.hAConcededAdvantage">{{h2hAnalysisPoints$.hAConcededAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span [class]="h2hAnalysisPoints$.hAWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                        *ngIf="h2hAnalysisPoints$.hAWinDrawLossAdvantage">{{h2hAnalysisPoints$.hAWinDrawLossAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span
                    [class]="h2hAnalysisPoints$.homeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="h2hAnalysisPoints$.homeHomeAwayAwayScoredAdvantage">{{h2hAnalysisPoints$.homeHomeAwayAwayScoredAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span
                    [class]="h2hAnalysisPoints$.homeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="h2hAnalysisPoints$.homeHomeAwayAwayConcededAdvantage">{{h2hAnalysisPoints$.homeHomeAwayAwayConcededAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span
                    [class]="h2hAnalysisPoints$.homeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                        *ngIf="h2hAnalysisPoints$.homeHomeAwayAwayWinDrawLossAdvantage">{{h2hAnalysisPoints$.homeHomeAwayAwayWinDrawLossAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                    <span [class]="h2hAnalysisPoints$.Ov05Rslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.Ov05">Ov0.5<br></span></span>
                    <span [class]="h2hAnalysisPoints$.Un05Rslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.Un05">Un0.5</span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                    <span [class]="h2hAnalysisPoints$.Ov15Rslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.Ov15">Ov1.5<br></span></span>
                    <span [class]="h2hAnalysisPoints$.Un15Rslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.Un15">Un1.5</span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                    <span [class]="h2hAnalysisPoints$.Ov25Rslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.Ov25">Ov2.5<br></span></span>
                    <span [class]="h2hAnalysisPoints$.Un25Rslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.Un25">Un2.5</span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                    <span [class]="h2hAnalysisPoints$.homeHomeAwayAwayGGRslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.homeHomeAwayAwayGG">GG<br></span></span>
                    <span [class]="h2hAnalysisPoints$.homeHomeAwayAwayGNRslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.homeHomeAwayAwayGN">GN<br></span></span>
                    <span [class]="h2hAnalysisPoints$.homeHomeAwayAwayNNRslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.homeHomeAwayAwayNN">NN<br></span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                    <span [class]="h2hAnalysisPoints$.hAGGRslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.hAGG">GG<br></span></span>
                    <span [class]="h2hAnalysisPoints$.hAGNRslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.hAGN">GN<br></span></span>
                    <span [class]="h2hAnalysisPoints$.hANNRslt?'green-bd':'red-bg'"><span
                            *ngIf="h2hAnalysisPoints$.hANN">NN<br></span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span
                    *ngIf="h2hAnalysisPoints$.result1X2">{{h2hAnalysisPoints$.result1X2}}</span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="h2hAnalysisPoints$">
                <span *ngIf="h2hAnalysisPoints$.resultOv25">Ov2.5</span>
                <span *ngIf="!h2hAnalysisPoints$.resultOv25">
                    <span *ngIf="h2hAnalysisPoints$.resultOv15">Ov1.5</span>
                    <span *ngIf="!h2hAnalysisPoints$.resultOv15">
                        <span *ngIf="h2hAnalysisPoints$.resultOv05">Ov0.5</span>
                    </span>
                </span>
            </span>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>Home Vs Away (Cumulative)<br></Span>
    <Span>1 : Scored<br></Span>
    <Span>2 : Conceded<br></Span>
    <Span>3 : Win Loss Draw<br></Span>
    <Span>Home Team at Home Vs Away Team at Away<br></Span>
    <Span>4 : Scored<br></Span>
    <Span>5 : Conceded<br></Span>
    <Span>6 : Win Loss Draw<br></Span>
</p>

