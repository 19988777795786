<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Season Last X Analysis Points</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Season Last X analysis points against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>

<div *ngIf="!isSearchMode">
    <div *ngIf="seasonLastXPointsObject$">
    <table class="table table-sm flex-fill" *ngFor="let competition of competition$;let i = index">
        <thead>
            <tr class="small-tx">
                <h5>
                    <span class="fw-bold fst-italic"
                        *ngIf="competition.competitionName">{{competition.competitionName}}<br></span>
                    <span class="fst-italic" *ngIf="competition.countryName">{{competition.countryName}}</span>
                </h5>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Ov/Un</th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">GG/GN/NN</th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Result</th>
                <th class="teams text-start"></th>
            </tr>
        </thead>
        <thead class="table-light">
            <tr class="small-tx">
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">Adv</th>
                <th class="teams text-start">0.5</th>
                <th class="teams text-start">1.5</th>
                <th class="teams text-start">2.5</th>
                <th class="teams text-start"></th>
                <th class="teams text-start"></th>
                <th class="teams text-start">Result 1X2</th>
                <th class="teams text-start">Result Ov/Un</th>
            </tr>
        </thead>
        <tbody *ngIf="eventObject">
            <tr class="cursor-pointer status-live small-tx"
            *ngFor="let event of (competition.events | keyvalue);let i = index;">
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject[event.key]">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].winDrawAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="seasonLastXPointsObject$[event.key].winDrawAdvantage">{{seasonLastXPointsObject$[event.key].winDrawAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].conformWithOddsAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="seasonLastXPointsObject$[event.key].conformWithOddsAdvantage">{{seasonLastXPointsObject$[event.key].conformWithOddsAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].conformWithPositionAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="seasonLastXPointsObject$[event.key].conformWithPositionAdvantage">{{seasonLastXPointsObject$[event.key].conformWithPositionAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].conformWithPositionHomeAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="seasonLastXPointsObject$[event.key].conformWithPositionHomeAwayAdvantage">{{seasonLastXPointsObject$[event.key].conformWithPositionHomeAwayAdvantage}}</span></span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                        <span [class]="seasonLastXPointsObject$[event.key].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].adv1X2CountAdvantage">{{seasonLastXPointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                        <span *ngIf="seasonLastXPointsObject$[event.key].adv1Count">1 - {{seasonLastXPointsObject$[event.key].adv1Count}}
                            <span *ngIf="seasonLastXPointsObject$[event.key].adv1CountPercentage">({{seasonLastXPointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="seasonLastXPointsObject$[event.key].advXCount">X - {{seasonLastXPointsObject$[event.key].advXCount}}
                            <span *ngIf="seasonLastXPointsObject$[event.key].advXCountPercentage">({{seasonLastXPointsObject$[event.key].advXCountPercentage}}%)<br></span>
                        </span>
                        <span *ngIf="seasonLastXPointsObject$[event.key].adv2Count">2 - {{seasonLastXPointsObject$[event.key].adv2Count}}
                            <span *ngIf="seasonLastXPointsObject$[event.key].adv2CountPercentage">({{seasonLastXPointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                        </span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].Ov05Rslt?'green-bd':'red-bg'">
                        <span *ngIf="seasonLastXPointsObject$[event.key].Ov05">Ov0.5<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].Un05Rslt?'green-bd':'red-bg'">
                            <span *ngIf="seasonLastXPointsObject$[event.key].Un05">Un0.5</span></span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].Ov15Rslt?'green-bd':'red-bg'">
                        <span *ngIf="seasonLastXPointsObject$[event.key].Ov15">Ov1.5<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].Un15Rslt?'green-bd':'red-bg'">
                            <span *ngIf="seasonLastXPointsObject$[event.key].Un15">Un1.5</span></span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span [class]="seasonLastXPointsObject$[event.key].Ov25Rslt?'green-bd':'red-bg'">
                        <span *ngIf="seasonLastXPointsObject$[event.key].Ov25">Ov2.5<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].Un25Rslt?'green-bd':'red-bg'">
                            <span *ngIf="seasonLastXPointsObject$[event.key].Un25">Un2.5</span></span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                        <span [class]="seasonLastXPointsObject$[event.key].homeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].homeHomeAwayAwayGG">GG<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].homeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].homeHomeAwayAwayGN">GN<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].homeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].homeHomeAwayAwayNN">NN<br></span></span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                        <span [class]="seasonLastXPointsObject$[event.key].hAGGAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].hAGG">GG<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].hAGNAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].hAGN">GN<br></span></span>
                        <span [class]="seasonLastXPointsObject$[event.key].hANNAdvRslt?'green-bd':'red-bg'"><span
                                *ngIf="seasonLastXPointsObject$[event.key].hANN">NN<br></span></span>
                    </span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span *ngIf="seasonLastXPointsObject$[event.key].result1X2">{{seasonLastXPointsObject$[event.key].result1X2}}</span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="seasonLastXPointsObject$[event.key]">
                    <span *ngIf="seasonLastXPointsObject$[event.key].resultOv25">Ov2.5</span>
                    <span *ngIf="!seasonLastXPointsObject$[event.key].resultOv25">
                        <span *ngIf="seasonLastXPointsObject$[event.key].resultOv15">Ov1.5</span>
                        <span *ngIf="!seasonLastXPointsObject$[event.key].resultOv15">
                            <span *ngIf="seasonLastXPointsObject$[event.key].resultOv05">Ov0.5</span>
                        </span>
                    </span></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>

<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill">

        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">Ov 0.5</th>
                <th class="teams text-start">Ov 1.5</th>
                <th class="teams text-start">Ov 2.5</th>
                <th class="teams text-start">Result 1X2</th>
                <th class="teams text-start">Result Ov/Un</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx"
                *ngFor="let event of seasonLastXAnalysisPoints$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span [class]="event.winDrawAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.winDrawAdvantage">{{event.winDrawAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.conformWithOddsAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.conformWithOddsAdvantage">{{event.conformWithOddsAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.conformWithPositionAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.conformWithPositionAdvantage">{{event.conformWithPositionAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.conformWithPositionHomeAwayAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.conformWithPositionHomeAwayAdvantage">{{event.conformWithPositionHomeAwayAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.Ov05Rslt?'green-bd':'red-bg'"><span *ngIf="event.Ov05">Ov0.5</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.Ov15Rslt?'green-bd':'red-bg'"><span *ngIf="event.Ov15">Ov1.5</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.Ov25Rslt?'green-bd':'red-bg'"><span *ngIf="event.Ov25">Ov2.5</span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.resultOv25">Ov2.5</span>
                    <span *ngIf="!event.resultOv25">
                        <span *ngIf="event.resultOv15">Ov1.5</span>
                        <span *ngIf="!event.resultOv15">
                            <span *ngIf="event.resultOv05">Ov0.5</span>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>1 : Win/Loss/Draw<br></Span>
    <Span>2 : Conform with Odds<br></Span>
    <Span>3 : Conform with Position<br></Span>
    <Span>4 : Conform with Position Home/Away<br></Span>
</p>