<!-- team intro -->
<div class="d-inline-flex my-md-3" *ngIf="matchEvent">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span
                    *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- last X Games-->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="homeLastX || awayLastX">
    <h4 class="display-6 fw-normal mb-0">Last X</h4>
</div>
<div class="d-inline-flex flex-md-equal w-100 my-md-3 fw-light">
    <div class="col-6 bg-light text-center" *ngIf="homeLastX">
        <table class="table my-md-3 bg-light table-borderless table-sm text-center">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Home</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Away</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let m of homeLastX.matches;let i = index;">
                    <td scope="col">
                        <span *ngIf="m.time"><span *ngIf="m.time.date">{{m.time.date}}</span></span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams">
                            <span *ngIf="m.teams.home">
                                <span [class.fw-bold]="m.result.winner === 'home'"
                                    *ngIf="m.teams.home.mediumname">{{m.teams.home.mediumname}}</span>
                            </span>
                        </span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.result">
                            <span [class.fw-bold]="m.result.winner === 'home'"
                                *ngIf="m.result.home">{{m.result.home}}</span>
                            <span *ngIf="!m.result.home">0</span>
                        </span>
                    </td>
                    <td scope="col" [class.fw-bold]="!m.result.winner">:</td>
                    <td scope="col">
                        <span *ngIf="m.result">
                            <span [class.fw-bold]="m.result.winner === 'away'"
                                *ngIf="m.result.away">{{m.result.away}}</span>
                            <span *ngIf="!m.result.away">0</span>
                        </span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams">
                            <span *ngIf="m.teams.away">
                                <span [class.fw-bold]="m.result.winner === 'away'"
                                    *ngIf="m.teams.away.mediumname">{{m.teams.away.mediumname}}</span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-6 bg-light text-center" *ngIf="awayLastX">
        <table class="table my-md-3 bg-light table-borderless table-sm text-center">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Home</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Away</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let m of awayLastX.matches;let i = index;">
                    <td scope="col">
                        <span *ngIf="m.time">
                            <span *ngIf="m.time.date">{{m.time.date}}</span>
                        </span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams">
                            <span *ngIf="m.teams.home">
                                <span [class.fw-bold]="m.result.winner === 'home'"
                                    *ngIf="m.teams.home.mediumname">{{m.teams.home.mediumname}}</span>
                            </span>
                        </span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.result">
                            <span [class.fw-bold]="m.result.winner === 'home'"
                                *ngIf="m.result.home">{{m.result.home}}</span>
                            <span *ngIf="!m.result.home">0</span>
                        </span>
                    </td>
                    <td scope="col" [class.fw-bold]="!m.result.winner">:</td>
                    <td scope="col">
                        <span *ngIf="m.result">
                            <span [class.fw-bold]="m.result.winner === 'away'"
                                *ngIf="m.result.away">{{m.result.away}}</span>
                            <span *ngIf="!m.result.away">0</span>
                        </span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams">
                            <span *ngIf="m.teams.away">
                                <span [class.fw-bold]="m.result.winner === 'away'"
                                    *ngIf="m.teams.away.mediumname">{{m.teams.away.mediumname}}</span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- home last X Games -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchEvent">
    <h5 class="display-6 fw-normal mb-0">
        <span *ngIf="matchEvent.homeTeamNameLong">
            {{matchEvent.homeTeamNameLong}}</span>
    </h5>
</div>

<!-- home last X Form Visual  -->
<div class="card-group pb-3 fw-light" *ngIf="lastXAnalysisHome">
    <div class="card" *ngFor="let game of lastXAnalysisHome.overallFormArray;let i = index;">
        <div class="card-body">
            <h5 class="card-title" [ngClass]="{'green-bd': game === 'W', 'red-bg' : game === 'L' }">{{game}}</h5>
            <p class="card-text"></p>
        </div>
    </div>
</div>

<!-- home win loss draw -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisHome">
    <caption>Played {{lastXAnalysisHome.lastXGames}} games.</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th></th>
            <th class="teams text-start w-25">Win</th>
            <th class="teams text-start w-25">Draw</th>
            <th class="teams text-start w-25">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXWinH">-</span>
                <span *ngIf="lastXAnalysisHome.lastXWinH"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinHomePercentage > 89) || lastXAnalysisHome.lastXWinHomePercentage < 11">{{lastXAnalysisHome.lastXWinH}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXWinHomePercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinHomePercentage > 89) || lastXAnalysisHome.lastXWinHomePercentage < 11">{{lastXAnalysisHome.lastXWinHomePercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXWinHomePercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinHomePercentage > 89) || lastXAnalysisHome.lastXWinHomePercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXDrawH">-</span>
                <span *ngIf="lastXAnalysisHome.lastXDrawH"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawHomePercentage > 89) || lastXAnalysisHome.lastXDrawHomePercentage < 11">{{lastXAnalysisHome.lastXDrawH}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXDrawHomePercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawHomePercentage > 89) || lastXAnalysisHome.lastXDrawHomePercentage < 11">{{lastXAnalysisHome.lastXDrawHomePercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXDrawHomePercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawHomePercentage > 89) || lastXAnalysisHome.lastXDrawHomePercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXLossH">-</span>
                <span *ngIf="lastXAnalysisHome.lastXLossH"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossHomePercentage > 89) || lastXAnalysisHome.lastXLossHomePercentage < 11">{{lastXAnalysisHome.lastXLossH}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXLossHomePercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossHomePercentage > 89) || lastXAnalysisHome.lastXLossHomePercentage < 11">{{lastXAnalysisHome.lastXLossHomePercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXLossHomePercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossHomePercentage > 89) || lastXAnalysisHome.lastXLossHomePercentage < 11">0%</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXWinA">-</span>
                <span *ngIf="lastXAnalysisHome.lastXWinA"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinAwayPercentage > 89) || lastXAnalysisHome.lastXWinAwayPercentage < 11">{{lastXAnalysisHome.lastXWinA}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXWinAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinAwayPercentage > 89) || lastXAnalysisHome.lastXWinAwayPercentage < 11">{{lastXAnalysisHome.lastXWinAwayPercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXWinAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinAwayPercentage > 89) || lastXAnalysisHome.lastXWinAwayPercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXDrawA">-</span>
                <span *ngIf="lastXAnalysisHome.lastXDrawA"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawAwayPercentage > 89) || lastXAnalysisHome.lastXDrawAwayPercentage < 11">{{lastXAnalysisHome.lastXDrawA}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXDrawAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawAwayPercentage > 89) || lastXAnalysisHome.lastXDrawAwayPercentage < 11">{{lastXAnalysisHome.lastXDrawAwayPercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXDrawAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawAwayPercentage > 89) || lastXAnalysisHome.lastXDrawAwayPercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXLossA">-</span>
                <span *ngIf="lastXAnalysisHome.lastXLossA"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossAwayPercentage > 89) || lastXAnalysisHome.lastXLossAwayPercentage < 11">{{lastXAnalysisHome.lastXLossA}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXLossAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossAwayPercentage > 89) || lastXAnalysisHome.lastXLossAwayPercentage < 11">{{lastXAnalysisHome.lastXLossAwayPercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXLossAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossAwayPercentage > 89) || lastXAnalysisHome.lastXLossAwayPercentage < 11">0%</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXWin">-</span>
                <span *ngIf="lastXAnalysisHome.lastXWin"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinPercentage > 89) || lastXAnalysisHome.lastXWinPercentage < 11">{{lastXAnalysisHome.lastXWin}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXWinPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinPercentage > 89) || lastXAnalysisHome.lastXWinPercentage < 11">{{lastXAnalysisHome.lastXWinPercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXWinPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXWinPercentage > 89) || lastXAnalysisHome.lastXWinPercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXDraw">-</span>
                <span *ngIf="lastXAnalysisHome.lastXDraw"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawPercentage > 89) || lastXAnalysisHome.lastXDrawPercentage < 11">{{lastXAnalysisHome.lastXDraw}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXDrawPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawPercentage > 89) || lastXAnalysisHome.lastXDrawPercentage < 11">{{lastXAnalysisHome.lastXDrawPercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXDrawPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXDrawPercentage > 89) || lastXAnalysisHome.lastXDrawPercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisHome.lastXLoss">-</span>
                <span *ngIf="lastXAnalysisHome.lastXLoss"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossPercentage > 89) || lastXAnalysisHome.lastXLossPercentage < 11">{{lastXAnalysisHome.lastXLoss}}</span><br>
                <span *ngIf="lastXAnalysisHome.lastXLossPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossPercentage > 89) || lastXAnalysisHome.lastXLossPercentage < 11">{{lastXAnalysisHome.lastXLossPercentage}}%</span>
                <span *ngIf="!lastXAnalysisHome.lastXLossPercentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXLossPercentage > 89) || lastXAnalysisHome.lastXLossPercentage < 11">0%</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- home ov/un -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisHome">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Ov/Un</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Ov0.5</th>
            <th class="teams text-start w-25">Ov1.5</th>
            <th class="teams text-start w-25">Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisHome.lastXOv05"
                    [class.fw-bold]="(lastXAnalysisHome.lastXOv05Percentage > 89) || lastXAnalysisHome.lastXOv05Percentage < 11">
                    {{lastXAnalysisHome.lastXOv05}}<br></span>
                <span *ngIf="lastXAnalysisHome.lastXOv05Percentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXOv05Percentage > 89) || lastXAnalysisHome.lastXOv05Percentage < 11">
                    {{lastXAnalysisHome.lastXOv05Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisHome.lastXOv15"
                    [class.fw-bold]="(lastXAnalysisHome.lastXOv15Percentage > 89) || lastXAnalysisHome.lastXOv15Percentage < 11">
                    {{lastXAnalysisHome.lastXOv15}}<br></span>
                <span *ngIf="lastXAnalysisHome.lastXOv15Percentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXOv15Percentage > 89) || lastXAnalysisHome.lastXOv15Percentage < 11">
                    {{lastXAnalysisHome.lastXOv15Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisHome.lastXOv25"
                    [class.fw-bold]="(lastXAnalysisHome.lastXOv25Percentage > 89) || lastXAnalysisHome.lastXOv25Percentage < 11">
                    {{lastXAnalysisHome.lastXOv25}}<br></span>
                <span *ngIf="lastXAnalysisHome.lastXOv25Percentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXOv25Percentage > 89) || lastXAnalysisHome.lastXOv25Percentage < 11">
                    {{lastXAnalysisHome.lastXOv25Percentage}}%</span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisHome">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Ov/Un</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Un0.5</th>
            <th class="teams text-start w-25">Un1.5</th>
            <th class="teams text-start w-25">Un2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisHome.lastXUn05"
                    [class.fw-bold]="(lastXAnalysisHome.lastXUn05Percentage > 89) || lastXAnalysisHome.lastXUn05Percentage < 11">
                    {{lastXAnalysisHome.lastXUn05}}<br></span>
                <span *ngIf="lastXAnalysisHome.lastXUn05Percentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXUn05Percentage > 89) || lastXAnalysisHome.lastXUn05Percentage < 11">
                    {{lastXAnalysisHome.lastXUn05Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisHome.lastXUn15"
                    [class.fw-bold]="(lastXAnalysisHome.lastXUn15Percentage > 89) || lastXAnalysisHome.lastXUn15Percentage < 11">
                    {{lastXAnalysisHome.lastXUn15}}<br></span>
                <span *ngIf="lastXAnalysisHome.lastXUn15Percentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXUn15Percentage > 89) || lastXAnalysisHome.lastXUn15Percentage < 11">
                    {{lastXAnalysisHome.lastXUn15Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisHome.lastXUn25"
                    [class.fw-bold]="(lastXAnalysisHome.lastXUn25Percentage > 89) || lastXAnalysisHome.lastXUn25Percentage < 11">
                    {{lastXAnalysisHome.lastXUn25}}<br></span>
                <span *ngIf="lastXAnalysisHome.lastXUn25Percentage"
                    [class.fw-bold]="(lastXAnalysisHome.lastXUn25Percentage > 89) || lastXAnalysisHome.lastXUn25Percentage < 11">
                    {{lastXAnalysisHome.lastXUn25Percentage}}%</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- home GG/GN/NN -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisHome">
    <span *ngIf="matchEvent">
        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Ov/Un</span>
    </span>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">GG</th>
            <th class="teams text-start w-25">GN</th>
            <th class="teams text-start w-25">NN</th>
            <th class="teams text-start w-25">Adv</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXGGHomePercentage > 79) || (lastXAnalysisHome.lastXGGHomePercentage < 11)">
                <span>{{lastXAnalysisHome.lastXGGH}}</span><br>
                {{lastXAnalysisHome.lastXGGHomePercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXGNHomePercentage > 79) || (lastXAnalysisHome.lastXGNHomePercentage < 11)">
                <span>{{lastXAnalysisHome.lastXGNH}}</span><br>
                {{lastXAnalysisHome.lastXGNHomePercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXNNHomePercentage > 79) || (lastXAnalysisHome.lastXNNHomePercentage < 11)">
                <span>{{lastXAnalysisHome.lastXNNH}}</span><br>
                {{lastXAnalysisHome.lastXNNHomePercentage}}%
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="true">
                <span></span>
            </td>
            
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXGGAwayPercentage > 79) || (lastXAnalysisHome.lastXGGAwayPercentage < 11)">
                <span>{{lastXAnalysisHome.lastXGGA}}</span><br>
                {{lastXAnalysisHome.lastXGGAwayPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXGNAwayPercentage > 79) || (lastXAnalysisHome.lastXGNAwayPercentage < 11)">
                <span>{{lastXAnalysisHome.lastXGNA}}</span><br>
                {{lastXAnalysisHome.lastXGNAwayPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXNNAwayPercentage > 79) || (lastXAnalysisHome.lastXNNAwayPercentage < 11)">
                <span>{{lastXAnalysisHome.lastXNNA}}</span><br>
                {{lastXAnalysisHome.lastXNNAwayPercentage}}%
            </td>
            <td class="teams text-start w-25">
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>All</td>
            <td class="time text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXGGPercentage > 79) || (lastXAnalysisHome.lastXGGPercentage < 11)">
                <span>{{lastXAnalysisHome.lastXGG}}</span><br>
                {{lastXAnalysisHome.lastXGGPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXGNPercentage > 79) || (lastXAnalysisHome.lastXGNPercentage < 11)">
                <span>{{lastXAnalysisHome.lastXGN}}</span><br>
                {{lastXAnalysisHome.lastXGNPercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="(lastXAnalysisHome.lastXNNPercentage > 79) || (lastXAnalysisHome.lastXNNPercentage < 11)">
                <span>{{lastXAnalysisHome.lastXNN}}</span><br>
                {{lastXAnalysisHome.lastXNNPercentage}}%
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="true">
                <span></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- home scored & conceded -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisHome">
    <caption>Goals in {{lastXAnalysisHome.lastXGames}} games.<br>{{lastXAnalysisHome.lastXGamesHome}} Home games.<br>
        {{lastXAnalysisHome.lastXGamesAway}} Away games.</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th></th>
            <th class="teams text-start w-25">Scored</th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25">Conceded</th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25"></th>
        </tr>
    </thead>
    <thead class="table-light">
        <tr class="small-tx">
            <th></th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
            <th class="teams text-start w-25">Total</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
            <th class="teams text-start w-25">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Numbers</td>
            <td class="time text-start w-25"
                [class.fw-bold]="lastXAnalysisHome.goalsScoredHomePercentage > 79 || lastXAnalysisHome.goalsScoredHomePercentage < 11">
                {{lastXAnalysisHome.goalsScoredH}}<br>
                {{lastXAnalysisHome.goalsScoredHomePercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="lastXAnalysisHome.goalsScoredAwayPercentage > 79 || lastXAnalysisHome.goalsScoredAwayPercentage < 11">
                {{lastXAnalysisHome.goalsScoredA}}<br>
                {{lastXAnalysisHome.goalsScoredAwayPercentage}}%
            </td>
            <td class="teams text-start w-25">
                {{lastXAnalysisHome.goalsScored}}
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="lastXAnalysisHome.goalsConcededHomePercentage > 79 || lastXAnalysisHome.goalsConcededHomePercentage < 11">
                {{lastXAnalysisHome.goalsConcededH}}<br>
                {{lastXAnalysisHome.goalsConcededHomePercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="lastXAnalysisHome.goalsConcededAwayPercentage > 79 || lastXAnalysisHome.goalsConcededAwayPercentage < 11">
                {{lastXAnalysisHome.goalsConcededA}}<br>
                {{lastXAnalysisHome.goalsConcededAwayPercentage}}%
            </td>
            <td class="teams text-start w-25">
                {{lastXAnalysisHome.goalsConceded}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Average</td>
            <td class="time text-start w-25" [class.fw-bold]="lastXAnalysisHome.goalsScoredPerHGameAvg > 1.9">
                {{lastXAnalysisHome.goalsScoredPerHGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisHome.goalsScoredPerAGameAvg > 1.9">
                {{lastXAnalysisHome.goalsScoredPerAGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisHome.goalsScoredPerGameAvg > 1.9">
                {{lastXAnalysisHome.goalsScoredPerGameAvg}}
            </td>
            <td class="time text-start w-25" [class.fw-bold]="lastXAnalysisHome.goalsConcededPerHGameAvg > 1.9">
                {{lastXAnalysisHome.goalsConcededPerHGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisHome.goalsConcededPerAGameAvg > 1.9">
                {{lastXAnalysisHome.goalsConcededPerAGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisHome.goalsConcededPerGameAvg > 1.9">
                {{lastXAnalysisHome.goalsConcededPerGameAvg}}
            </td>
        </tr>
    </tbody>
</table>

<!-- away last X Games -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchEvent">
    <h5 class="display-6 fw-normal mb-0"><span *ngIf="matchEvent.awayTeamNameLong">
            {{matchEvent.awayTeamNameLong}}</span></h5>
</div>

<!-- away last X Form Visual  -->
<div class="card-group pb-3 fw-light" *ngIf="lastXAnalysisAway">
    <div class="card" *ngFor="let game of lastXAnalysisAway.overallFormArray;let i = index;">
        <div class="card-body">
            <h5 class="card-title" [ngClass]="{'green-bd': game === 'W', 'red-bg' : game === 'L' }">{{game}}</h5>
            <p class="card-text"></p>
        </div>
    </div>
</div>

<!-- away win loss draw -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisAway">
    <caption>Played {{lastXAnalysisAway.lastXGames}} games</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th></th>
            <th class="teams text-start w-25">Win</th>
            <th class="teams text-start w-25">Draw</th>
            <th class="teams text-start w-25">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Home</td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXWinH">-</span>
                <span *ngIf="lastXAnalysisAway.lastXWinH"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinHomePercentage > 89) || lastXAnalysisAway.lastXWinHomePercentage < 11">{{lastXAnalysisAway.lastXWinH}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXWinHomePercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinHomePercentage > 89) || lastXAnalysisAway.lastXWinHomePercentage < 11">{{lastXAnalysisAway.lastXWinHomePercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXWinHomePercentage">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXDrawH">-</span>
                <span *ngIf="lastXAnalysisAway.lastXDrawH"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawHomePercentage > 89) || lastXAnalysisAway.lastXDrawHomePercentage < 11">{{lastXAnalysisAway.lastXDrawH}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXDrawHomePercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawHomePercentage > 89) || lastXAnalysisAway.lastXDrawHomePercentage < 11">{{lastXAnalysisAway.lastXDrawHomePercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXDrawHomePercentage">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXLossH">-</span>
                <span *ngIf="lastXAnalysisAway.lastXLossH"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossHomePercentage > 89) || lastXAnalysisAway.lastXLossHomePercentage < 11">{{lastXAnalysisAway.lastXLossH}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXLossHomePercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossHomePercentage > 89) || lastXAnalysisAway.lastXLossHomePercentage < 11">{{lastXAnalysisAway.lastXLossHomePercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXLossHomePercentage">0%</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Away</td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXWinA">-</span>
                <span *ngIf="lastXAnalysisAway.lastXWinA"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinAwayPercentage > 89) || lastXAnalysisAway.lastXWinAwayPercentage < 11">{{lastXAnalysisAway.lastXWinA}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXWinAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinAwayPercentage > 89) || lastXAnalysisAway.lastXWinAwayPercentage < 11">{{lastXAnalysisAway.lastXWinAwayPercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXWinAwayPercentage">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXDrawA">-</span>
                <span *ngIf="lastXAnalysisAway.lastXDrawA"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawAwayPercentage > 89) || lastXAnalysisAway.lastXDrawAwayPercentage < 11">{{lastXAnalysisAway.lastXDrawA}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXDrawAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawAwayPercentage > 89) || lastXAnalysisAway.lastXDrawAwayPercentage < 11">{{lastXAnalysisAway.lastXDrawAwayPercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXDrawAwayPercentage">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXLossA">-</span>
                <span *ngIf="lastXAnalysisAway.lastXLossA"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossAwayPercentage > 89) || lastXAnalysisAway.lastXLossAwayPercentage < 11">{{lastXAnalysisAway.lastXLossA}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXLossAwayPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossAwayPercentage > 89) || lastXAnalysisAway.lastXLossAwayPercentage < 11">{{lastXAnalysisAway.lastXLossAwayPercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXLossAwayPercentage">0%</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXWin">-</span>
                <span *ngIf="lastXAnalysisAway.lastXWin"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinPercentage > 89) || lastXAnalysisAway.lastXWinPercentage < 11">{{lastXAnalysisAway.lastXWin}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXWinPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinPercentage > 89) || lastXAnalysisAway.lastXWinPercentage < 11">{{lastXAnalysisAway.lastXWinPercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXWinPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXWinPercentage > 89) || lastXAnalysisAway.lastXWinPercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXDraw">-</span>
                <span *ngIf="lastXAnalysisAway.lastXDraw"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawPercentage > 89) || lastXAnalysisAway.lastXDrawPercentage < 11">{{lastXAnalysisAway.lastXDraw}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXDrawPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawPercentage > 89) || lastXAnalysisAway.lastXDrawPercentage < 11">{{lastXAnalysisAway.lastXDrawPercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXDrawPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXDrawPercentage > 89) || lastXAnalysisAway.lastXDrawPercentage < 11">0%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="!lastXAnalysisAway.lastXLoss">-</span>
                <span *ngIf="lastXAnalysisAway.lastXLoss"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossPercentage > 89) || lastXAnalysisAway.lastXLossPercentage < 11">{{lastXAnalysisAway.lastXLoss}}</span><br>
                <span *ngIf="lastXAnalysisAway.lastXLossPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossPercentage > 89) || lastXAnalysisAway.lastXLossPercentage < 11">{{lastXAnalysisAway.lastXLossPercentage}}%</span>
                <span *ngIf="!lastXAnalysisAway.lastXLossPercentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXLossPercentage > 89) || lastXAnalysisAway.lastXLossPercentage < 11">0%</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- away ov/un -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisAway">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Ov/Un</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Ov0.5</th>
            <th class="teams text-start w-25">Ov1.5</th>
            <th class="teams text-start w-25">Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisAway.lastXOv05"
                    [class.fw-bold]="(lastXAnalysisAway.lastXOv05Percentage > 89) || lastXAnalysisAway.lastXOv05Percentage < 11">
                    {{lastXAnalysisAway.lastXOv05}}<br></span>
                <span *ngIf="lastXAnalysisAway.lastXOv05Percentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXOv05Percentage > 89) || lastXAnalysisAway.lastXOv05Percentage < 11">
                    {{lastXAnalysisAway.lastXOv05Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisAway.lastXOv15"
                    [class.fw-bold]="(lastXAnalysisAway.lastXOv15Percentage > 89) || lastXAnalysisAway.lastXOv15Percentage < 11">
                    {{lastXAnalysisAway.lastXOv15}}<br></span>
                <span *ngIf="lastXAnalysisAway.lastXOv15Percentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXOv15Percentage > 89) || lastXAnalysisAway.lastXOv15Percentage < 11">
                    {{lastXAnalysisAway.lastXOv15Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisAway.lastXOv25"
                    [class.fw-bold]="(lastXAnalysisAway.lastXOv25Percentage > 89) || lastXAnalysisAway.lastXOv25Percentage < 11">
                    {{lastXAnalysisAway.lastXOv25}}<br></span>
                <span *ngIf="lastXAnalysisAway.lastXOv25Percentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXOv25Percentage > 89) || lastXAnalysisAway.lastXOv25Percentage < 11">
                    {{lastXAnalysisAway.lastXOv25Percentage}}%</span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisAway">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Ov/Un</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Un0.5</th>
            <th class="teams text-start w-25">Un1.5</th>
            <th class="teams text-start w-25">Un2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisAway.lastXUn05"
                    [class.fw-bold]="(lastXAnalysisAway.lastXUn05Percentage > 89) || lastXAnalysisAway.lastXUn05Percentage < 11">
                    {{lastXAnalysisAway.lastXUn05}}<br></span>
                <span *ngIf="lastXAnalysisAway.lastXUn05Percentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXUn05Percentage > 89) || lastXAnalysisAway.lastXUn05Percentage < 11">
                    {{lastXAnalysisAway.lastXUn05Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisAway.lastXUn15"
                    [class.fw-bold]="(lastXAnalysisAway.lastXUn15Percentage > 89) || lastXAnalysisAway.lastXUn15Percentage < 11">
                    {{lastXAnalysisAway.lastXUn15}}<br></span>
                <span *ngIf="lastXAnalysisAway.lastXUn15Percentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXUn15Percentage > 89) || lastXAnalysisAway.lastXUn15Percentage < 11">
                    {{lastXAnalysisAway.lastXUn15Percentage}}%</span>
            </td>
            <td class="time text-start w-25">
                <span *ngIf="lastXAnalysisAway.lastXUn25"
                    [class.fw-bold]="(lastXAnalysisAway.lastXUn25Percentage > 89) || lastXAnalysisAway.lastXUn25Percentage < 11">
                    {{lastXAnalysisAway.lastXUn25}}<br></span>
                <span *ngIf="lastXAnalysisAway.lastXUn25Percentage"
                    [class.fw-bold]="(lastXAnalysisAway.lastXUn25Percentage > 89) || lastXAnalysisAway.lastXUn25Percentage < 11">
                    {{lastXAnalysisAway.lastXUn25Percentage}}%</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- away scored & conceded -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisAway">
    <caption>Goals in {{lastXAnalysisAway.lastXGames}} games.<br>{{lastXAnalysisAway.lastXGamesHome}} Home games.<br>
        {{lastXAnalysisAway.lastXGamesAway}} Away games.</caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th></th>
            <th class="teams text-start w-25">Scored</th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25">Conceded</th>
            <th class="teams text-start w-25"></th>
            <th class="teams text-start w-25"></th>
        </tr>
    </thead>
    <thead class="table-light">
        <tr class="small-tx">
            <th></th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
            <th class="teams text-start w-25">Total</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
            <th class="teams text-start w-25">Total</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Numbers</td>
            <td class="time text-start w-25"
                [class.fw-bold]="lastXAnalysisAway.goalsScoredHomePercentage > 79 || lastXAnalysisAway.goalsScoredHomePercentage < 11">
                {{lastXAnalysisAway.goalsScoredH}}<br>
                {{lastXAnalysisAway.goalsScoredHomePercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="lastXAnalysisAway.goalsScoredAwayPercentage > 79 || lastXAnalysisAway.goalsScoredAwayPercentage < 11">
                {{lastXAnalysisAway.goalsScoredA}}<br>
                {{lastXAnalysisAway.goalsScoredAwayPercentage}}%
            </td>
            <td class="teams text-start w-25">
                {{lastXAnalysisAway.goalsScored}}
            </td>
            <td class="time text-start w-25"
                [class.fw-bold]="lastXAnalysisAway.goalsConcededHomePercentage > 79 || lastXAnalysisAway.goalsConcededHomePercentage < 11">
                {{lastXAnalysisAway.goalsConcededH}}<br>
                {{lastXAnalysisAway.goalsConcededHomePercentage}}%
            </td>
            <td class="teams text-start w-25"
                [class.fw-bold]="lastXAnalysisAway.goalsConcededAwayPercentage > 79 || lastXAnalysisAway.goalsConcededAwayPercentage < 11">
                {{lastXAnalysisAway.goalsConcededA}}<br>
                {{lastXAnalysisAway.goalsConcededAwayPercentage}}%
            </td>
            <td class="teams text-start w-25">
                {{lastXAnalysisAway.goalsConceded}}
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Average</td>
            <td class="time text-start w-25" [class.fw-bold]="lastXAnalysisAway.goalsScoredPerHGameAvg > 1.9">
                {{lastXAnalysisAway.goalsScoredPerHGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisAway.goalsScoredPerAGameAvg > 1.9">
                {{lastXAnalysisAway.goalsScoredPerAGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisAway.goalsScoredPerGameAvg > 1.9">
                {{lastXAnalysisAway.goalsScoredPerGameAvg}}
            </td>
            <td class="time text-start w-25" [class.fw-bold]="lastXAnalysisAway.goalsConcededPerHGameAvg > 1.9">
                {{lastXAnalysisAway.goalsConcededPerHGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisAway.goalsConcededPerAGameAvg > 1.9">
                {{lastXAnalysisAway.goalsConcededPerAGameAvg}}
            </td>
            <td class="teams text-start w-25" [class.fw-bold]="lastXAnalysisAway.goalsConcededPerGameAvg > 1.9">
                {{lastXAnalysisAway.goalsConcededPerGameAvg}}
            </td>
        </tr>
    </tbody>
</table>

<!-- points -->
<!-- Top Scorer Points -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="lastXPoints">
    <h4 class="display-6 fw-normal mb-0">Points</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="lastXPoints">
    <caption>Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Scored</th>
            <th scope="col">Concede</th>
            <th scope="col">Win</th>
            <th scope="col">Loss</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Home vs Away Avg.</span>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsScoredPerHAGameAvgAdvantage">
                    {{lastXPoints.goalsScoredPerHAGameAvgAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsConcededPerHAGameAvgAdvantage">
                    {{lastXPoints.goalsConcededPerHAGameAvgAdvantage}}
                </div>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">
                <span>Home% vs Away%</span>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsScoredHAwayPercentageAdvantage">
                    {{lastXPoints.goalsScoredHAwayPercentageAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsConcededHAwayPercentageAdvantage">
                    {{lastXPoints.goalsConcededHAwayPercentageAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.winHAwayPercentageAdvantage">
                    {{lastXPoints.winHAwayPercentageAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.lossHAwayPercentageAdvantage">
                    {{lastXPoints.lossHAwayPercentageAdvantage}}
                </div>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">
                <span>Home vs Away</span>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsScoredHomeAwayAdvantageantage">
                    {{lastXPoints.goalsScoredHomeAwayAdvantageantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsConcededHomeAwayAdvantageantage">
                    {{lastXPoints.goalsConcededHomeAwayAdvantageantage}}
                </div>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">
                <span>Overall%</span>
            </td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col">
                <div *ngIf="lastXPoints.winPercentageAdvantage">
                    {{lastXPoints.winPercentageAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.lossPercentageAdvantage">
                    {{lastXPoints.lossPercentageAdvantage}}
                </div>
            </td>

        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">
                <span>Overall</span>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsScoredAdvantageantage">
                    {{lastXPoints.goalsScoredAdvantageantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.goalsConcededAdvantageantage">
                    {{lastXPoints.goalsConcededAdvantageantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.winAdvantage">
                    {{lastXPoints.winAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.lossAdvantage">
                    {{lastXPoints.lossAdvantage}}
                </div>
            </td>

        </tr>
    </tbody>

</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="lastXPoints">
    <caption>Cumulative Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col">Neutral</th>
            <th scope="col">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Count</span>
            </td>
            <td scope="col">
                <span *ngIf="lastXPoints.hAdvantageCount">
                    {{lastXPoints.hAdvantageCount}}<br>
                </span>
                <span *ngIf="lastXPoints.hAdvantageCountPercentage">
                    {{lastXPoints.hAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!lastXPoints.hAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!lastXPoints.hAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="lastXPoints.neutralAdvantageCount">
                    {{lastXPoints.neutralAdvantageCount}}<br>
                </span>
                <span *ngIf="lastXPoints.neutralAdvantageCountPercentage">
                    {{lastXPoints.neutralAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!lastXPoints.neutralAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!lastXPoints.neutralAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="lastXPoints.aAdvantageCount">
                    {{lastXPoints.aAdvantageCount}}<br>
                </span>
                <span *ngIf="!lastXPoints.aAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="lastXPoints.aAdvantageCountPercentage">
                    {{lastXPoints.aAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!lastXPoints.aAdvantageCountPercentage">
                    0%
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="lastXPoints">
    <caption>Conclusion</caption>
    <thead>
        <tr>
            <th scope="col">Greater Than</th>
            <th scope="col">50%</th>
            <th scope="col">60%</th>
            <th scope="col">70%</th>
            <th scope="col">80%</th>
            <th scope="col">90%</th>
            <th scope="col">100%</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.advantage51">
                    {{lastXPoints.advantage51}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.advantage60">
                    {{lastXPoints.advantage60}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.advantage70">
                    {{lastXPoints.advantage70}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.advantage80">
                    {{lastXPoints.advantage80}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.advantage90">
                    {{lastXPoints.advantage90}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="lastXPoints.advantage100">
                    {{lastXPoints.advantage100}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

<!-- home scored -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Goals Scored</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsScoredH00 || 
            lastXEventAnalysis.homeTeamGoalsScoredH10 ||
            lastXEventAnalysis.homeTeamGoalsScoredH51 ||
            lastXEventAnalysis.homeTeamGoalsScoredH60 ||
            lastXEventAnalysis.homeTeamGoalsScoredH70 ||
            lastXEventAnalysis.homeTeamGoalsScoredH80 ||
            lastXEventAnalysis.homeTeamGoalsScoredH90 || 
            lastXEventAnalysis.homeTeamGoalsScoredH100">Home</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsScoredA00 || 
            lastXEventAnalysis.homeTeamGoalsScoredA10 ||
            lastXEventAnalysis.homeTeamGoalsScoredA51 ||
            lastXEventAnalysis.homeTeamGoalsScoredA60 ||
            lastXEventAnalysis.homeTeamGoalsScoredA70 ||
            lastXEventAnalysis.homeTeamGoalsScoredA80 ||
            lastXEventAnalysis.homeTeamGoalsScoredA90 || 
            lastXEventAnalysis.homeTeamGoalsScoredA100">Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    lastXEventAnalysis.homeTeamGoalsScoredH00 || 
    lastXEventAnalysis.homeTeamGoalsScoredH10 ||
    lastXEventAnalysis.homeTeamGoalsScoredH51 ||
    lastXEventAnalysis.homeTeamGoalsScoredH60 ||
    lastXEventAnalysis.homeTeamGoalsScoredH70 ||
    lastXEventAnalysis.homeTeamGoalsScoredH80 ||
    lastXEventAnalysis.homeTeamGoalsScoredH90 || 
    lastXEventAnalysis.homeTeamGoalsScoredH100 ||
    lastXEventAnalysis.homeTeamGoalsScoredA00 || 
    lastXEventAnalysis.homeTeamGoalsScoredA10 ||
    lastXEventAnalysis.homeTeamGoalsScoredA51 ||
    lastXEventAnalysis.homeTeamGoalsScoredA60 ||
    lastXEventAnalysis.homeTeamGoalsScoredA70 ||
    lastXEventAnalysis.homeTeamGoalsScoredA80 ||
    lastXEventAnalysis.homeTeamGoalsScoredA90 || 
    lastXEventAnalysis.homeTeamGoalsScoredA100">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>
                Scored
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsScoredH00 || 
            lastXEventAnalysis.homeTeamGoalsScoredH10 ||
            lastXEventAnalysis.homeTeamGoalsScoredH51 ||
            lastXEventAnalysis.homeTeamGoalsScoredH60 ||
            lastXEventAnalysis.homeTeamGoalsScoredH70 ||
            lastXEventAnalysis.homeTeamGoalsScoredH80 ||
            lastXEventAnalysis.homeTeamGoalsScoredH90 || 
            lastXEventAnalysis.homeTeamGoalsScoredH100">
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsScoredA00 || 
            lastXEventAnalysis.homeTeamGoalsScoredA10 ||
            lastXEventAnalysis.homeTeamGoalsScoredA51 ||
            lastXEventAnalysis.homeTeamGoalsScoredA60 ||
            lastXEventAnalysis.homeTeamGoalsScoredA70 ||
            lastXEventAnalysis.homeTeamGoalsScoredA80 ||
            lastXEventAnalysis.homeTeamGoalsScoredA90 || 
            lastXEventAnalysis.homeTeamGoalsScoredA100">
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsScoredA100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- away scored -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} - Goals Scored</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsScoredH00 || 
            lastXEventAnalysis.awayTeamGoalsScoredH10 ||
            lastXEventAnalysis.awayTeamGoalsScoredH51 ||
            lastXEventAnalysis.awayTeamGoalsScoredH60 ||
            lastXEventAnalysis.awayTeamGoalsScoredH70 ||
            lastXEventAnalysis.awayTeamGoalsScoredH80 ||
            lastXEventAnalysis.awayTeamGoalsScoredH90 || 
            lastXEventAnalysis.awayTeamGoalsScoredH100">Home</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsScoredA00 || 
            lastXEventAnalysis.awayTeamGoalsScoredA10 ||
            lastXEventAnalysis.awayTeamGoalsScoredA51 ||
            lastXEventAnalysis.awayTeamGoalsScoredA60 ||
            lastXEventAnalysis.awayTeamGoalsScoredA70 ||
            lastXEventAnalysis.awayTeamGoalsScoredA80 ||
            lastXEventAnalysis.awayTeamGoalsScoredA90 || 
            lastXEventAnalysis.awayTeamGoalsScoredA100">Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    lastXEventAnalysis.awayTeamGoalsScoredH00 || 
    lastXEventAnalysis.awayTeamGoalsScoredH10 ||
    lastXEventAnalysis.awayTeamGoalsScoredH51 ||
    lastXEventAnalysis.awayTeamGoalsScoredH60 ||
    lastXEventAnalysis.awayTeamGoalsScoredH70 ||
    lastXEventAnalysis.awayTeamGoalsScoredH80 ||
    lastXEventAnalysis.awayTeamGoalsScoredH90 || 
    lastXEventAnalysis.awayTeamGoalsScoredH100 ||
    lastXEventAnalysis.awayTeamGoalsScoredA00 || 
    lastXEventAnalysis.awayTeamGoalsScoredA10 ||
    lastXEventAnalysis.awayTeamGoalsScoredA51 ||
    lastXEventAnalysis.awayTeamGoalsScoredA60 ||
    lastXEventAnalysis.awayTeamGoalsScoredA70 ||
    lastXEventAnalysis.awayTeamGoalsScoredA80 ||
    lastXEventAnalysis.awayTeamGoalsScoredA90 || 
    lastXEventAnalysis.awayTeamGoalsScoredA100">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>
                Scored
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsScoredH00 || 
            lastXEventAnalysis.awayTeamGoalsScoredH10 ||
            lastXEventAnalysis.awayTeamGoalsScoredH51 ||
            lastXEventAnalysis.awayTeamGoalsScoredH60 ||
            lastXEventAnalysis.awayTeamGoalsScoredH70 ||
            lastXEventAnalysis.awayTeamGoalsScoredH80 ||
            lastXEventAnalysis.awayTeamGoalsScoredH90 || 
            lastXEventAnalysis.awayTeamGoalsScoredH100">
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsScoredA00 || 
            lastXEventAnalysis.awayTeamGoalsScoredA10 ||
            lastXEventAnalysis.awayTeamGoalsScoredA51 ||
            lastXEventAnalysis.awayTeamGoalsScoredA60 ||
            lastXEventAnalysis.awayTeamGoalsScoredA70 ||
            lastXEventAnalysis.awayTeamGoalsScoredA80 ||
            lastXEventAnalysis.awayTeamGoalsScoredA90 || 
            lastXEventAnalysis.awayTeamGoalsScoredA100">
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsScoredA100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home conceded -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Goals Conceded</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsConcededH00 || 
            lastXEventAnalysis.homeTeamGoalsConcededH10 ||
            lastXEventAnalysis.homeTeamGoalsConcededH51 ||
            lastXEventAnalysis.homeTeamGoalsConcededH60 ||
            lastXEventAnalysis.homeTeamGoalsConcededH70 ||
            lastXEventAnalysis.homeTeamGoalsConcededH80 ||
            lastXEventAnalysis.homeTeamGoalsConcededH90 || 
            lastXEventAnalysis.homeTeamGoalsConcededH100">Home</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsConcededA00 || 
            lastXEventAnalysis.homeTeamGoalsConcededA10 ||
            lastXEventAnalysis.homeTeamGoalsConcededA51 ||
            lastXEventAnalysis.homeTeamGoalsConcededA60 ||
            lastXEventAnalysis.homeTeamGoalsConcededA70 ||
            lastXEventAnalysis.homeTeamGoalsConcededA80 ||
            lastXEventAnalysis.homeTeamGoalsConcededA90 || 
            lastXEventAnalysis.homeTeamGoalsConcededA100">Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    lastXEventAnalysis.homeTeamGoalsConcededH00 || 
    lastXEventAnalysis.homeTeamGoalsConcededH10 ||
    lastXEventAnalysis.homeTeamGoalsConcededH51 ||
    lastXEventAnalysis.homeTeamGoalsConcededH60 ||
    lastXEventAnalysis.homeTeamGoalsConcededH70 ||
    lastXEventAnalysis.homeTeamGoalsConcededH80 ||
    lastXEventAnalysis.homeTeamGoalsConcededH90 || 
    lastXEventAnalysis.homeTeamGoalsConcededH100 ||
    lastXEventAnalysis.homeTeamGoalsConcededA00 || 
    lastXEventAnalysis.homeTeamGoalsConcededA10 ||
    lastXEventAnalysis.homeTeamGoalsConcededA51 ||
    lastXEventAnalysis.homeTeamGoalsConcededA60 ||
    lastXEventAnalysis.homeTeamGoalsConcededA70 ||
    lastXEventAnalysis.homeTeamGoalsConcededA80 ||
    lastXEventAnalysis.homeTeamGoalsConcededA90 || 
    lastXEventAnalysis.homeTeamGoalsConcededA100">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>
                Conceded
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsConcededH00 || 
            lastXEventAnalysis.homeTeamGoalsConcededH10 ||
            lastXEventAnalysis.homeTeamGoalsConcededH51 ||
            lastXEventAnalysis.homeTeamGoalsConcededH60 ||
            lastXEventAnalysis.homeTeamGoalsConcededH70 ||
            lastXEventAnalysis.homeTeamGoalsConcededH80 ||
            lastXEventAnalysis.homeTeamGoalsConcededH90 || 
            lastXEventAnalysis.homeTeamGoalsConcededH100">
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.homeTeamGoalsConcededA00 || 
            lastXEventAnalysis.homeTeamGoalsConcededA10 ||
            lastXEventAnalysis.homeTeamGoalsConcededA51 ||
            lastXEventAnalysis.homeTeamGoalsConcededA60 ||
            lastXEventAnalysis.homeTeamGoalsConcededA70 ||
            lastXEventAnalysis.homeTeamGoalsConcededA80 ||
            lastXEventAnalysis.homeTeamGoalsConcededA90 || 
            lastXEventAnalysis.homeTeamGoalsConcededA100">
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamGoalsConcededA100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- away conceded -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} - Goals Conceded</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsConcededH00 || 
            lastXEventAnalysis.awayTeamGoalsConcededH10 ||
            lastXEventAnalysis.awayTeamGoalsConcededH51 ||
            lastXEventAnalysis.awayTeamGoalsConcededH60 ||
            lastXEventAnalysis.awayTeamGoalsConcededH70 ||
            lastXEventAnalysis.awayTeamGoalsConcededH80 ||
            lastXEventAnalysis.awayTeamGoalsConcededH90 || 
            lastXEventAnalysis.awayTeamGoalsConcededH100">Home</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsConcededA00 || 
            lastXEventAnalysis.awayTeamGoalsConcededA10 ||
            lastXEventAnalysis.awayTeamGoalsConcededA51 ||
            lastXEventAnalysis.awayTeamGoalsConcededA60 ||
            lastXEventAnalysis.awayTeamGoalsConcededA70 ||
            lastXEventAnalysis.awayTeamGoalsConcededA80 ||
            lastXEventAnalysis.awayTeamGoalsConcededA90 || 
            lastXEventAnalysis.awayTeamGoalsConcededA100">Away</th>
        </tr>
    </thead>
    <tbody *ngIf="
    lastXEventAnalysis.awayTeamGoalsConcededH00 || 
    lastXEventAnalysis.awayTeamGoalsConcededH10 ||
    lastXEventAnalysis.awayTeamGoalsConcededH51 ||
    lastXEventAnalysis.awayTeamGoalsConcededH60 ||
    lastXEventAnalysis.awayTeamGoalsConcededH70 ||
    lastXEventAnalysis.awayTeamGoalsConcededH80 ||
    lastXEventAnalysis.awayTeamGoalsConcededH90 || 
    lastXEventAnalysis.awayTeamGoalsConcededH100 ||
    lastXEventAnalysis.awayTeamGoalsConcededA00 || 
    lastXEventAnalysis.awayTeamGoalsConcededA10 ||
    lastXEventAnalysis.awayTeamGoalsConcededA51 ||
    lastXEventAnalysis.awayTeamGoalsConcededA60 ||
    lastXEventAnalysis.awayTeamGoalsConcededA70 ||
    lastXEventAnalysis.awayTeamGoalsConcededA80 ||
    lastXEventAnalysis.awayTeamGoalsConcededA90 || 
    lastXEventAnalysis.awayTeamGoalsConcededA100">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>
                Conceded
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsConcededH00 || 
            lastXEventAnalysis.awayTeamGoalsConcededH10 ||
            lastXEventAnalysis.awayTeamGoalsConcededH51 ||
            lastXEventAnalysis.awayTeamGoalsConcededH60 ||
            lastXEventAnalysis.awayTeamGoalsConcededH70 ||
            lastXEventAnalysis.awayTeamGoalsConcededH80 ||
            lastXEventAnalysis.awayTeamGoalsConcededH90 || 
            lastXEventAnalysis.awayTeamGoalsConcededH100">
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.awayTeamGoalsConcededA00 || 
            lastXEventAnalysis.awayTeamGoalsConcededA10 ||
            lastXEventAnalysis.awayTeamGoalsConcededA51 ||
            lastXEventAnalysis.awayTeamGoalsConcededA60 ||
            lastXEventAnalysis.awayTeamGoalsConcededA70 ||
            lastXEventAnalysis.awayTeamGoalsConcededA80 ||
            lastXEventAnalysis.awayTeamGoalsConcededA90 || 
            lastXEventAnalysis.awayTeamGoalsConcededA100">
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamGoalsConcededA100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home win/loss/draw -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Win/Loss/Draw</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
            <th class="teams text-start w-25">Overall</th>
        </tr>
    </thead>
    <tbody *ngIf="
    lastXEventAnalysis.homeTeamLastXWinH00 || 
    lastXEventAnalysis.homeTeamLastXWinH10 ||
    lastXEventAnalysis.homeTeamLastXWinH51 ||
    lastXEventAnalysis.homeTeamLastXWinH60 ||
    lastXEventAnalysis.homeTeamLastXWinH70 ||
    lastXEventAnalysis.homeTeamLastXWinH80 ||
    lastXEventAnalysis.homeTeamLastXWinH90 || 
    lastXEventAnalysis.homeTeamLastXWinH100 ||
    lastXEventAnalysis.homeTeamLastXWinA00 || 
    lastXEventAnalysis.homeTeamLastXWinA10 ||
    lastXEventAnalysis.homeTeamLastXWinA51 ||
    lastXEventAnalysis.homeTeamLastXWinA60 ||
    lastXEventAnalysis.homeTeamLastXWinA70 ||
    lastXEventAnalysis.homeTeamLastXWinA80 ||
    lastXEventAnalysis.homeTeamLastXWinA90 || 
    lastXEventAnalysis.homeTeamLastXWinA100 ||
    lastXEventAnalysis.homeTeamLastXWin00 || 
    lastXEventAnalysis.homeTeamLastXWin10 ||
    lastXEventAnalysis.homeTeamLastXWin51 ||
    lastXEventAnalysis.homeTeamLastXWin60 ||
    lastXEventAnalysis.homeTeamLastXWin70 ||
    lastXEventAnalysis.homeTeamLastXWin80 ||
    lastXEventAnalysis.homeTeamLastXWin90 || 
    lastXEventAnalysis.homeTeamLastXWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWinA100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    lastXEventAnalysis.homeTeamLastXDrawH00 || 
    lastXEventAnalysis.homeTeamLastXDrawH10 ||
    lastXEventAnalysis.homeTeamLastXDrawH51 ||
    lastXEventAnalysis.homeTeamLastXDrawH60 ||
    lastXEventAnalysis.homeTeamLastXDrawH70 ||
    lastXEventAnalysis.homeTeamLastXDrawH80 ||
    lastXEventAnalysis.homeTeamLastXDrawH90 || 
    lastXEventAnalysis.homeTeamLastXDrawH100 ||
    lastXEventAnalysis.homeTeamLastXDrawA00 || 
    lastXEventAnalysis.homeTeamLastXDrawA10 ||
    lastXEventAnalysis.homeTeamLastXDrawA51 ||
    lastXEventAnalysis.homeTeamLastXDrawA60 ||
    lastXEventAnalysis.homeTeamLastXDrawA70 ||
    lastXEventAnalysis.homeTeamLastXDrawA80 ||
    lastXEventAnalysis.homeTeamLastXDrawA90 || 
    lastXEventAnalysis.homeTeamLastXDrawA100 ||
    lastXEventAnalysis.homeTeamLastXDraw00 || 
    lastXEventAnalysis.homeTeamLastXDraw10 ||
    lastXEventAnalysis.homeTeamLastXDraw51 ||
    lastXEventAnalysis.homeTeamLastXDraw60 ||
    lastXEventAnalysis.homeTeamLastXDraw70 ||
    lastXEventAnalysis.homeTeamLastXDraw80 ||
    lastXEventAnalysis.homeTeamLastXDraw90 || 
    lastXEventAnalysis.homeTeamLastXDraw100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDrawA100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXDraw100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    lastXEventAnalysis.homeTeamLastXLossH00 || 
    lastXEventAnalysis.homeTeamLastXLossH10 ||
    lastXEventAnalysis.homeTeamLastXLossH51 ||
    lastXEventAnalysis.homeTeamLastXLossH60 ||
    lastXEventAnalysis.homeTeamLastXLossH70 ||
    lastXEventAnalysis.homeTeamLastXLossH80 ||
    lastXEventAnalysis.homeTeamLastXLossH90 || 
    lastXEventAnalysis.homeTeamLastXLossH100 ||
    lastXEventAnalysis.homeTeamLastXLossA00 || 
    lastXEventAnalysis.homeTeamLastXLossA10 ||
    lastXEventAnalysis.homeTeamLastXLossA51 ||
    lastXEventAnalysis.homeTeamLastXLossA60 ||
    lastXEventAnalysis.homeTeamLastXLossA70 ||
    lastXEventAnalysis.homeTeamLastXLossA80 ||
    lastXEventAnalysis.homeTeamLastXLossA90 || 
    lastXEventAnalysis.homeTeamLastXLossA100 ||
    lastXEventAnalysis.homeTeamLastXLoss00 || 
    lastXEventAnalysis.homeTeamLastXLoss10 ||
    lastXEventAnalysis.homeTeamLastXLoss51 ||
    lastXEventAnalysis.homeTeamLastXLoss60 ||
    lastXEventAnalysis.homeTeamLastXLoss70 ||
    lastXEventAnalysis.homeTeamLastXLoss80 ||
    lastXEventAnalysis.homeTeamLastXLoss90 || 
    lastXEventAnalysis.homeTeamLastXLoss100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLossA100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.homeTeamLastXLoss100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- away win/loss/draw -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} - Win/Loss/Draw</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">Home</th>
            <th class="teams text-start w-25">Away</th>
            <th class="teams text-start w-25">Overall</th>
        </tr>
    </thead>
    <tbody *ngIf="
    lastXEventAnalysis.awayTeamLastXWinH00 || 
    lastXEventAnalysis.awayTeamLastXWinH10 ||
    lastXEventAnalysis.awayTeamLastXWinH51 ||
    lastXEventAnalysis.awayTeamLastXWinH60 ||
    lastXEventAnalysis.awayTeamLastXWinH70 ||
    lastXEventAnalysis.awayTeamLastXWinH80 ||
    lastXEventAnalysis.awayTeamLastXWinH90 || 
    lastXEventAnalysis.awayTeamLastXWinH100 ||
    lastXEventAnalysis.awayTeamLastXWinA00 || 
    lastXEventAnalysis.awayTeamLastXWinA10 ||
    lastXEventAnalysis.awayTeamLastXWinA51 ||
    lastXEventAnalysis.awayTeamLastXWinA60 ||
    lastXEventAnalysis.awayTeamLastXWinA70 ||
    lastXEventAnalysis.awayTeamLastXWinA80 ||
    lastXEventAnalysis.awayTeamLastXWinA90 || 
    lastXEventAnalysis.awayTeamLastXWinA100 ||
    lastXEventAnalysis.awayTeamLastXWin00 || 
    lastXEventAnalysis.awayTeamLastXWin10 ||
    lastXEventAnalysis.awayTeamLastXWin51 ||
    lastXEventAnalysis.awayTeamLastXWin60 ||
    lastXEventAnalysis.awayTeamLastXWin70 ||
    lastXEventAnalysis.awayTeamLastXWin80 ||
    lastXEventAnalysis.awayTeamLastXWin90 || 
    lastXEventAnalysis.awayTeamLastXWin100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWinA100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXWin100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    lastXEventAnalysis.awayTeamLastXDrawH00 || 
    lastXEventAnalysis.awayTeamLastXDrawH10 ||
    lastXEventAnalysis.awayTeamLastXDrawH51 ||
    lastXEventAnalysis.awayTeamLastXDrawH60 ||
    lastXEventAnalysis.awayTeamLastXDrawH70 ||
    lastXEventAnalysis.awayTeamLastXDrawH80 ||
    lastXEventAnalysis.awayTeamLastXDrawH90 || 
    lastXEventAnalysis.awayTeamLastXDrawH100 ||
    lastXEventAnalysis.awayTeamLastXDrawA00 || 
    lastXEventAnalysis.awayTeamLastXDrawA10 ||
    lastXEventAnalysis.awayTeamLastXDrawA51 ||
    lastXEventAnalysis.awayTeamLastXDrawA60 ||
    lastXEventAnalysis.awayTeamLastXDrawA70 ||
    lastXEventAnalysis.awayTeamLastXDrawA80 ||
    lastXEventAnalysis.awayTeamLastXDrawA90 || 
    lastXEventAnalysis.awayTeamLastXDrawA100 ||
    lastXEventAnalysis.awayTeamLastXDraw00 || 
    lastXEventAnalysis.awayTeamLastXDraw10 ||
    lastXEventAnalysis.awayTeamLastXDraw51 ||
    lastXEventAnalysis.awayTeamLastXDraw60 ||
    lastXEventAnalysis.awayTeamLastXDraw70 ||
    lastXEventAnalysis.awayTeamLastXDraw80 ||
    lastXEventAnalysis.awayTeamLastXDraw90 || 
    lastXEventAnalysis.awayTeamLastXDraw100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDrawA100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXDraw100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    lastXEventAnalysis.awayTeamLastXLossH00 || 
    lastXEventAnalysis.awayTeamLastXLossH10 ||
    lastXEventAnalysis.awayTeamLastXLossH51 ||
    lastXEventAnalysis.awayTeamLastXLossH60 ||
    lastXEventAnalysis.awayTeamLastXLossH70 ||
    lastXEventAnalysis.awayTeamLastXLossH80 ||
    lastXEventAnalysis.awayTeamLastXLossH90 || 
    lastXEventAnalysis.awayTeamLastXLossH100 ||
    lastXEventAnalysis.awayTeamLastXLossA00 || 
    lastXEventAnalysis.awayTeamLastXLossA10 ||
    lastXEventAnalysis.awayTeamLastXLossA51 ||
    lastXEventAnalysis.awayTeamLastXLossA60 ||
    lastXEventAnalysis.awayTeamLastXLossA70 ||
    lastXEventAnalysis.awayTeamLastXLossA80 ||
    lastXEventAnalysis.awayTeamLastXLossA90 || 
    lastXEventAnalysis.awayTeamLastXLossA100 ||
    lastXEventAnalysis.awayTeamLastXLoss00 || 
    lastXEventAnalysis.awayTeamLastXLoss10 ||
    lastXEventAnalysis.awayTeamLastXLoss51 ||
    lastXEventAnalysis.awayTeamLastXLoss60 ||
    lastXEventAnalysis.awayTeamLastXLoss70 ||
    lastXEventAnalysis.awayTeamLastXLoss80 ||
    lastXEventAnalysis.awayTeamLastXLoss90 || 
    lastXEventAnalysis.awayTeamLastXLoss100 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss</td>
            <td class="time text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossH100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLossA100">Ov100%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss00">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss10">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss51">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss60">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss70">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss80">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss90">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.awayTeamLastXLoss100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- home ov/un -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} - Ov/Un</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
    lastXEventAnalysis.lastXHomeOv0500 || 
    lastXEventAnalysis.lastXHomeOv0510 ||
    lastXEventAnalysis.lastXHomeOv0551 ||
    lastXEventAnalysis.lastXHomeOv0560 ||
    lastXEventAnalysis.lastXHomeOv0570 ||
    lastXEventAnalysis.lastXHomeOv0580 ||
    lastXEventAnalysis.lastXHomeOv0590 || 
    lastXEventAnalysis.lastXHomeOv05100 ">
                Ov0.5</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.lastXHomeOv1500 || 
            lastXEventAnalysis.lastXHomeOv1510 ||
            lastXEventAnalysis.lastXHomeOv1551 ||
            lastXEventAnalysis.lastXHomeOv1560 ||
            lastXEventAnalysis.lastXHomeOv1570 ||
            lastXEventAnalysis.lastXHomeOv1580 ||
            lastXEventAnalysis.lastXHomeOv1590 || 
            lastXEventAnalysis.lastXHomeOv15100 ">
                Ov1.5</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.lastXHomeOv2500 || 
            lastXEventAnalysis.lastXHomeOv2510 ||
            lastXEventAnalysis.lastXHomeOv2551 ||
            lastXEventAnalysis.lastXHomeOv2560 ||
            lastXEventAnalysis.lastXHomeOv2570 ||
            lastXEventAnalysis.lastXHomeOv2580 ||
            lastXEventAnalysis.lastXHomeOv2590 || 
            lastXEventAnalysis.lastXHomeOv25100 ">
                Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.lastXHomeOv0500 || 
            lastXEventAnalysis.lastXHomeOv0510 ||
            lastXEventAnalysis.lastXHomeOv0551 ||
            lastXEventAnalysis.lastXHomeOv0560 ||
            lastXEventAnalysis.lastXHomeOv0570 ||
            lastXEventAnalysis.lastXHomeOv0580 ||
            lastXEventAnalysis.lastXHomeOv0590 || 
            lastXEventAnalysis.lastXHomeOv05100 ">
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0500">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0511">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0551">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0560">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0570">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0580">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv0590">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv05100">Ov100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.lastXHomeOv1500 || 
            lastXEventAnalysis.lastXHomeOv1510 ||
            lastXEventAnalysis.lastXHomeOv1551 ||
            lastXEventAnalysis.lastXHomeOv1560 ||
            lastXEventAnalysis.lastXHomeOv1570 ||
            lastXEventAnalysis.lastXHomeOv1580 ||
            lastXEventAnalysis.lastXHomeOv1590 || 
            lastXEventAnalysis.lastXHomeOv15100 ">
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1500">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1511">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1551">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1560">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1570">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1580">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv1590">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv15100">Ov100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.lastXHomeOv2500 || 
            lastXEventAnalysis.lastXHomeOv2510 ||
            lastXEventAnalysis.lastXHomeOv2551 ||
            lastXEventAnalysis.lastXHomeOv2560 ||
            lastXEventAnalysis.lastXHomeOv2570 ||
            lastXEventAnalysis.lastXHomeOv2580 ||
            lastXEventAnalysis.lastXHomeOv2590 || 
            lastXEventAnalysis.lastXHomeOv25100 ">
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2500">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2511">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2551">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2560">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2570">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2580">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv2590">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXHomeOv25100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>
<!-- away ov/un -->
<table class="table table-sm flex-fill" *ngIf="lastXEventAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}} - Ov/Un</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25" *ngIf="
    lastXEventAnalysis.lastXAwayOv0500 || 
    lastXEventAnalysis.lastXAwayOv0510 ||
    lastXEventAnalysis.lastXAwayOv0551 ||
    lastXEventAnalysis.lastXAwayOv0560 ||
    lastXEventAnalysis.lastXAwayOv0570 ||
    lastXEventAnalysis.lastXAwayOv0580 ||
    lastXEventAnalysis.lastXAwayOv0590 || 
    lastXEventAnalysis.lastXAwayOv05100 ">
                Ov0.5</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.lastXAwayOv1500 || 
            lastXEventAnalysis.lastXAwayOv1510 ||
            lastXEventAnalysis.lastXAwayOv1551 ||
            lastXEventAnalysis.lastXAwayOv1560 ||
            lastXEventAnalysis.lastXAwayOv1570 ||
            lastXEventAnalysis.lastXAwayOv1580 ||
            lastXEventAnalysis.lastXAwayOv1590 || 
            lastXEventAnalysis.lastXAwayOv15100 ">
                Ov1.5</th>
            <th class="teams text-start w-25" *ngIf="
            lastXEventAnalysis.lastXAwayOv2500 || 
            lastXEventAnalysis.lastXAwayOv2510 ||
            lastXEventAnalysis.lastXAwayOv2551 ||
            lastXEventAnalysis.lastXAwayOv2560 ||
            lastXEventAnalysis.lastXAwayOv2570 ||
            lastXEventAnalysis.lastXAwayOv2580 ||
            lastXEventAnalysis.lastXAwayOv2590 || 
            lastXEventAnalysis.lastXAwayOv25100 ">
                Ov2.5</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals<br>Over/Under</td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.lastXAwayOv0500 || 
            lastXEventAnalysis.lastXAwayOv0510 ||
            lastXEventAnalysis.lastXAwayOv0551 ||
            lastXEventAnalysis.lastXAwayOv0560 ||
            lastXEventAnalysis.lastXAwayOv0570 ||
            lastXEventAnalysis.lastXAwayOv0580 ||
            lastXEventAnalysis.lastXAwayOv0590 || 
            lastXEventAnalysis.lastXAwayOv05100 ">
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0500">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0511">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0551">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0560">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0570">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0580">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv0590">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv05100">Ov100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.lastXAwayOv1500 || 
            lastXEventAnalysis.lastXAwayOv1510 ||
            lastXEventAnalysis.lastXAwayOv1551 ||
            lastXEventAnalysis.lastXAwayOv1560 ||
            lastXEventAnalysis.lastXAwayOv1570 ||
            lastXEventAnalysis.lastXAwayOv1580 ||
            lastXEventAnalysis.lastXAwayOv1590 || 
            lastXEventAnalysis.lastXAwayOv15100 ">
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1500">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1511">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1551">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1560">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1570">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1580">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv1590">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv15100">Ov100%<br></span>
            </td>
            <td class="time text-start w-25" *ngIf="
            lastXEventAnalysis.lastXAwayOv2500 || 
            lastXEventAnalysis.lastXAwayOv2510 ||
            lastXEventAnalysis.lastXAwayOv2551 ||
            lastXEventAnalysis.lastXAwayOv2560 ||
            lastXEventAnalysis.lastXAwayOv2570 ||
            lastXEventAnalysis.lastXAwayOv2580 ||
            lastXEventAnalysis.lastXAwayOv2590 || 
            lastXEventAnalysis.lastXAwayOv25100 ">
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2500">Un0%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2511">Un10%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2551">Ov51%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2560">Ov60%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2570">Ov70%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2580">Ov80%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv2590">Ov90%<br></span>
                <span *ngIf="lastXEventAnalysis.lastXAwayOv25100">Ov100%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points -->
<table class="table table-sm flex-fill" *ngIf="lastXAnalysisPoints$">
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">3</th>
            <th class="teams text-start">4</th>
            <th class="teams text-start">5</th>
            <th class="teams text-start">6</th>
            <th class="teams text-start">0.5</th>
            <th class="teams text-start">1.5</th>
            <th class="teams text-start">2.5</th>
            <th class="teams text-start">GG/GN/NN</th>
            <th class="teams text-start"></th>
            <th class="teams text-start">Result 1X2</th>
            <th class="teams text-start">Result Ov/Un</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span [class]="lastXAnalysisPoints$.hAScoredAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="lastXAnalysisPoints$.hAScoredAdvantage">{{lastXAnalysisPoints$.hAScoredAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span [class]="lastXAnalysisPoints$.hAConcededAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="lastXAnalysisPoints$.hAConcededAdvantage">{{lastXAnalysisPoints$.hAConcededAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span [class]="lastXAnalysisPoints$.hAWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                        *ngIf="lastXAnalysisPoints$.hAWinDrawLossAdvantage">{{lastXAnalysisPoints$.hAWinDrawLossAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span [class]="lastXAnalysisPoints$.homeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="lastXAnalysisPoints$.homeHomeAwayAwayScoredAdvantage">{{lastXAnalysisPoints$.homeHomeAwayAwayScoredAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span [class]="lastXAnalysisPoints$.homeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                        *ngIf="lastXAnalysisPoints$.homeHomeAwayAwayConcededAdvantage">{{lastXAnalysisPoints$.homeHomeAwayAwayConcededAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span [class]="lastXAnalysisPoints$.homeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                        *ngIf="lastXAnalysisPoints$.homeHomeAwayAwayWinDrawLossAdvantage">{{lastXAnalysisPoints$.homeHomeAwayAwayWinDrawLossAdvantage}}</span></span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                    <span [class]="lastXAnalysisPoints$.Ov05Rslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.Ov05">Ov0.5<br></span></span>
                            <span [class]="lastXAnalysisPoints$.Un05Rslt?'green-bd':'red-bg'"><span
                                *ngIf="lastXAnalysisPoints$.Un05">Un0.5</span></span>
                        </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                    <span [class]="lastXAnalysisPoints$.Ov15Rslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.Ov15">Ov1.5<br></span></span>
                            <span [class]="lastXAnalysisPoints$.Un15Rslt?'green-bd':'red-bg'"><span
                                *ngIf="lastXAnalysisPoints$.Un15">Un1.5</span></span>
                        </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                    <span [class]="lastXAnalysisPoints$.Ov25Rslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.Ov25">Ov2.5<br></span></span>
                            <span [class]="lastXAnalysisPoints$.Un25Rslt?'green-bd':'red-bg'"><span
                                *ngIf="lastXAnalysisPoints$.Un25">Un2.5</span></span>
                        </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                    <span [class]="lastXAnalysisPoints$.hHAwayGGARslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.hHAwayGGA">GG<br></span></span>
                    <span [class]="lastXAnalysisPoints$.hHAwayGNARslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.hHAwayGNA">GN<br></span></span>
                    <span [class]="lastXAnalysisPoints$.hHAwayNNARslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.hHAwayNNA">NN<br></span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                    <span [class]="lastXAnalysisPoints$.homeGGARslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.homeGGA">GG<br></span></span>
                    <span [class]="lastXAnalysisPoints$.homeGNARslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.homeGNA">GN<br></span></span>
                    <span [class]="lastXAnalysisPoints$.homeNNARslt?'green-bd':'red-bg'"><span
                            *ngIf="lastXAnalysisPoints$.homeNNA">NN<br></span></span>
                </span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span *ngIf="lastXAnalysisPoints$.result1X2">{{lastXAnalysisPoints$.result1X2}}</span></span>
            </td>
            <td class="teams text-start">
                <span *ngIf="lastXAnalysisPoints$">
                <span *ngIf="lastXAnalysisPoints$.resultOv25">Ov2.5</span>
                <span *ngIf="!lastXAnalysisPoints$.resultOv25">
                    <span *ngIf="lastXAnalysisPoints$.resultOv15">Ov1.5</span>
                    <span *ngIf="!lastXAnalysisPoints$.resultOv15">
                        <span *ngIf="lastXAnalysisPoints$.resultOv05">Ov0.5</span>
                    </span>
                </span>
            </span>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>Home Vs Away (Cumulative)<br></Span>
    <Span>1 :    Scored<br></Span>
    <Span>2 :    Conceded<br></Span>
    <Span>3 :    Win Loss Draw<br></Span>
    <Span>Home Team at Home Vs Away Team at Away<br></Span>
    <Span>4 :    Scored<br></Span>
    <Span>5 :    Conceded<br></Span>
    <Span>6 :    Win Loss Draw<br></Span>
</p>