<div class="card-group pb-3" *ngIf="dates">
    <div class="card" *ngFor="let date of dates;let i = index;">
      <div class="card-body">
        <h5 class="card-title">{{date.date}}</h5>
        <p class="card-text">{{date.month}}</p>
      </div>
      <div class="card-footer">
        <small class="text-muted" *ngIf="i === 0">Today</small>
        <small class="text-muted" *ngIf="i !== 0">Day {{i}}</small>
      </div>
    </div>
  </div>
  
  <form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
      <div class="input-group input-group-sm mb-3">
        <label class="input-group-text" for="date">Pick a Date</label>
        <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
          aria-describedby="inputGroup-sizing-sm" required>
      </div>
    </div>
    <div class="col-4">
      <div class="input-group input-group-sm mb-3">
        <button class="w-100 btn btn-primary btn-lg" [disabled]="!datePicked || requestClick"
          (click)="requestEventDateData()">Request Data</button>
      </div>
    </div>
    <div class="col-2"></div>
  </form>
  
  <div class="container">
    <p>
      <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
  </div>
  
  <table class="table table-sm flex-fill">
    <thead class="table-light">
      <tr class="small-tx">
        <th>#</th>
        <th class="teams text-start w-25">Home</th>
        <th class="teams text-start w-25">Away</th>
        <th class="teams text-start w-25">Competition</th>
        <th class="teams text-start w-25">Event Id</th>
        <th class="teams text-start w-25">Season Id</th>
        <th class="teams text-start w-25">Round</th>
        <th class="teams text-start w-25"></th>
        <th class="teams text-start w-25"></th>
      </tr>
    </thead>
    <tbody *ngFor="let event of filteredEvent$;let i = index;">
      <tr class="cursor-pointer status-live small-tx">
        <td>{{i+1}}.</td>
        <td class="time text-start w-25">
          {{event.homeTeamNameLong}}<br>
          {{event.homeTeamId}}
        </td>
        <td class="teams text-start w-25">
          {{event.awayTeamNameLong}}<br>
          {{event.awayTeamId}}
        </td>
        <td class="teams text-start w-25">
          {{event.competitionName}}
        </td>
        <td class="teams text-start w-25">
          {{event.eventId}}
        </td>
        <td class="teams text-start w-25">
          {{event.seasonId}}
        </td>
        <td class="teams text-start w-25">
          {{event.eRound}}
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary" [routerLink]="['/football/match',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Event</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-event/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Analysis</button>
          </div>
        </td>
      </tr>
      <tr class="cursor-pointer status-live small-tx">
        <td class="teams text-start w-25"></td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-h2h/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">H2H</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-last-x/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Last X</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-next-x/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Next X</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-top-scorer/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Top Scorer</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-top-assist/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Top Assist</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-top-cards/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Top Card</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-season-table/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Season Table</button>
          </div>
        </td>
        <td class="teams text-start w-25">
          <div *ngIf="event.eventId">
            <button class="btn btn-sm btn-outline-primary"
              [routerLink]="['/analysis-dashboard/analysis-season-last-x/',event.eventId]"
              [queryParams]="{ 'date': datePicked}">Season Last X</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  
  <div class="form-body" *ngIf="!filteredEvent$ || filteredEvent$.length < 1">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Empty</h1>
          <p class="lead">No Data</p>
        </div>
      </div>
    </div>
  </div>