import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalysisTasksStatus } from '../interface/analysis-tasks-status';
import { Competition } from '../interface/competition';
import { Event } from '../interface/event';
import { EventH2h } from '../interface/event-h2h';
import { EventTasksStatus } from '../interface/event-tasks-status';
import { Fixtures } from '../interface/fixtures';
import { Jackpot } from '../interface/jackpot';
import { JackpotBookmaker } from '../interface/jackpot-bookmaker';
import { League2 } from '../interface/league2';
import { DataTaskStatus } from '../interface/data-task-status';
import { CleanUpTasksStatus } from '../interface/clean-up-tasks-status';
import { PrototypeJackpot } from '../interface/prototype-jackpot';

@Injectable({
  providedIn: 'root'
})
export class FootballService {

  constructor(
    private db: AngularFireDatabase,
  ) { }

  // 3rd March 2023

  getJpData(){
    return this.db.list<PrototypeJackpot>('/r/pr-jp-f', (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  // 3rd March 2023

  getMatchesByDate(matchDate: string) {
    return this.db.list<Competition>(`/fixture/${matchDate}/competition`, (query) => {
      return query.orderByKey();
    });
  }

  getMaintenanceData(matchDate: string) {
    return this.db.list<any>(`/d-chk`, (query) => query.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getAllEventsByDate_Object(matchDate: string) {
    return this.db.object<any>(`/fixture/${matchDate}/event`).valueChanges();
  }

  getH2HData(matchDate: string, eventId) {
    return this.db.object<any>(`/h2h-data/${matchDate}/${eventId}`).valueChanges();
  }

  getH2HAnalysis(matchDate: string, eventId) {
    return this.db.object<any>(`/h2h-analysis/${matchDate}/${eventId}`).valueChanges();
  }

  getLastXPoints(matchDate: string, eventId) {
    return this.db.object<any>(`/last-x-points/${matchDate}/${eventId}`).valueChanges();
  }

  getLastXEventPoints(matchDate: string, eventId) {
    return this.db.object<any>(`/last-x-e-points/${matchDate}/${eventId}`).valueChanges();
  }

  getLastXEventAnalysis(matchDate: string, eventId) {
    return this.db.object<any>(`/last-x-analysis/${matchDate}/${eventId}`).valueChanges();
  }

  getAllEventsByDate_List(matchDate: string) {
    return this.db.list<any>(`/fixture/${matchDate}/event`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCountryList(matchDate: string) {
    return this.db.list<any>(`/fixture/${matchDate}/country`, (ref) => ref.orderByChild("name"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionList(matchDate: string) {
    return this.db.list<any>(`/fixture/${matchDate}/competition`, (ref) => ref.orderByChild("competitionName"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionTopScorerList(matchDate: string) {
    return this.db.list<any>(`/top-scorer/${matchDate}`, (ref) => ref
      .orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionSeasonTopCards(matchDate: string) {
    return this.db.list<any>(`/top-cards/${matchDate}`, (ref) => ref
      .orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionSeasonTopAssist(matchDate: string) {
    return this.db.list<any>(`/top-assist/${matchDate}`, (ref) => ref
      .orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionH2HList(matchDate: string) {
    return this.db.list<any>(`/h2h/${matchDate}/events`, (ref) => ref.orderByChild("competitionName"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionSeasonTable(matchDate: string) {
    return this.db.list<any>(`/season-table/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionSeasonLastX(matchDate: string) {
    return this.db.list<any>(`/last-x-season/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitionSeasonLastXOdds(matchDate: string) {
    return this.db.list<any>(`/last-x-season-odds/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getSeasonList(matchDate: string) {
    return this.db.list<any>(`/fixture/${matchDate}/season`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventList(matchDate: string) {
    return this.db.list<any>(`/fixture/${matchDate}/event`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventObject() {
    return this.db.object<any>(`/fixture`).valueChanges();
  }

  getH2HEventAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/h2h-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getH2HPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/h2h-analysis-points/${matchDate}`).valueChanges();
  }

  getH2HEventAnalysisPointsByMatch(matchDate: string, eventId: any) {
    return this.db.object<any>(`/h2h-analysis-points/${matchDate}/${eventId}`).valueChanges();
  }

  getTopScorerAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/top-scorer-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getTopScorerPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/top-scorer-analysis-points/${matchDate}`).valueChanges();
  }

  getTopScorerAnalysisPointsByMatch(matchDate: string, matchId: any) {
    return this.db.object<any>(`/top-scorer-event-analysis-points/${matchDate}/${matchId}`).valueChanges();
  }

  getTopAssistAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/top-assist-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }
  
  getTopAssistPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/top-assist-analysis-points/${matchDate}`).valueChanges();
  }

  getTopAssistAnalysisPointsByMatch(matchDate: string, matchId: any) {
    return this.db.object<any>(`/top-assist-event-analysis-points/${matchDate}/${matchId}`).valueChanges();
  }

  getTopCardAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/top-card-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getTopCardPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/top-card-analysis-points/${matchDate}`).valueChanges();
  }

  getTopCardAnalysisPointsByMatch(matchDate: string, matchId: any) {
    return this.db.object<any>(`/top-cards-event-analysis-points/${matchDate}/${matchId}`).valueChanges();
  }

  getSeasonTableAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/table-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getSeasonTablePointsList_Object(matchDate: string) {
    return this.db.object<any>(`/table-analysis-points/${matchDate}`).valueChanges();
  }

  getSeasonTableAnalysisPointsByMatch(matchDate: string, matchId: any) {
    return this.db.object<any>(`/table-analysis-points/${matchDate}/${matchId}`).valueChanges();
  }

  getLastXAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/last-x-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getLastXPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/last-x-analysis-points/${matchDate}`).valueChanges();
  }

  getLastXAnalysisPointsByMatch(matchDate: string, matchId) {
    return this.db.object<any>(`/last-x-analysis-points/${matchDate}/${matchId}`).valueChanges();
  }

  getSeasonLastXAnalysisPoints(matchDate: string) {
    return this.db.list<any>(`/last-x-s-analysis-points/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getSeasonLastXPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/last-x-s-analysis-points/${matchDate}`).valueChanges();
  }

  getSeasonLastXAnalysisPointsByMatch(matchDate: string, matchId: any) {
    return this.db.object<any>(`/season-last-x-analysis-points/${matchDate}/${matchId}`).valueChanges();
  }

  getAnalysisPointsList(matchDate: string) {
    return this.db.list<any>(`/consolidated-analysis-points-sheet/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getSummaryCompetitionList(matchDate: string,seasonId: string) {
    return this.db.list<any>(`/analysis-summary-tournament/${matchDate}/${seasonId}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getGGGNNNExtractList(matchDate: string) {
    return this.db.list<any>(`/extract/GGGNNN/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getGGGNNNSlipList(matchDate: string) {
    return this.db.list<any>(`/slip/${matchDate}/GGGNNN`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getOvUnSlipList(matchDate: string) {
    return this.db.list<any>(`/slip/${matchDate}/OvUn`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getOneXTwoS1SlipList(matchDate: string) {
    return this.db.list<any>(`/slip/${matchDate}/1X2S1`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getOneXTwoS2SlipList(matchDate: string) {
    return this.db.list<any>(`/slip/${matchDate}/1X2S2`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getOneXTwoS3SlipList(matchDate: string) {
    return this.db.list<any>(`/slip/${matchDate}/1X2S3`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getOneXTwoS4SlipList(matchDate: string) {
    return this.db.list<any>(`/slip/${matchDate}/1X2S4`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getOvUnExtractList(matchDate: string) {
    return this.db.list<any>(`/extract/OvUn/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  get1X2S1ExtractList(matchDate: string) {
    return this.db.list<any>(`/extract/1X2S1/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  get1X2S2ExtractList(matchDate: string) {
    return this.db.list<any>(`/extract/1X2S2/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  get1X2S3ExtractList(matchDate: string) {
    return this.db.list<any>(`/extract/1X2S3/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  get1X2S4ExtractList(matchDate: string) {
    return this.db.list<any>(`/extract/1X2S4/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getAnalysisPointsList_Object(matchDate: string) {
    return this.db.object<any>(`/consolidated-analysis-points-sheet/${matchDate}`).valueChanges();
  }

  getGGGNNNExtractList_Object(matchDate: string) {
    return this.db.object<any>(`/extract/GGGNNN/${matchDate}`).valueChanges();
  }

  getGGGNNNSlipList_Object(matchDate: string) {
    return this.db.object<any>(`/slip/${matchDate}/GGGNNN`).valueChanges();
  }

  getOvUnSlipList_Object(matchDate: string) {
    return this.db.object<any>(`/slip/${matchDate}/OvUn`).valueChanges();
  }

  get1X2S1SlipList_Object(matchDate: string) {
    return this.db.object<any>(`/slip/${matchDate}/1X2S1`).valueChanges();
  }

  get1X2S2SlipList_Object(matchDate: string) {
    return this.db.object<any>(`/slip/${matchDate}/1X2S2`).valueChanges();
  }

  get1X2S3SlipList_Object(matchDate: string) {
    return this.db.object<any>(`/slip/${matchDate}/1X2S3`).valueChanges();
  }
  
  get1X2S4SlipList_Object(matchDate: string) {
    return this.db.object<any>(`/slip/${matchDate}/1X2S4`).valueChanges();
  }

  getOvUnExtractList_Object(matchDate: string) {
    return this.db.object<any>(`/extract/OvUn/${matchDate}`).valueChanges();
  }

  get1X2S1ExtractList_Object(matchDate: string) {
    return this.db.object<any>(`/extract/1X2S1/${matchDate}`).valueChanges();
  }

  get1X2S2ExtractList_Object(matchDate: string) {
    return this.db.object<any>(`/extract/1X2S2/${matchDate}`).valueChanges();
  }

  get1X2S3ExtractList_Object(matchDate: string) {
    return this.db.object<any>(`/extract/1X2S3/${matchDate}`).valueChanges();
  }

  get1X2S4ExtractList_Object(matchDate: string) {
    return this.db.object<any>(`/extract/1X2S4/${matchDate}`).valueChanges();
  }

  getSummaryCompetitionList_Object(matchDate: string,seasonId: string) {
    return this.db.object<any>(`/analysis-summary-tournament/${matchDate}/${seasonId}`).valueChanges();
  }

  getDataRequestStatus(matchDate: string) {
    return this.db.object<any>(`/backend-d-tasks/${matchDate}/summary`).valueChanges();
  }

  getEventAnalysisStatus(matchDate: string) {
    return this.db.object<any>(`/backend-a-tasks/${matchDate}/summary`).valueChanges();
  }

  getTeamTablePositionHistoryList(matchDate: string) {
    return this.db.list<any>(`/position-history/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventSquadList(matchDate: string) {
    return this.db.list<any>(`/squad-event/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventH2HList(matchDate: string) {
    return this.db.list<any>(`/h2h-event/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventH2HListByEvent(matchDate: string, eventId: number) {
    return this.db.object<any>(`/h2h/${matchDate}/${eventId}`).valueChanges();
  }

  getHLastX(matchDate: string, id: number) {
    return this.db.object<any>(`/last-x/${matchDate}/${id}`).valueChanges();
  }

  getALastX(matchDate: string, id: number) {
    return this.db.object<any>(`/last-x/${matchDate}/${id}`).valueChanges();
  }

  getHNextX(matchDate: string, id: number) {
    return this.db.object<any>(`/next-x/${matchDate}/${id}`).valueChanges();
  }

  getANextX(matchDate: string, id: number) {
    return this.db.object<any>(`/next-x/${matchDate}/${id}`).valueChanges();
  }

  getTopScorer(matchDate: string, id: number) {
    return this.db.object<any>(`/top-scorer/${matchDate}/${id}`).valueChanges();
  }

  getTopScorerByTeam(matchDate: string, id: number, teamId: number) {
    return this.db.object<any>(`/top-scorer-teams/${matchDate}/${id}/${teamId}`).valueChanges();
  }

  getTopScorerTeamsByTeam(matchDate: string, teamId: number) {
    return this.db.object<any>(`/top-scorer-teams-data/${matchDate}/${teamId}`).valueChanges();
  }

  getTopScorerPointsByEvent(matchDate: string, eventId: number) {
    return this.db.object<any>(`/top-scorer-event-points/${matchDate}/${eventId}`).valueChanges();
  }

  getTopScorerAnalysis(matchDate: string, eventId: number) {
    return this.db.object<any>(`/top-scorer-event-analysis/${matchDate}/${eventId}`).valueChanges();
  }

  getTopAssistByTeam(matchDate: string, id: number, teamId: number) {
    return this.db.object<any>(`/top-assist-teams/${matchDate}/${id}/${teamId}`).valueChanges();
  }

  getTopAssistByTeamData(matchDate: string, id: number, teamId: number) {
    return this.db.object<any>(`/top-assist-teams-data/${matchDate}/${teamId}`).valueChanges();
  }

  getTopAssistByEventPoints(matchDate: string, eventId: number) {
    return this.db.object<any>(`/top-assist-event-points/${matchDate}/${eventId}`).valueChanges();
  }

  getTopAssistByEventAnalysis(matchDate: string, eventId: number) {
    return this.db.object<any>(`/top-assist-event-analysis/${matchDate}/${eventId}`).valueChanges();
  }

  getTopCardsByTeam(matchDate: string, id: number, teamId: number) {
    return this.db.object<any>(`/top-cards-teams/${matchDate}/${id}/${teamId}`).valueChanges();
  }

  getTopCardsByTeamData(matchDate: string, teamId: number) {
    return this.db.object<any>(`/top-cards-teams-data/${matchDate}/${teamId}`).valueChanges();
  }

  getTopCardsByEventPoints(matchDate: string, eventId: number) {
    return this.db.object<any>(`/top-cards-event-points/${matchDate}/${eventId}`).valueChanges();
  }

  getTopCardsByEventAnalysis(matchDate: string, eventId: number) {
    return this.db.object<any>(`/top-cards-event-analysis/${matchDate}/${eventId}`).valueChanges();
  }


  getTopAssist(matchDate: string, id: number) {
    return this.db.object<any>(`/top-assist/${matchDate}/${id}`).valueChanges();
  }

  getSeasonLastXByRound(matchDate: string, id: number, roundId: number) {
    return this.db.list<any>(`/last-x-season-round/${id}/${roundId}`).valueChanges();
  }

  getSeasonLastXPoints(matchDate: string, id: number, roundId: number) {
    return this.db.object<any>(`/season-last-x-points/${matchDate}/${id}`).valueChanges();
  }

  getSeasonLastXRoundList(matchDate: string, id: number) {
    return this.db.list<any>(`/last-x-season-round/${id}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getTopCards(matchDate: string, id: number) {
    return this.db.object<any>(`/top-cards/${matchDate}/${id}`).valueChanges();
  }

  getTable(matchDate: string, id: number) {
    return this.db.object<any>(`/table-two/${matchDate}/${id}`).valueChanges();
  }

  getTableList(matchDate: string, id: number) {
    return this.db.list<any>(`/table-two/${matchDate}/${id}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getTableByTeam(matchDate: string, id: number, teamId: number) {
    return this.db.object<any>(`/season-team-table/${matchDate}/${id}/${teamId}`).valueChanges();
  }

  getTableByEvent(matchDate: string, eventId: number) {
    return this.db.object<any>(`/table-e/${matchDate}/${eventId}`).valueChanges();
  }

  getTableByEventPoints(matchDate: string, eventId: number) {
    return this.db.object<any>(`/table-ep/${matchDate}/${eventId}`).valueChanges();
  }

  getTableByEventAnalysis(matchDate: string, eventId: number) {
    return this.db.object<any>(`/table-analysis/${matchDate}/${eventId}`).valueChanges();
  }

  getEventOdds(matchDate: string, id: number) {
    return this.db.object<any>(`/odds/${matchDate}/${id}`).valueChanges();
  }

  getEventSquad(matchDate: string, id: number) {
    return this.db.object<any>(`/squad-event/${matchDate}/${id}`).valueChanges();
  }

  getCompetitorsList(matchDate: string) {
    return this.db.list<any>(`/fixture/${matchDate}/competitors`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getMaintenanceDataByDate(matchDate: string) {
    return this.db.list<any>(`/d-chk/${matchDate}`).valueChanges();
  }

  getCompetitorsLastXList(matchDate: string) {
    return this.db.list<any>(`/last-x/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitorsNextXList(matchDate: string) {
    return this.db.list<any>(`/next-x/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getCompetitorsFormList(matchDate: string) {
    return this.db.list<any>(`/competitors-form/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventOddsList(matchDate: string) {
    return this.db.list<any>(`/odds/${matchDate}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getBookMakersList() {
    return this.db.list<JackpotBookmaker>(`/jackpot`, (ref) => ref.orderByChild("name"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getBookMakers(bookMakerId: string) {
    return this.db.object<JackpotBookmaker>(`/jackpot/${bookMakerId}`).valueChanges();
  }

  getBookMakersWeeks(bookMakerId: string) {
    return this.db.list<any>(`/jackpot/${bookMakerId}/week`, (ref) => ref.orderByChild("name"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getBookMakersWeeks_Manual(bookMakerId: string) {
    return this.db.list<any>(`/jackpot/${bookMakerId}/week-m`, (ref) => ref.orderByChild("name"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getJackpots(bookMakerId: string, weekNumber: string) {
    return this.db.list<any>(`/jackpot/${bookMakerId}/week/${weekNumber}`, (ref) => ref.orderByChild("name"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getJackpots2(bookMakerId: string, weekNumber: string) {
    return this.db.object<any>(`/jackpot/${bookMakerId}/week/${weekNumber}`).valueChanges();
  }

  updateBookMakersList(bookMakerId: string, bookMakerLoad,) {
    return this.db.object(`/jackpot/${bookMakerId}`).update(bookMakerLoad)
      .then((newMessage) => {

      }).catch((err) => {
        return err;
      });
  }

  updateJackpotList(bookMaker: string, jackpotLoad, weekNumber) {
    return this.db.list(`/jackpot/${bookMaker}/week/${weekNumber}`).push(jackpotLoad)
      .then((newMessage) => {
        console.log("message:", newMessage);
      }).catch((err) => {
        return err;
      });
  }

  getEvent(matchDate: string, competitionId: number) {
    return this.db.object<any>(`/fixture/${matchDate}/events/${competitionId}`).valueChanges();
  }


  getAllCountryByDate(matchDate: string) {
    return this.db.list<Competition>(`/fixture/${matchDate}/country`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }


  getAllCompetitionByDate(matchDate: string) {
    return this.db.list<Competition>(`/fixture/${matchDate}/competition`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getAllSummaryCompetitionByDate(matchDate: string) {
    return this.db.list<Competition>(`/fixture/${matchDate}/competition`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  /* getMatchesByDate(matchDate: string): Observable<Fixtures> {
    return this.db.object<Fixtures>(`/fixture/${matchDate}`).valueChanges();
  } */

  getMatchById(matchDate: string, matchId: number): Observable<Event> {
    return this.db.object<Event>(`/fixture/${matchDate}/event/${matchId}`).valueChanges();
  }

  getDataTaskStatus(matchDate: string): Observable<DataTaskStatus> {
    return this.db.object<DataTaskStatus>(`/backend-d-tasks/${matchDate}/summary`).valueChanges();
  }

  getSeasonDataTaskStatus(matchDate: string): Observable<DataTaskStatus> {
    return this.db.object<DataTaskStatus>(`/backend-season-team-history-tasks/${matchDate}/summary`).valueChanges();
  }

  getAnalysisTaskStatus(matchDate: string): Observable<AnalysisTasksStatus> {
    return this.db.object<AnalysisTasksStatus>(`/backend-a-tasks/${matchDate}/summary`).valueChanges();
  }

  getEventTaskStatus(matchDate: string): Observable<EventTasksStatus> {
    return this.db.object<EventTasksStatus>(`/backgroundTask/fixture/${matchDate}/summary`).valueChanges();
  }

  getMainStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/main/${matchDate}/summary`).valueChanges();
  }

  getEventsResultTaskStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/event-result/${matchDate}/summary`).valueChanges();
  }

  getDataRequestItemStatus(matchDate: string, itemName: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/${itemName}/${matchDate}/summary`).valueChanges();
  }

  getDataAnalysisItemStatus(matchDate: string, itemName: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/${itemName}/${matchDate}/summary`).valueChanges();
  }

  getDataAnalysisResultStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/e-rva/${matchDate}/summary`).valueChanges();
  }

  getDataAnalysisResultTotalStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/e-rva-total/${matchDate}/summary`).valueChanges();
  }

  getExtractUpdateStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/extract-r/${matchDate}/summary`).valueChanges();
  }

  getSlipUpdateStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/slip-r/${matchDate}/summary`).valueChanges();
  }

  getEventReFormatTaskStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backgroundTask/fixtures-reformat/${matchDate}/summary`).valueChanges();
  }

  getConsolidatedTaskStatus(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backend-consolidated-tasks/${matchDate}/summary`).valueChanges();
  }

  getConsolidatedTaskStatusTwo(matchDate: string): Observable<any> {
    return this.db.object<any>(`/backend-consolidated-tasks-two/${matchDate}/summary`).valueChanges();
  }

  getCleanUpTaskStatus(matchDate: string): Observable<CleanUpTasksStatus> {
    return this.db.object<CleanUpTasksStatus>(`/backend-clean-up-tasks/${matchDate}/summary`).valueChanges();
  }





  getCalenderMonthYear2(year: string, month: string) {
    return this.db.object<any>(`/Calendar/${year}/${month}`).valueChanges();
  }

  getCalenderMonthYear(year: string, month: string) {
    return this.db.list<any>(`/Calendar/${year}/${month}`, (query) => {
      return query.orderByKey();
    });
  }

  getCalenderMonthYear3(year: string, month: string) {
    return this.db.list(`/Calendar/${year}/${month}`).valueChanges();
    /* return this.db.list<any>(`/Calendar/${year}/${month}`, (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      ); */
  }

  getCalenderDay(year: string, month: string, date: string) {
    /* return this.db.list(`/Calendar/${year}/${month}`).valueChanges(); */
    return this.db.list<any>(`/Calendar/${year}/${month}`, (ref) => ref
      .orderByChild("date")
      .equalTo(date))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getAllEventsLargeLoadList(date: string) {
    return this.db.list<League2>(`/fixture/${date}/summaries`, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

  getEventLargeLoad(date: string, leagueId: number) {
    return this.db.list<Event>(`/fixture/${date}/summaries/${leagueId}/summaries`, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(c => (Object.assign({}, c.payload.val(), { key: c.payload.key })));
        })
      );
  }

}
