import { Route } from "@angular/router";
import { AnalysisCompetitionListComponent } from './analysis-competition-list/analysis-competition-list.component';
import { AnalysisEventComponent } from './analysis-event/analysis-event.component';
import { AnalysisEventsComponent } from './analysis-events/analysis-events.component';
import { AnalysisGggnnnListComponent } from './analysis-gggnnn-list/analysis-gggnnn-list.component';
import { AnalysisH2hListComponent } from './analysis-h2h-list/analysis-h2h-list.component';
import { AnalysisH2hComponent } from './analysis-h2h/analysis-h2h.component';
import { AnalysisLastXListComponent } from './analysis-last-x-list/analysis-last-x-list.component';
import { AnalysisLastXComponent } from './analysis-last-x/analysis-last-x.component';
import { AnalysisListComponent } from './analysis-list/analysis-list.component';
import { AnalysisNextXComponent } from './analysis-next-x/analysis-next-x.component';
import { AnalysisOnextwoS1ListComponent } from './analysis-onextwo-s1-list/analysis-onextwo-s1-list.component';
import { AnalysisOnextwoS2ListComponent } from './analysis-onextwo-s2-list/analysis-onextwo-s2-list.component';
import { AnalysisOnextwoS3ListComponent } from './analysis-onextwo-s3-list/analysis-onextwo-s3-list.component';
import { AnalysisOnextwoS4ListComponent } from './analysis-onextwo-s4-list/analysis-onextwo-s4-list.component';
import { AnalysisOvUnListComponent } from './analysis-ov-un-list/analysis-ov-un-list.component';
import { AnalysisSeasonLastXListComponent } from './analysis-season-last-x-list/analysis-season-last-x-list.component';
import { AnalysisSeasonLastXComponent } from './analysis-season-last-x/analysis-season-last-x.component';
import { AnalysisSeasonTableListComponent } from './analysis-season-table-list/analysis-season-table-list.component';
import { AnalysisSeasonTableComponent } from './analysis-season-table/analysis-season-table.component';
import { AnalysisSlipGggnnnListComponent } from './analysis-slip-gggnnn-list/analysis-slip-gggnnn-list.component';
import { AnalysisSlipOnextwoS1ListComponent } from './analysis-slip-onextwo-s1-list/analysis-slip-onextwo-s1-list.component';
import { AnalysisSlipOnextwoS2ListComponent } from './analysis-slip-onextwo-s2-list/analysis-slip-onextwo-s2-list.component';
import { AnalysisSlipOnextwoS3ListComponent } from './analysis-slip-onextwo-s3-list/analysis-slip-onextwo-s3-list.component';
import { AnalysisSlipOnextwoS4ListComponent } from './analysis-slip-onextwo-s4-list/analysis-slip-onextwo-s4-list.component';
import { AnalysisSlipOvUnListComponent } from './analysis-slip-ov-un-list/analysis-slip-ov-un-list.component';
import { AnalysisTopAssistListComponent } from './analysis-top-assist-list/analysis-top-assist-list.component';
import { AnalysisTopAssistComponent } from './analysis-top-assist/analysis-top-assist.component';
import { AnalysisTopCardsListComponent } from './analysis-top-cards-list/analysis-top-cards-list.component';
import { AnalysisTopCardsComponent } from './analysis-top-cards/analysis-top-cards.component';
import { AnalysisTopScorerListComponent } from './analysis-top-scorer-list/analysis-top-scorer-list.component';
import { AnalysisTopScorerComponent } from './analysis-top-scorer/analysis-top-scorer.component';
import { AnalysisTotalsComponent } from './analysis-totals/analysis-totals.component';
import { MenuItem } from "../layouts/shared/models/menu.model";

export const ANALYSIS_MENU: MenuItem[] = [
  { key: 'ds-analysis-event', isTitle: false, label: 'Events', link: '/analysis/analysis-event', icon: 'uil-home-alt' },
  { key: 'ds-analysis-list', isTitle: false, label: 'Compiled', link: '/analysis/analysis-list', icon: 'uil-home-alt' },
  { key: 'ds-analysis-competition', isTitle: false, label: 'Compiled Competition', link: '/analysis/analysis-competition', icon: 'uil-home-alt' },

  { key: 'ds-analysis-h2h', isTitle: false, label: 'H2H Analysis', link: '/analysis/analysis-h2h', icon: 'uil-home-alt' },
  { key: 'ds-analysis-last-x', isTitle: false, label: 'Event Last X Analysis', link: '/analysis/analysis-last-x', icon: 'uil-home-alt' },
  { key: 'ds-analysis-season-last-x', isTitle: false, label: 'Season Last X Analysis', link: '/analysis/analysis-season-last-x', icon: 'uil-home-alt' },
  { key: 'ds-analysis-season-table', isTitle: false, label: 'Table Analysis', link: '/analysis/analysis-season-table', icon: 'uil-home-alt' },
  { key: 'ds-analysis-top-cards', isTitle: false, label: 'Top Card Analysis', link: '/analysis/analysis-top-cards', icon: 'uil-home-alt' },
  { key: 'ds-analysis-top-scorer', isTitle: false, label: 'Top Scorer Analysis', link: '/analysis/analysis-top-scorer', icon: 'uil-home-alt' },
  { key: 'ds-analysis-top-assist', isTitle: false, label: 'Top Assist Analysis', link: '/analysis/analysis-top-assist', icon: 'uil-home-alt' },

  { key: 'ds-analysis-gggnnn', isTitle: false, label: 'GG/GN/NN', link: '/analysis/analysis-gggnnn', icon: 'uil-home-alt' },
  { key: 'ds-analysis-ov-un', isTitle: false, label: 'Ov/Un', link: '/analysis/analysis-ov-un', icon: 'uil-home-alt' },
  { key: 'ds-analysis-1x2-s1', isTitle: false, label: '1X2 S1', link: '/analysis/analysis-1x2-s1', icon: 'uil-home-alt' },
  { key: 'ds-analysis-1x2-s2', isTitle: false, label: '1X2 S2', link: '/analysis/analysis-1x2-s2', icon: 'uil-home-alt' },
  { key: 'ds-analysis-1x2-s3', isTitle: false, label: '1X2 S3', link: '/analysis/analysis-1x2-s3', icon: 'uil-home-alt' },
  { key: 'ds-analysis-1x2-s4', isTitle: false, label: '1X2 S4', link: '/analysis/analysis-1x2-s4', icon: 'uil-home-alt' },

  { key: 'ds-slip-gggnnn', isTitle: false, label: 'GG/GN/NN', link: '/analysis/slip-gggnnn', icon: 'uil-home-alt' },
  { key: 'ds-slip-ov-un', isTitle: false, label: 'Ov/Un', link: '/analysis/slip-ov-un', icon: 'uil-home-alt' },
  { key: 'ds-slip-1x2-s1', isTitle: false, label: '1X2 S1', link: '/analysis/slip-1x2-s1', icon: 'uil-home-alt' },
  { key: 'ds-slip-1x2-s2', isTitle: false, label: '1X2 S2', link: '/analysis/slip-1x2-s2', icon: 'uil-home-alt' },
  { key: 'ds-slip-1x2-s3', isTitle: false, label: '1X2 S3', link: '/analysis/slip-1x2-s3', icon: 'uil-home-alt' },
  { key: 'ds-slip-1x2-s4', isTitle: false, label: '1X2 S4', link: '/analysis/slip-1x2-s4', icon: 'uil-home-alt' },

  { key: 'ds-analysis-totals', isTitle: false, label: 'Totals', link: '/analysis/analysis-totals', icon: 'uil-home-alt' },
]

export const ANALYSIS_ROUTES: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'analysis-event'
    },
    {
        path: 'analysis-event',
        children: [
          { path: '', component: AnalysisEventsComponent, },
          { path: ':id1', component: AnalysisEventComponent, },
        ]
      },
      {
        path: 'analysis-list',
        children: [
          { path: '', component: AnalysisListComponent, },
        ]
      },
      {
        path: 'analysis-competition',
        children: [
          { path: '', component: AnalysisCompetitionListComponent, },
        ]
      },
      {
        path: 'analysis-h2h',
        children: [
          { path: '', component: AnalysisH2hListComponent, },
          { path: ':id1', component: AnalysisH2hComponent, },
        ]
      },
      {
        path: 'analysis-last-x',
        children: [
          { path: '', component: AnalysisLastXListComponent, },
          { path: ':id1', component: AnalysisLastXComponent, },
        ]
      },
      {
        path: 'analysis-next-x',
        children: [
          { path: ':id1', component: AnalysisNextXComponent, },
        ]
      },
      {
        path: 'analysis-top-scorer',
        children: [
          { path: '', component: AnalysisTopScorerListComponent, },
          { path: ':id1', component: AnalysisTopScorerComponent, },
        ]
      },
      {
        path: 'analysis-top-assist',
        children: [
          { path: '', component: AnalysisTopAssistListComponent, },
          { path: ':id1', component: AnalysisTopAssistComponent, },
        ]
      },
      {
        path: 'analysis-top-cards',
        children: [
          { path: '', component: AnalysisTopCardsListComponent, },
          { path: ':id1', component: AnalysisTopCardsComponent, },
        ]
      },
      {
        path: 'analysis-season-table',
        children: [
          { path: '', component: AnalysisSeasonTableListComponent, },
          { path: ':id1', component: AnalysisSeasonTableComponent, },
        ]
      },
      {
        path: 'analysis-season-last-x',
        children: [
          { path: '', component: AnalysisSeasonLastXListComponent, },
          { path: ':id1', component: AnalysisSeasonLastXComponent, },
        ]
      },
      {
        path: 'analysis-gggnnn',
        children: [
          { path: '', component: AnalysisGggnnnListComponent, },
        ]
      },
      {
        path: 'analysis-ov-un',
        children: [
          { path: '', component: AnalysisOvUnListComponent, },
        ]
      },
      {
        path: 'analysis-1x2-s1',
        children: [
          { path: '', component: AnalysisOnextwoS1ListComponent, },
        ]
      },
      {
        path: 'analysis-1x2-s2',
        children: [
          { path: '', component: AnalysisOnextwoS2ListComponent, },
        ]
      },
      {
        path: 'analysis-1x2-s3',
        children: [
          { path: '', component: AnalysisOnextwoS3ListComponent, },
        ]
      },
      {
        path: 'analysis-1x2-s4',
        children: [
          { path: '', component: AnalysisOnextwoS4ListComponent, },
        ]
      },

      {
        path: 'slip-gggnnn',
        children: [
          { path: '', component: AnalysisSlipGggnnnListComponent, },
        ]
      },
      {
        path: 'slip-ov-un',
        children: [
          { path: '', component: AnalysisSlipOvUnListComponent, },
        ]
      },
      {
        path: 'slip-1x2-s1',
        children: [
          { path: '', component: AnalysisSlipOnextwoS1ListComponent, },
        ]
      },
      {
        path: 'slip-1x2-s2',
        children: [
          { path: '', component: AnalysisSlipOnextwoS2ListComponent, },
        ]
      },
      {
        path: 'slip-1x2-s3',
        children: [
          { path: '', component: AnalysisSlipOnextwoS3ListComponent, },
        ]
      },
      {
        path: 'slip-1x2-s4',
        children: [
          { path: '', component: AnalysisSlipOnextwoS4ListComponent, },
        ]
      },
      {
        path: 'analysis-totals',
        children: [
          { path: '', component: AnalysisTotalsComponent, },
        ]
      },
];