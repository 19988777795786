<!-- team intro -->
<div class="d-inline-flex my-md-3">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- next X -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="homeNextX || awayNextX">
    <h4 class="display-6 fw-normal mb-0">Next X</h4>
</div>
<div class="d-inline-flex flex-md-equal w-100 my-md-3 fw-light">
    <div class="col-6 bg-light text-center" *ngIf="homeNextX">
        <table class="table my-md-3 bg-light table-borderless table-sm text-center">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Home</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Away</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let m of homeNextX.matches;let i = index;">
                    <td scope="col">
                        <span *ngIf="m.time"><span *ngIf="m.time.date">{{m.time.date}}</span></span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams"><span *ngIf="m.teams.home"><span
                                    *ngIf="m.teams.home.mediumname">{{m.teams.home.mediumname}}</span></span></span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.result"><span *ngIf="m.result.home">{{m.result.home}}</span></span>
                    </td>
                    <td scope="col">:</td>
                    <td scope="col">
                        <span *ngIf="m.result"><span *ngIf="m.result.away">{{m.result.away}}</span></span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams"><span *ngIf="m.teams.away"><span
                                    *ngIf="m.teams.away.mediumname">{{m.teams.away.mediumname}}</span></span></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-6 bg-light text-center" *ngIf="awayNextX">
        <table class="table my-md-3 bg-light table-borderless table-sm text-center">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Home</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Away</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let m of awayNextX.matches;let i = index;">
                    <td scope="col">
                        <span *ngIf="m.time"><span *ngIf="m.time.date">{{m.time.date}}</span></span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams"><span *ngIf="m.teams.home"><span
                                    *ngIf="m.teams.home.mediumname">{{m.teams.home.mediumname}}</span></span></span>
                    </td>
                    <td scope="col">
                        <span *ngIf="m.result"><span *ngIf="m.result.home">{{m.result.home}}</span></span>
                        {{m.result.home}}
                    </td>
                    <td scope="col">:</td>
                    <td scope="col">
                        <span *ngIf="m.result"><span *ngIf="m.result.away">{{m.result.away}}</span></span>
                        {{m.result.away}}
                    </td>
                    <td scope="col">
                        <span *ngIf="m.teams"><span *ngIf="m.teams.away"><span
                                    *ngIf="m.teams.away.mediumname">{{m.teams.away.mediumname}}</span></span></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>