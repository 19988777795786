<!-- team intro -->
<div class="d-inline-flex my-md-3" *ngIf="matchEvent">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span
                    *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- topAssist -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="topAssist && topAssist.players">
    <h4 class="display-6 fw-normal mb-0">Season Top Assist</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light"
    *ngIf="topAssist && topAssist.players">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Nationality</th>
            <th scope="col">Position</th>
            <th scope="col">Matches</th>
            <th scope="col">Assist</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topAssist.players;let i = index;">
            <td scope="col">{{i+1}}</td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.player.name">{{t.player.name}}</span><br>
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId]"
                        *ngIf="t.teams[matchEvent.homeTeamId]">{{matchEvent.homeTeamNameLong}}</span>
                    <span [class.fw-bold]="t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.teams[matchEvent.awayTeamId]">{{matchEvent.awayTeamNameLong}}</span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span *ngIf="t.player.nationality">
                        <span
                            [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                            *ngIf="t.player.nationality.name">{{t.player.nationality.name}}</span>
                    </span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span *ngIf="t.player.position">
                        <span
                            [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                            *ngIf="t.player.position.abbr">{{t.player.position.abbr}}</span>
                    </span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.matches">{{t.total.matches}}</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.assists">{{t.total.assists}}</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topAssist Home Team-->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchEvent && topAssistHT">
    <h4 class="display-6 fw-normal mb-0">
        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
    </h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistHT">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Nationality</th>
            <th scope="col">Position</th>
            <th scope="col">Matches</th>
            <th scope="col">Assist</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topAssistHT|keyvalue;let i = index;">
            <td scope="col">
                <span *ngIf="topAssistHT[t.key].topAssistPosition">
                    {{topAssistHT[t.key].topAssistPosition}}
                </span>
            </td>
            <td scope="col">
                    <span *ngIf="topAssistHT[t.key].playerName">{{topAssistHT[t.key].playerName}}</span><br>
                <span *ngIf="!topAssistHT[t.key].playerName">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHT[t.key].nationality">
                    {{topAssistHT[t.key].nationality}}
                </span>
                <span *ngIf="!topAssistHT[t.key].nationality">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHT[t.key].position">{{topAssistHT[t.key].position}}</span>
                <span *ngIf="!topAssistHT[t.key].position">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHT[t.key].matches">{{topAssistHT[t.key].matches}}</span>
                <span *ngIf="!topAssistHT[t.key].matches">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHT[t.key].assists">{{topAssistHT[t.key].assists}}</span>
                <span *ngIf="!topAssistHT[t.key].assists">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topAssist Home Team Data-->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistHTData">
    <thead>
        <tr>
            <th scope="col">Player Count</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Highest Rank</th>
            <th scope="col">Ranks</th>
            <th scope="col">Matches</th>
            <th scope="col">Assists</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span *ngIf="topAssistHTData.assistPlayerCount">
                    {{topAssistHTData.assistPlayerCount}}
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHTData.assistName">
                    <span *ngFor="let t of topAssistHTData.assistName">
                        <span>{{t}}</span><br>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHTData.assistPosition">
                    <span *ngFor="let t of topAssistHTData.assistPosition">
                        <span>{{t}}</span>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHTData.highestAssistPosition">
                    {{topAssistHTData.highestAssistPosition}}
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHTData.assistId">
                    <span *ngFor="let t of topAssistHTData.assistId">
                        <span>{{t}}</span><br>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHTData.cumulativeMatches">
                    {{topAssistHTData.cumulativeMatches}}
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistHTData.cumulativeAssist">
                    {{topAssistHTData.cumulativeAssist}}
                </span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topAssist Away Team-->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchEvent && topAssistAT">
    <h4 class="display-6 fw-normal mb-0">
        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
    </h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistAT">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Nationality</th>
            <th scope="col">Position</th>
            <th scope="col">Matches</th>
            <th scope="col">Assist</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topAssistAT|keyvalue;let i = index;">
            <td scope="col">
                <span *ngIf="topAssistAT[t.key].topAssistPosition">
                    {{topAssistAT[t.key].topAssistPosition}}
                </span>
            </td>
            <td scope="col">
                    <span *ngIf="topAssistAT[t.key].playerName">{{topAssistAT[t.key].playerName}}</span><br>
                <span *ngIf="!topAssistAT[t.key].playerName">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistAT[t.key].nationality">
                    {{topAssistAT[t.key].nationality}}
                </span>
                <span *ngIf="!topAssistAT[t.key].nationality">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistAT[t.key].position">{{topAssistAT[t.key].position}}</span>
                <span *ngIf="!topAssistAT[t.key].position">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistAT[t.key].matches">{{topAssistAT[t.key].matches}}</span>
                <span *ngIf="!topAssistAT[t.key].matches">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistAT[t.key].assists">{{topAssistAT[t.key].assists}}</span>
                <span *ngIf="!topAssistAT[t.key].assists">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topAssist Away Team Data-->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistATData">
    <thead>
        <tr>
            <th scope="col">Player Count</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Highest Rank</th>
            <th scope="col">Ranks</th>
            <th scope="col">Matches</th>
            <th scope="col">Assists</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span *ngIf="topAssistATData.assistPlayerCount">
                    {{topAssistATData.assistPlayerCount}}
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistATData.assistName">
                    <span *ngFor="let t of topAssistATData.assistName">
                        <span>{{t}}</span><br>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistATData.assistPosition">
                    <span *ngFor="let t of topAssistATData.assistPosition">
                        <span>{{t}}</span>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistATData.highestAssistPosition">
                    {{topAssistATData.highestAssistPosition}}
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistATData.assistId">
                    <span *ngFor="let t of topAssistATData.assistId">
                        <span>{{t}}</span><br>
                    </span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistATData.cumulativeMatches">
                    {{topAssistATData.cumulativeMatches}}
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistATData.cumulativeAssist">
                    {{topAssistATData.cumulativeAssist}}
                </span>
            </td>
        </tr>
    </tbody>
</table>

<!-- points -->
<!-- Top Scorer Points -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="topAssistPoints">
    <h4 class="display-6 fw-normal mb-0">Points</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistPoints">
    <caption>Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Assists</th>
            <th scope="col">Assists Per Game</th>
            <th scope="col">Player Count</th>
            <th scope="col">Player Rank</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.tAAssistAdvantage">
                    {{topAssistPoints.tAAssistAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.tAAssistPerGameAdvantage">
                    {{topAssistPoints.tAAssistPerGameAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.tAAssistPlayerCountAdvantage">
                    {{topAssistPoints.tAAssistPlayerCountAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.tAAssistPlayerRankAdvantage">
                    {{topAssistPoints.tAAssistPlayerRankAdvantage}}
                </div>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistPoints">
    <caption>Cumulative Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col">Neutral</th>
            <th scope="col">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Count</span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistPoints.hAdvantageCount">
                    {{topAssistPoints.hAdvantageCount}}<br>
                </span>
                <span *ngIf="topAssistPoints.hAdvantageCountPercentage">
                    {{topAssistPoints.hAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topAssistPoints.hAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!topAssistPoints.hAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistPoints.neutralAdvantageCount">
                    {{topAssistPoints.neutralAdvantageCount}}<br>
                </span>
                <span *ngIf="topAssistPoints.neutralAdvantageCountPercentage">
                    {{topAssistPoints.neutralAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topAssistPoints.neutralAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!topAssistPoints.neutralAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topAssistPoints.aAdvantageCount">
                    {{topAssistPoints.aAdvantageCount}}<br>
                </span>
                <span *ngIf="!topAssistPoints.aAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="topAssistPoints.aAdvantageCountPercentage">
                    {{topAssistPoints.aAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topAssistPoints.aAdvantageCountPercentage">
                    0%
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topAssistPoints">
    <caption>Conclusion</caption>
    <thead>
        <tr>
            <th scope="col">Greater Than</th>
            <th scope="col">50%</th>
            <th scope="col">60%</th>
            <th scope="col">70%</th>
            <th scope="col">80%</th>
            <th scope="col">90%</th>
            <th scope="col">100%</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.advantage51">
                    {{topAssistPoints.advantage51}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.advantage60">
                    {{topAssistPoints.advantage60}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.advantage70">
                    {{topAssistPoints.advantage70}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.advantage80">
                    {{topAssistPoints.advantage80}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.advantage90">
                    {{topAssistPoints.advantage90}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topAssistPoints.advantage100">
                    {{topAssistPoints.advantage100}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

<!-- top assist analysis -->
<table class="table table-sm flex-fill" *ngIf="topAssistAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} Vs<br></span>
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                </span>
            </th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                </span>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="
    topAssistAnalysis.cumulativeAssistDiffHomePercentage01 || 
    topAssistAnalysis.cumulativeAssistDiffHomePercentage20 ||
    topAssistAnalysis.cumulativeAssistDiffHomePercentage30 ||
    topAssistAnalysis.cumulativeAssistDiffHomePercentage40 ||
    topAssistAnalysis.cumulativeAssistDiffHomePercentage50 ||
    topAssistAnalysis.cumulativeAssistDiffAwayPercentage01 || 
    topAssistAnalysis.cumulativeAssistDiffAwayPercentage20 ||
    topAssistAnalysis.cumulativeAssistDiffAwayPercentage30 ||
    topAssistAnalysis.cumulativeAssistDiffAwayPercentage40 ||
    topAssistAnalysis.cumulativeAssistDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Cumulative Assists</td>
            <td class="time text-start w-25">
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topAssistAnalysis.cumulativeAssistDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topAssistAnalysis.playerCountDiffHomePercentage01 || 
    topAssistAnalysis.playerCountDiffHomePercentage20 ||
    topAssistAnalysis.playerCountDiffHomePercentage30 ||
    topAssistAnalysis.playerCountDiffHomePercentage40 ||
    topAssistAnalysis.playerCountDiffHomePercentage50 ||
    topAssistAnalysis.playerCountDiffAwayPercentage01 || 
    topAssistAnalysis.playerCountDiffAwayPercentage20 ||
    topAssistAnalysis.playerCountDiffAwayPercentage30 ||
    topAssistAnalysis.playerCountDiffAwayPercentage40 ||
    topAssistAnalysis.playerCountDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>APlayer Count</td>
            <td class="time text-start w-25">
                <span *ngIf="topAssistAnalysis.playerCountDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topAssistAnalysis.playerCountDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topAssistAnalysis.playerCountDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points -->
<table class="table table-sm flex-fill" *ngIf="topAssistAnalysisPoints$">
    <caption>
        <Span>Key<br></Span>
        <Span><br></Span>
        <Span>1 :    Assist Player Count<br></Span>
        <Span>2 :    Assist Count<br></Span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">Result 1X2</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="topAssistAnalysisPoints$.playerCountAdvantage">{{topAssistAnalysisPoints$.playerCountAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topAssistAnalysisPoints$.cumulativeAssistAdvantage">{{topAssistAnalysisPoints$.cumulativeAssistAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span></span>
            </td>
        </tr>
    </tbody>
</table>