import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Competition } from 'src/app/core/interface/competition';
import { DateTime } from 'src/app/core/interface/date-time';
import { ApiService } from 'src/app/core/service/api.service';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';

@Component({
  selector: 'app-analysis-slip-onextwo-s2-list',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './analysis-slip-onextwo-s2-list.component.html',
  styleUrls: ['./analysis-slip-onextwo-s2-list.component.scss']
})
export class AnalysisSlipOnextwoS2ListComponent implements OnInit, OnDestroy {
  id: any;
  dateForm: FormGroup;
  /* dateToday: string; */
  subscription: Subscription = new Subscription();
  todayData: any;
  dates = [];
  datePicked: string;
  requestClick: boolean;
  date$: string;
  isSearchMode: boolean = false;
  oneXTwoS2List$: any[];
  filteredOneXTwoS2List$: any[];
  oneXTwoS2Object$: any;

  constructor(
    private footballService: FootballService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('id1');
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.checkDataAvailableToday();
  }
  checkDataAvailableToday() {
    /* get date today */
    /* check if events today are more than 1 */
    /* date today add day six times while checking more than 1 event */
    /* display date boxes green/red */

    this.subscription.add(this.dateTimeService.getCurrentDate().pipe(first()).subscribe(
      (dateTime: DateTime) => {
        this.date$ = dateTime.date;
      }));
  }

  initializeForm() {
    this.dateForm = this.fb.group({
      date: [0],
    });

    this.dateForm.get("date").valueChanges.subscribe(x => {
      this.requestClick = false;

      let day = x.slice(8, 10);
      let month = x.slice(5, 7);
      let year = x.slice(0, 4);
      let date = `${year}-${month}-${day}`;
      this.datePicked = date;

      this.subscription.add(this.footballService.getOneXTwoS2SlipList(date).pipe(first())
        .subscribe((oneXTwoS2List) => {
          //this.responses$ = response;
          this.filteredOneXTwoS2List$ = this.oneXTwoS2List$ = oneXTwoS2List;
        }))
        this.subscription.add(this.footballService.get1X2S2SlipList_Object(date).pipe(first())
        .subscribe((oneXTwoS2Ob) => {
          //this.responses$ = response;
          this.oneXTwoS2Object$ = oneXTwoS2Ob;
        }))
    })
  }

  edit(countryId: string) {

  }

  filter(query) {
    if (query === "" || !query) {
      this.isSearchMode = false;
    } else {
      this.isSearchMode = true;
    }

    this.filteredOneXTwoS2List$ = (query) ?
      this.oneXTwoS2List$.filter(c => (c.homeTeamNameLong + " " + c.awayTeamNameLong).toLowerCase().includes(query.toLowerCase())) :
      this.oneXTwoS2List$;
  }

  redirect(eDate, eventKey: number | string) {
    //Create interest data for date
    //Record Click ${date}/interest/${gameId}
    //*Note Time ZOne Matters!!!
    if (eDate && eventKey) {
      this.router.navigate([`/football/match/${eventKey}`], { queryParams: { "date": eDate } });
    }
  }

  excludeCompetition(seasonId: any) {
    console.log(`excludeCompetition - clicked`);
    if (this.datePicked) {
      console.log(`this.datePicked - ${this.datePicked}`);
      this.subscription.add(this.apiService.excludeCompetition(this.datePicked, seasonId).subscribe(
        (result) => {
          if (result) {
            console.log("result:", result);
            return;
          }
        }))
    }
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}




