// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDeA6G7aGgNgQmHBHvsRL5JNBd-8XqTG7c",
    authDomain: "football-data-two.firebaseapp.com",
    databaseURL: "https://football-data-two-default-rtdb.firebaseio.com",
    projectId: "football-data-two",
    storageBucket: "football-data-two.appspot.com",
    messagingSenderId: "840608266030",
    appId: "1:840608266030:web:80181efbe60046c3682cbf",
    measurementId: "G-ZEWGTM1MCB"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
