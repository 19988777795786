export const environment = {
  firebase: {
    apiKey: "AIzaSyDeA6G7aGgNgQmHBHvsRL5JNBd-8XqTG7c",
    authDomain: "football-data-two.firebaseapp.com",
    databaseURL: "https://football-data-two-default-rtdb.firebaseio.com",
    projectId: "football-data-two",
    storageBucket: "football-data-two.appspot.com",
    messagingSenderId: "840608266030",
    appId: "1:840608266030:web:80181efbe60046c3682cbf",
    measurementId: "G-ZEWGTM1MCB"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
