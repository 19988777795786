<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">Last X Analysis Points</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Last X analysis points against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>

<div *ngIf="!isSearchMode">
    <div *ngIf="lastXPointsObject$">
        <table class="table table-sm flex-fill" *ngFor="let competition of competition$;let i = index">
            <thead>
                <tr class="small-tx">
                    <h5>
                        <span class="fw-bold fst-italic"
                            *ngIf="competition.competitionName">{{competition.competitionName}}<br></span>
                        <span class="fst-italic" *ngIf="competition.countryName">{{competition.countryName}}</span>
                    </h5>
                </tr>
            </thead>
            <thead class="table-light">
                <tr class="small-tx">
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Ov/Un</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">GG/GN/NN</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Result</th>
                    <th class="teams text-start"></th>
                </tr>
            </thead>
            <thead class="table-light">
                <tr class="small-tx">
                    <th class="teams text-start">Match</th>
                    <th class="teams text-start">1</th>
                    <th class="teams text-start">2</th>
                    <th class="teams text-start">3</th>
                    <th class="teams text-start">4</th>
                    <th class="teams text-start">5</th>
                    <th class="teams text-start">6</th>
                    <th class="teams text-start">Adv</th>
                    <th class="teams text-start">0.5</th>
                    <th class="teams text-start">1.5</th>
                    <th class="teams text-start">2.5</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Result 1X2</th>
                    <th class="teams text-start">Result Ov/Un</th>
                </tr>
            </thead>
            <tbody *ngIf="eventObject">
                <tr class="cursor-pointer status-live small-tx"
                    *ngFor="let event of (competition.events | keyvalue);let i = index;">
                    <td class="time text-start">
                        <div class="row text-bold">
                            <div class="col-xs" *ngIf="eventObject[event.key]"
                                [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                                <span
                                    *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                    Vs</span><br>
                                <span
                                    *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                [class]="lastXPointsObject$[event.key].hAScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].hAScoredAdvantage">{{lastXPointsObject$[event.key].hAScoredAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                [class]="lastXPointsObject$[event.key].hAConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].hAConcededAdvantage">{{lastXPointsObject$[event.key].hAConcededAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                [class]="lastXPointsObject$[event.key].hAWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].hAWinDrawLossAdvantage">{{lastXPointsObject$[event.key].hAWinDrawLossAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                [class]="lastXPointsObject$[event.key].homeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].homeHomeAwayAwayScoredAdvantage">{{lastXPointsObject$[event.key].homeHomeAwayAwayScoredAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                [class]="lastXPointsObject$[event.key].homeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].homeHomeAwayAwayConcededAdvantage">{{lastXPointsObject$[event.key].homeHomeAwayAwayConcededAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                [class]="lastXPointsObject$[event.key].homeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].homeHomeAwayAwayWinDrawLossAdvantage">{{lastXPointsObject$[event.key].homeHomeAwayAwayWinDrawLossAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span [class]="lastXPointsObject$[event.key].adv1X2CountAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].adv1X2CountAdvantage">{{lastXPointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                            <span *ngIf="lastXPointsObject$[event.key].adv1Count">1 - {{lastXPointsObject$[event.key].adv1Count}}
                                <span *ngIf="lastXPointsObject$[event.key].adv1CountPercentage">({{lastXPointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                            </span>
                            <span *ngIf="lastXPointsObject$[event.key].advXCount">X - {{lastXPointsObject$[event.key].advXCount}}
                                <span *ngIf="lastXPointsObject$[event.key].advXCountPercentage">({{lastXPointsObject$[event.key].advXCountPercentage}}%)<br></span>
                            </span>
                            <span *ngIf="lastXPointsObject$[event.key].adv2Count">2 - {{lastXPointsObject$[event.key].adv2Count}}
                                <span *ngIf="lastXPointsObject$[event.key].adv2CountPercentage">({{lastXPointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                            </span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span [class]="lastXPointsObject$[event.key].Ov05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].Ov05">Ov0.5<br></span></span>
                                    <span [class]="lastXPointsObject$[event.key].Un05Rslt?'green-bd':'red-bg'"><span
                                        *ngIf="lastXPointsObject$[event.key].Un05">Un0.5</span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span [class]="lastXPointsObject$[event.key].Ov15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].Ov15">Ov1.5<br></span></span>
                                    <span [class]="lastXPointsObject$[event.key].Un15Rslt?'green-bd':'red-bg'"><span
                                        *ngIf="lastXPointsObject$[event.key].Un15">Un1.5</span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span [class]="lastXPointsObject$[event.key].Ov25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].Ov25">Ov2.5<br></span></span>
                                    <span [class]="lastXPointsObject$[event.key].Un25Rslt?'green-bd':'red-bg'"><span
                                        *ngIf="lastXPointsObject$[event.key].Un25">Un2.5</span></span>
                                </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span [class]="lastXPointsObject$[event.key].homeHomeAwayAwayGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].homeHomeAwayAwayGG">GG<br></span></span>
                            <span [class]="lastXPointsObject$[event.key].homeHomeAwayAwayGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].homeHomeAwayAwayGN">GN<br></span></span>
                            <span [class]="lastXPointsObject$[event.key].homeHomeAwayAwayNNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].homeHomeAwayAwayNN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span [class]="lastXPointsObject$[event.key].hAGGAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].hAGG">GG<br></span></span>
                            <span [class]="lastXPointsObject$[event.key].hAGNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].hAGN">GN<br></span></span>
                            <span [class]="lastXPointsObject$[event.key].hANNAdvRslt?'green-bd':'red-bg'"><span
                                    *ngIf="lastXPointsObject$[event.key].hANN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span
                                *ngIf="lastXPointsObject$[event.key].result1X2">{{lastXPointsObject$[event.key].result1X2}}</span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="lastXPointsObject$[event.key]">
                            <span *ngIf="lastXPointsObject$[event.key].resultOv25">Ov2.5</span>
                            <span *ngIf="!lastXPointsObject$[event.key].resultOv25">
                                <span *ngIf="lastXPointsObject$[event.key].resultOv15">Ov1.5</span>
                                <span *ngIf="!lastXPointsObject$[event.key].resultOv15">
                                    <span *ngIf="lastXPointsObject$[event.key].resultOv05">Ov0.5</span>
                                </span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill" *ngIf="eventObject">
        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">0.5</th>
                <th class="teams text-start">1.5</th>
                <th class="teams text-start">2.5</th>
                <th class="teams text-start">Result 1X2</th>
                <th class="teams text-start">Result Ov/Un</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx" *ngFor="let event of lastXAnalysisPoints$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>
                <td class="teams text-start">
                    <span [class]="event.hAScoredAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.hAScoredAdvantage">{{event.hAScoredAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.hAConcededAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.hAConcededAdvantage">{{event.hAConcededAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.hAWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.hAWinDrawLossAdvantage">{{event.hAWinDrawLossAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.homeHomeAwayAwayScoredAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.homeHomeAwayAwayScoredAdvantage">{{event.homeHomeAwayAwayScoredAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.homeHomeAwayAwayConcededAdvantageRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.homeHomeAwayAwayConcededAdvantage">{{event.homeHomeAwayAwayConcededAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.homeHomeAwayAwayWinDrawLossAdvRslt?'green-bd':'red-bg'"><span
                            *ngIf="event.homeHomeAwayAwayWinDrawLossAdvantage">{{event.homeHomeAwayAwayWinDrawLossAdvantage}}</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.Ov05Rslt?'green-bd':'red-bg'">
                        <span *ngIf="event.Ov05">Ov0.5<br></span></span>
                        <span [class]="event.Un05Rslt?'green-bd':'red-bg'">
                            <span *ngIf="event.Un05">Un0.5</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.Ov15Rslt?'green-bd':'red-bg'">
                        <span *ngIf="event.Ov15">Ov1.5<br></span></span>
                        <span [class]="event.Un15Rslt?'green-bd':'red-bg'">
                            <span *ngIf="event.Un15">Un1.5</span></span>
                </td>
                <td class="teams text-start">
                    <span [class]="event.Ov25Rslt?'green-bd':'red-bg'">
                        <span *ngIf="event.Ov25">Ov2.5<br></span></span>
                        <span [class]="event.Un25Rslt?'green-bd':'red-bg'">
                            <span *ngIf="event.Un25">Un2.5</span></span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.resultOv25">Ov2.5</span>
                    <span *ngIf="!event.resultOv25">
                        <span *ngIf="event.resultOv15">Ov1.5</span>
                        <span *ngIf="!event.resultOv15">
                            <span *ngIf="event.resultOv05">Ov0.5</span>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>Home Vs Away (Cumulative)<br></Span>
    <Span>1 : Scored<br></Span>
    <Span>2 : Conceded<br></Span>
    <Span>3 : Win Loss Draw<br></Span>
    <Span>Home Team at Home Vs Away Team at Away<br></Span>
    <Span>4 : Scored<br></Span>
    <Span>5 : Conceded<br></Span>
    <Span>6 : Win Loss Draw<br></Span>
</p>