import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-support-analysis',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './support-analysis.component.html',
  styleUrls: ['./support-analysis.component.scss']
})
export class SupportAnalysisComponent {

}
