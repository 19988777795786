import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTime } from 'src/app/core/interface/date-time';
import { Event } from 'src/app/core/interface/event';
import { EventTopCardsPoints } from 'src/app/core/interface/event-top-cards-points';
import { JackpotBookmaker } from 'src/app/core/interface/jackpot-bookmaker';
import { TopCardsTeams } from 'src/app/core/interface/top-cards-teams';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';

@Component({
  selector: 'app-analysis-top-cards',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './analysis-top-cards.component.html',
  styleUrls: ['./analysis-top-cards.component.scss']
})
export class AnalysisTopCardsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  fixtureDate: string;
  id: number;
  todayDate: DateTime;
  weekNumberToday: string;
  matchEvent: Event;
  topCards: any;
  topCardsAT: any[];
  topCardsHT: any[];
  topCardsHTData: any;
  topCardsATData: any;
  topCardsPoints: EventTopCardsPoints;
  topCardsAnalysis: any;
  topCardAnalysisPoints$: any;

  constructor(
    private dateTimeService: DateTimeService,
    private footballService: FootballService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subscription.add(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams.pipe(first()).subscribe(params => {
          let date = params['date'];
          let date1 = new Date(date);
          console.log("date1:", date1);
          let year = date1.getFullYear();
          let month = ((date1.getMonth() + 1).toString()).padStart(2, '0');
          let day = (date1.getDate().toString()).padStart(2, '0');

          this.fixtureDate = `${year}-${month}-${day}`;
        })

        this.id = Number(this.route.snapshot.paramMap.get('id1'));
        console.log("id:", this.id);
        console.log("eDate:", this.fixtureDate);
        this.subscription.add(dateTimeService.getCurrentDate().subscribe(
          (dateTime) => {
            this.todayDate = dateTime;
            this.weekNumberToday = ((dateTime.yearWeek).toString()).padStart(2, '0');

            this.footballService.getMatchById(this.fixtureDate, this.id).subscribe((event) => {
              this.matchEvent = event;

              if (event) {
                /* get Top Card */
                this.subscription.add(
                  this.footballService.getTopCards(this.fixtureDate, event.seasonId).subscribe((topCards) => {
                    this.topCards = topCards;
                  })
                );
                /* get Top Card Home Team*/
                this.subscription.add(
                  this.footballService.getTopCardsByTeam(this.fixtureDate, event.seasonId, event.homeTeamId).subscribe((topCardsHT) => {
                    this.topCardsHT = topCardsHT;
                  })
                );
                /* get Top Card Away Team*/
                this.subscription.add(
                  this.footballService.getTopCardsByTeam(this.fixtureDate, event.seasonId, event.awayTeamId).subscribe((topCardsAT) => {
                    this.topCardsAT = topCardsAT;
                  })
                );
                /* get Top Card Home Team Data*/
                this.subscription.add(
                  this.footballService.getTopCardsByTeamData(this.fixtureDate, event.homeTeamId).subscribe((topCardsHTData) => {
                    this.topCardsHTData = topCardsHTData;
                  })
                );
                /* get Top Card Away Team Data*/
                this.subscription.add(
                  this.footballService.getTopCardsByTeamData(this.fixtureDate, event.awayTeamId).subscribe((topCardsATData) => {
                    this.topCardsATData = topCardsATData;
                  })
                );
                /* get Top Card Points*/
                this.subscription.add(
                  this.footballService.getTopCardsByEventPoints(this.fixtureDate, event.eventId).subscribe((topCardsPoints) => {
                    this.topCardsPoints = topCardsPoints;
                  })
                );
                /* get Top Card Analysis*/
                this.subscription.add(
                  this.footballService.getTopCardsByEventAnalysis(this.fixtureDate, event.eventId).subscribe((topCardsAnalysis) => {
                    this.topCardsAnalysis = topCardsAnalysis;
                  })
                );
                // analysis points
                this.subscription.add(this.footballService.getTopCardAnalysisPointsByMatch(this.fixtureDate, event.eventId).pipe(first())
                  .subscribe((topCardAnalysisPoints) => {
                    //this.responses$ = response;
                    this.topCardAnalysisPoints$ = topCardAnalysisPoints;
                  }))
              }
            })

          }));
      }
    }))
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
  }

}

