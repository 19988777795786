import { Route } from "@angular/router";
import { SupportDashboardComponent } from './support-dashboard/support-dashboard.component';
import { SupportDataComponent } from './support-data/support-data.component';
import { SupportErrorComponent } from './support-error/support-error.component';
import { SupportLogComponent } from './support-log/support-log.component';
import { SupportDataCheckComponent } from './support-data-check/support-data-check.component';
import { SupportAnalysisComponent } from './support-analysis/support-analysis.component';
import { MenuItem } from "../layouts/shared/models/menu.model";

export const SUPPORT_MENU: MenuItem[] = [
  { key: 'ds-navigation', label: 'Support Navigation', isTitle: true },
  { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/support/dashboard' },
  { key: 'ds-data', label: 'Data', isTitle: false, icon: 'uil-cloud-database-tree', link: '/support/data' },
  { key: 'ds-data-check', label: 'Data Check', isTitle: false, icon: 'uil-search', link: '/support/data-check' },
  { key: 'ds-error', label: 'Errors', isTitle: false, icon: 'uil-exclamation-triangle', link: '/support/errors' },
  { key: 'ds-log', label: 'Log', isTitle: false, icon: 'uil-newspaper', link: '/support/logs' },
  { key: 'ds-analysis', label: 'Analysis', isTitle: false, icon: 'uil-presentation-line', link: '/support/analysis' },
]

export const SUPPORT_ROUTES: Route[] = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
  { path: 'dashboard', component: SupportDashboardComponent, },
  { path: 'data', component: SupportDataComponent, },
  { path: 'data-check', component: SupportDataCheckComponent, },
  { path: 'errors', component: SupportErrorComponent, },
  { path: 'logs', component: SupportLogComponent, },
  { path: 'analysis', component: SupportAnalysisComponent, },
  // {
  //   path: 'support',
  //   /* canActivateChild: [AuthGuard, RegGuard], */
  //   children: [
  //     { path: '', component: SupportComponent, },
  //     { path: ':id1/:id2', component: SupportChatComponent, },
  //   ]
  // },
];