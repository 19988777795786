import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Competition } from 'src/app/core/interface/competition';
import { DateTime } from 'src/app/core/interface/date-time';
import { ApiService } from 'src/app/core/service/api.service';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';


@Component({
  selector: 'app-analysis-h2h-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './analysis-h2h-list.component.html',
  styleUrls: ['./analysis-h2h-list.component.scss']
})
export class AnalysisH2hListComponent implements OnInit, OnDestroy {
  id: any;
  dateForm: FormGroup;
  /* dateToday: string; */
  subscription: Subscription = new Subscription();
  todayData: any;
  dates = [];
  datePicked: string;
  requestClick: boolean;
  date$: string;
  h2hAnalysisPoints$: any;
  filteredH2HAnalysisPoints$: any;
  eventObject: any;
  filteredEventObject: any;
  h2hPointsObject$: any;
  competition$: (Competition & { key: string; })[];
  isSearchMode: boolean;

  constructor(
    private footballService: FootballService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService
  ) {
    
  }

  ngOnInit(): void {
    this.initializeForm();
    this.checkDataAvailableToday();
  }

  checkDataAvailableToday() {

    this.subscription.add(this.dateTimeService.getCurrentDate().pipe(first()).subscribe(
      (dateTime: DateTime) => {
        this.date$ = dateTime.date;
      }));
  }

  initializeForm() {
    this.dateForm = this.fb.group({
      date: [0],
    });

    this.dateForm.get("date").valueChanges.subscribe(x => {
      this.requestClick = false;

      let day = x.slice(8, 10);
      let month = x.slice(5, 7);
      let year = x.slice(0, 4);
      let date = `${year}-${month}-${day}`;
      this.datePicked = date;

      this.subscription.add(this.footballService.getAllCompetitionByDate(date)
        .subscribe((competitions) => {
          //this.responses$ = response;
          this.competition$ = competitions;
        }));
      //Get H2H Date List
      this.subscription.add(this.footballService.getH2HEventAnalysisPoints(date).pipe(first())
        .subscribe((h2hAnalysisPoints) => {
          //this.responses$ = response;
          this.filteredH2HAnalysisPoints$ = this.h2hAnalysisPoints$ = h2hAnalysisPoints;
        }));
      this.subscription.add(this.footballService.getH2HPointsList_Object(date).pipe(first())
        .subscribe((h2hPointsObject) => {
          //this.responses$ = response;
          this.h2hPointsObject$ = h2hPointsObject;
        }));
      this.subscription.add(this.footballService.getAllEventsByDate_Object(date)
        .subscribe((eventObject) => {
          //this.responses$ = response;
          this.filteredEventObject = this.eventObject = eventObject;
        }));
    })
  }

  edit(countryId: string) {

  }

  filter(query) {
    if (query === "" || !query) {
      this.isSearchMode = false;
    } else {
      this.isSearchMode = true;
    }
    
    this.filteredH2HAnalysisPoints$ = (query) ?
      this.h2hAnalysisPoints$.filter(c => (c.homeTeamNameLong + " " + c.awayTeamNameLong).toLowerCase().includes(query.toLowerCase())) :
      this.h2hAnalysisPoints$;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}




