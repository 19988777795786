<!-- team intro -->
<div class="d-inline-flex my-md-3" *ngIf="matchEvent">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span
                    *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- topCard -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="topCards && topCards.players">
    <h4 class="display-6 fw-normal mb-0">Season Top Card</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light"
    *ngIf="topCards && topCards.players">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Nationality</th>
            <th scope="col">Position</th>
            <th scope="col">Matches</th>
            <th scope="col">Minutes</th>
            <th scope="col">Yellow Card</th>
            <th scope="col">Red Card</th>
            <th scope="col">Yellow Red Card</th>
            <th scope="col">H1 Cards</th>
            <th scope="col">H2 Cards</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topCards.players;let i = index;">
            <td scope="col">{{i+1}}</td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.player.name">{{t.player.name}}</span><br>
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId]"
                        *ngIf="t.teams[matchEvent.homeTeamId]">{{matchEvent.homeTeamNameLong}}</span>
                    <span [class.fw-bold]="t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.teams[matchEvent.awayTeamId]">{{matchEvent.awayTeamNameLong}}</span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span *ngIf="t.player.nationality"><span
                            [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                            *ngIf="t.player.nationality.name">{{t.player.nationality.name}}</span>
                    </span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.player">
                    <span *ngIf="t.player.position">
                        <span
                            [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                            *ngIf="t.player.position.abbr">{{t.player.position.abbr}}</span>
                    </span>
                </span>
                <span *ngIf="!t.player">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.matches">{{t.total.matches}}</span>
                    <span *ngIf="!t.total.matches">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.minutes_played">{{t.total.minutes_played}}</span>
                    <span *ngIf="!t.total.minutes_played">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.yellow_cards">{{t.total.yellow_cards}}</span>
                    <span *ngIf="!t.total.yellow_cards">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.red_cards">{{t.total.red_cards}}</span>
                    <span *ngIf="!t.total.red_cards">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.yellowred_cards">{{t.total.yellowred_cards}}</span>
                    <span *ngIf="!t.total.yellowred_cards">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.number_of_cards_1st_half">{{t.total.number_of_cards_1st_half}}</span>
                    <span *ngIf="!t.total.number_of_cards_1st_half">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
            <td scope="col">
                <span *ngIf="t.total">
                    <span [class.fw-bold]="t.teams[matchEvent.homeTeamId] || t.teams[matchEvent.awayTeamId]"
                        *ngIf="t.total.number_of_cards_2nd_half">{{t.total.number_of_cards_2nd_half}}</span>
                    <span *ngIf="!t.total.number_of_cards_2nd_half">-</span>
                </span>
                <span *ngIf="!t.total">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topCard Home Team -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchEvent && topCardsHT">
    <h4 class="display-6 fw-normal mb-0">
        <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
    </h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsHT">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Nationality</th>
            <th scope="col">Position</th>
            <th scope="col">Matches</th>
            <th scope="col">Minutes</th>
            <th scope="col">Yellow Card</th>
            <th scope="col">Red Card</th>
            <th scope="col">Yellow Red Card</th>
            <th scope="col">H1 Cards</th>
            <th scope="col">H2 Cards</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topCardsHT|keyvalue;let i = index;">
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span
                        *ngIf="topCardsHT[t.key].positionNumber">{{topCardsHT[t.key].positionNumber}}</span>
                    <span *ngIf="!topCardsHT[t.key].positionNumber">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].playerName">{{topCardsHT[t.key].playerName}}</span><br>
                    <span *ngIf="!topCardsHT[t.key].playerName">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].nationality">{{topCardsHT[t.key].nationality}}</span>
                    <span *ngIf="!topCardsHT[t.key].nationality">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].position">
                        {{topCardsHT[t.key].position}}</span>
                    <span *ngIf="!topCardsHT[t.key].position">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].matches">{{topCardsHT[t.key].matches}}</span>
                    <span *ngIf="!topCardsHT[t.key].matches">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].minutesPlayed">{{topCardsHT[t.key].minutesPlayed}}</span>
                    <span *ngIf="!topCardsHT[t.key].minutesPlayed">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].yellowCards">{{topCardsHT[t.key].yellowCards}}</span>
                    <span *ngIf="!topCardsHT[t.key].yellowCards">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span *ngIf="topCardsHT[t.key].redCards">{{topCardsHT[t.key].redCards}}</span>
                    <span *ngIf="!topCardsHT[t.key].redCards">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span
                        *ngIf="topCardsHT[t.key].yellowRedCards">{{topCardsHT[t.key].yellowRedCards}}</span>
                    <span *ngIf="!topCardsHT[t.key].yellowRedCards">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span
                        *ngIf="topCardsHT[t.key].cardsH1">{{topCardsHT[t.key].cardsH1}}</span>
                    <span *ngIf="!topCardsHT[t.key].cardsH1">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHT[t.key]">
                    <span
                        *ngIf="topCardsHT[t.key].cardsH2">{{topCardsHT[t.key].cardsH2}}</span>
                    <span *ngIf="!topCardsHT[t.key].cardsH2">-</span>
                </span>
                <span *ngIf="!topCardsHT[t.key]">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topCard Home Team Data -->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsHTData">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Highest Rank</th>
            <th scope="col">Players</th>
            <th scope="col">Matches</th>
            <th scope="col">Yellow Card</th>
            <th scope="col">Red Card</th>
            <th scope="col">Yellow Red Card</th>
            <th scope="col">Cards H1</th>
            <th scope="col">Cards H2</th>
            <th scope="col">All Cards</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span *ngIf="topCardsHTData.cardseasonId">
                    <span *ngFor="let t of topCardsHTData.cardseasonId">
                        {{t+1}}<br>
                    </span>
                </span>
                <span *ngIf="!topCardsHTData.cardseasonId">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cardsName">
                    <span *ngFor="let t of topCardsHTData.cardsName">
                        {{t}}<br>
                    </span>
                </span>
                <span *ngIf="!topCardsHTData.cardsName">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cardsPosition">
                    <span *ngFor="let t of topCardsHTData.cardsPosition">
                        {{t}}<br>
                    </span>
                </span>
                <span *ngIf="!topCardsHTData.cardsPosition">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.highestCardsPosition">
                    <span>{{topCardsHTData.highestCardsPosition + 1}}</span>
                </span>
                <span *ngIf="!topCardsHTData.highestCardsPosition">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.playerCount">
                    <span>{{topCardsHTData.playerCount}}</span>
                </span>
                <span *ngIf="!topCardsHTData.playerCount">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cumulativeMatches">
                    <span>{{topCardsHTData.cumulativeMatches}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cumulativeMatches">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cumulativeYellowCard">
                    <span>{{topCardsHTData.cumulativeYellowCard}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cumulativeYellowCard">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cumulativeRedCard">
                    <span>{{topCardsHTData.cumulativeRedCard}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cumulativeRedCard">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cumulativeRedYellowCard">
                    <span>{{topCardsHTData.cumulativeRedYellowCard}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cumulativeRedYellowCard">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cardsH1">
                    <span>{{topCardsHTData.cardsH1}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cardsH1">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cardsH2">
                    <span>{{topCardsHTData.cardsH2}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cardsH2">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsHTData.cumulativeCards">
                    <span>{{topCardsHTData.cumulativeCards}}</span>
                </span>
                <span *ngIf="!topCardsHTData.cumulativeCards">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topCard Away Team -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="matchEvent && topCardsAT">
    <h4 class="display-6 fw-normal mb-0">
        <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
    </h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsAT">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Nationality</th>
            <th scope="col">Position</th>
            <th scope="col">Matches</th>
            <th scope="col">Minutes</th>
            <th scope="col">Yellow Card</th>
            <th scope="col">Red Card</th>
            <th scope="col">Yellow Red Card</th>
            <th scope="col">H1 Cards</th>
            <th scope="col">H2 Cards</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of topCardsAT|keyvalue;let i = index;">
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span
                        *ngIf="topCardsAT[t.key].positionNumber">{{topCardsAT[t.key].positionNumber}}</span>
                    <span *ngIf="!topCardsAT[t.key].positionNumber">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].playerName">{{topCardsAT[t.key].playerName}}</span><br>
                    <span *ngIf="!topCardsAT[t.key].playerName">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].nationality">{{topCardsAT[t.key].nationality}}</span>
                    <span *ngIf="!topCardsAT[t.key].nationality">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].position">
                        {{topCardsAT[t.key].position}}</span>
                    <span *ngIf="!topCardsAT[t.key].position">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].matches">{{topCardsAT[t.key].matches}}</span>
                    <span *ngIf="!topCardsAT[t.key].matches">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].minutesPlayed">{{topCardsAT[t.key].minutesPlayed}}</span>
                    <span *ngIf="!topCardsAT[t.key].minutesPlayed">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].yellowCards">{{topCardsAT[t.key].yellowCards}}</span>
                    <span *ngIf="!topCardsAT[t.key].yellowCards">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span *ngIf="topCardsAT[t.key].redCards">{{topCardsAT[t.key].redCards}}</span>
                    <span *ngIf="!topCardsAT[t.key].redCards">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span
                        *ngIf="topCardsAT[t.key].yellowRedCards">{{topCardsAT[t.key].yellowRedCards}}</span>
                    <span *ngIf="!topCardsAT[t.key].yellowRedCards">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span
                        *ngIf="topCardsAT[t.key].cardsH1">{{topCardsAT[t.key].cardsH1}}</span>
                    <span *ngIf="!topCardsAT[t.key].cardsH1">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsAT[t.key]">
                    <span
                        *ngIf="topCardsAT[t.key].cardsH2">{{topCardsAT[t.key].cardsH2}}</span>
                    <span *ngIf="!topCardsAT[t.key].cardsH2">-</span>
                </span>
                <span *ngIf="!topCardsAT[t.key]">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- topCard Away Team Data -->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsATData">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Position</th>
            <th scope="col">Highest Rank</th>
            <th scope="col">Players</th>
            <th scope="col">Matches</th>
            <th scope="col">Yellow Card</th>
            <th scope="col">Red Card</th>
            <th scope="col">Yellow Red Card</th>
            <th scope="col">Cards H1</th>
            <th scope="col">Cards H2</th>
            <th scope="col">All Cards</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span *ngIf="topCardsATData.cardseasonId">
                    <span *ngFor="let t of topCardsATData.cardseasonId">
                        {{t+1}}<br>
                    </span>
                </span>
                <span *ngIf="!topCardsATData.cardseasonId">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cardsName">
                    <span *ngFor="let t of topCardsATData.cardsName">
                        {{t}}<br>
                    </span>
                </span>
                <span *ngIf="!topCardsATData.cardsName">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cardsPosition">
                    <span *ngFor="let t of topCardsATData.cardsPosition">
                        {{t}}<br>
                    </span>
                </span>
                <span *ngIf="!topCardsATData.cardsPosition">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.highestCardsPosition">
                    <span>{{topCardsATData.highestCardsPosition + 1}}</span>
                </span>
                <span *ngIf="!topCardsATData.highestCardsPosition">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.playerCount">
                    <span>{{topCardsATData.playerCount}}</span>
                </span>
                <span *ngIf="!topCardsATData.playerCount">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cumulativeMatches">
                    <span>{{topCardsATData.cumulativeMatches}}</span>
                </span>
                <span *ngIf="!topCardsATData.cumulativeMatches">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cumulativeYellowCard">
                    <span>{{topCardsATData.cumulativeYellowCard}}</span>
                </span>
                <span *ngIf="!topCardsATData.cumulativeYellowCard">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cumulativeRedCard">
                    <span>{{topCardsATData.cumulativeRedCard}}</span>
                </span>
                <span *ngIf="!topCardsATData.cumulativeRedCard">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cumulativeRedYellowCard">
                    <span>{{topCardsATData.cumulativeRedYellowCard}}</span>
                </span>
                <span *ngIf="!topCardsATData.cumulativeRedYellowCard">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cardsH1">
                    <span>{{topCardsATData.cardsH1}}</span>
                </span>
                <span *ngIf="!topCardsATData.cardsH1">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cardsH2">
                    <span>{{topCardsATData.cardsH2}}</span>
                </span>
                <span *ngIf="!topCardsATData.cardsH2">-</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsATData.cumulativeCards">
                    <span>{{topCardsATData.cumulativeCards}}</span>
                </span>
                <span *ngIf="!topCardsATData.cumulativeCards">-</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- points -->
<!-- Top Scorer Points -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="topCardsPoints">
    <h4 class="display-6 fw-normal mb-0">Points</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsPoints">
    <caption>Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Cards</th>
            <th scope="col">H1</th>
            <th scope="col">H2</th>
            <th scope="col">Player Rank</th>
            <th scope="col">Cards Per Match</th>
            <th scope="col">Player Count</th>
            <th scope="col">Yellow</th>
            <th scope="col">Red</th>
            <th scope="col">Red Yellow</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsAdvantage">
                    {{topCardsPoints.cardsAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsH1Advantage">
                    {{topCardsPoints.cardsH1Advantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsH2Advantage">
                    {{topCardsPoints.cardsH2Advantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsHighestRankAdvantage">
                    {{topCardsPoints.cardsHighestRankAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsPerMatchAdvantage">
                    {{topCardsPoints.cardsPerMatchAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsPlayerCountAdvantage">
                    {{topCardsPoints.cardsPlayerCountAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsYellowAdvantage">
                    {{topCardsPoints.cardsYellowAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsRedAdvantage">
                    {{topCardsPoints.cardsRedAdvantage}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.cardsRedYellowAdvantage">
                    {{topCardsPoints.cardsRedYellowAdvantage}}
                </div>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsPoints">
    <caption>Cumulative Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col">Neutral</th>
            <th scope="col">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Count</span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsPoints.hAdvantageCount">
                    {{topCardsPoints.hAdvantageCount}}<br>
                </span>
                <span *ngIf="topCardsPoints.hAdvantageCountPercentage">
                    {{topCardsPoints.hAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topCardsPoints.hAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!topCardsPoints.hAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsPoints.neutralAdvantageCount">
                    {{topCardsPoints.neutralAdvantageCount}}<br>
                </span>
                <span *ngIf="topCardsPoints.neutralAdvantageCountPercentage">
                    {{topCardsPoints.neutralAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topCardsPoints.neutralAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="!topCardsPoints.neutralAdvantageCountPercentage">
                    0%
                </span>
            </td>
            <td scope="col">
                <span *ngIf="topCardsPoints.aAdvantageCount">
                    {{topCardsPoints.aAdvantageCount}}<br>
                </span>
                <span *ngIf="!topCardsPoints.aAdvantageCount">
                    0<br>
                </span>
                <span *ngIf="topCardsPoints.aAdvantageCountPercentage">
                    {{topCardsPoints.aAdvantageCountPercentage}}%
                </span>
                <span *ngIf="!topCardsPoints.aAdvantageCountPercentage">
                    0%
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="topCardsPoints">
    <caption>Conclusion</caption>
    <thead>
        <tr>
            <th scope="col">Greater Than</th>
            <th scope="col">50%</th>
            <th scope="col">60%</th>
            <th scope="col">70%</th>
            <th scope="col">80%</th>
            <th scope="col">90%</th>
            <th scope="col">100%</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">
                <span>Advantage</span>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.advantage51">
                    {{topCardsPoints.advantage51}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.advantage60">
                    {{topCardsPoints.advantage60}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.advantage70">
                    {{topCardsPoints.advantage70}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.advantage80">
                    {{topCardsPoints.advantage80}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.advantage90">
                    {{topCardsPoints.advantage90}}
                </div>
            </td>
            <td scope="col">
                <div *ngIf="topCardsPoints.advantage100">
                    {{topCardsPoints.advantage100}}
                </div>
            </td>
        </tr>
    </tbody>
</table>

<!-- top card analysis -->
<table class="table table-sm flex-fill" *ngIf="topCardsAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} Vs<br></span>
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                </span>
            </th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                </span>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="
    topCardsAnalysis.cardsCountDiffHomePercentage01 || 
    topCardsAnalysis.cardsCountDiffHomePercentage20 ||
    topCardsAnalysis.cardsCountDiffHomePercentage30 ||
    topCardsAnalysis.cardsCountDiffHomePercentage40 ||
    topCardsAnalysis.cardsCountDiffHomePercentage50 ||
    topCardsAnalysis.cardsCountDiffAwayPercentage01 || 
    topCardsAnalysis.cardsCountDiffAwayPercentage20 ||
    topCardsAnalysis.cardsCountDiffAwayPercentage30 ||
    topCardsAnalysis.cardsCountDiffAwayPercentage40 ||
    topCardsAnalysis.cardsCountDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Cumulative Cards Count</td>
            <td class="time text-start w-25">
                <span *ngIf="topCardsAnalysis.cardsCountDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topCardsAnalysis.cardsCountDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.cardsCountDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topCardsAnalysis.cardsH1DiffHomePercentage01 || 
    topCardsAnalysis.cardsH1DiffHomePercentage20 ||
    topCardsAnalysis.cardsH1DiffHomePercentage30 ||
    topCardsAnalysis.cardsH1DiffHomePercentage40 ||
    topCardsAnalysis.cardsH1DiffHomePercentage50 ||
    topCardsAnalysis.cardsH1DiffAwayPercentage01 || 
    topCardsAnalysis.cardsH1DiffAwayPercentage20 ||
    topCardsAnalysis.cardsH1DiffAwayPercentage30 ||
    topCardsAnalysis.cardsH1DiffAwayPercentage40 ||
    topCardsAnalysis.cardsH1DiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>H1</td>
            <td class="time text-start w-25">
                <span *ngIf="topCardsAnalysis.cardsH1DiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topCardsAnalysis.cardsH1DiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH1DiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topCardsAnalysis.cardsH2DiffHomePercentage01 || 
    topCardsAnalysis.cardsH2DiffHomePercentage20 ||
    topCardsAnalysis.cardsH2DiffHomePercentage30 ||
    topCardsAnalysis.cardsH2DiffHomePercentage40 ||
    topCardsAnalysis.cardsH2DiffHomePercentage50 ||
    topCardsAnalysis.cardsH2DiffAwayPercentage01 || 
    topCardsAnalysis.cardsH2DiffAwayPercentage20 ||
    topCardsAnalysis.cardsH2DiffAwayPercentage30 ||
    topCardsAnalysis.cardsH2DiffAwayPercentage40 ||
    topCardsAnalysis.cardsH2DiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>H2</td>
            <td class="time text-start w-25">
                <span *ngIf="topCardsAnalysis.cardsH2DiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topCardsAnalysis.cardsH2DiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.cardsH2DiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topCardsAnalysis.redCardDiffHomePercentage01 || 
    topCardsAnalysis.redCardDiffHomePercentage20 ||
    topCardsAnalysis.redCardDiffHomePercentage30 ||
    topCardsAnalysis.redCardDiffHomePercentage40 ||
    topCardsAnalysis.redCardDiffHomePercentage50 ||
    topCardsAnalysis.redCardDiffAwayPercentage01 || 
    topCardsAnalysis.redCardDiffAwayPercentage20 ||
    topCardsAnalysis.redCardDiffAwayPercentage30 ||
    topCardsAnalysis.redCardDiffAwayPercentage40 ||
    topCardsAnalysis.redCardDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Red Card</td>
            <td class="time text-start w-25">
                <span *ngIf="topCardsAnalysis.redCardDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topCardsAnalysis.redCardDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.redCardDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    topCardsAnalysis.playerCountDiffHomePercentage01 || 
    topCardsAnalysis.playerCountDiffHomePercentage20 ||
    topCardsAnalysis.playerCountDiffHomePercentage30 ||
    topCardsAnalysis.playerCountDiffHomePercentage40 ||
    topCardsAnalysis.playerCountDiffHomePercentage50 ||
    topCardsAnalysis.playerCountDiffAwayPercentage01 || 
    topCardsAnalysis.playerCountDiffAwayPercentage20 ||
    topCardsAnalysis.playerCountDiffAwayPercentage30 ||
    topCardsAnalysis.playerCountDiffAwayPercentage40 ||
    topCardsAnalysis.playerCountDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Player Count</td>
            <td class="time text-start w-25">
                <span *ngIf="topCardsAnalysis.playerCountDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="topCardsAnalysis.playerCountDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="topCardsAnalysis.playerCountDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points -->
<table class="table table-sm flex-fill" *ngIf="topCardAnalysisPoints$">
    <caption>
        <Span>Key<br></Span>
        <Span><br></Span>
        <Span>1 :    Card Count<br></Span>
        <Span>2 :    Red Card Count<br></Span>
        <Span>3 :    Card H1<br></Span>
        <Span>4 :    Card H2<br></Span>
        <Span>5 :    Carded Player Count<br></Span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">3</th>
            <th class="teams text-start">4</th>
            <th class="teams text-start">5</th>
            <th class="teams text-start">Result 1X2</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="topCardAnalysisPoints$.cardsCountAdvantage">{{topCardAnalysisPoints$.cardsCountAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topCardAnalysisPoints$.redCardAdvantage">{{topCardAnalysisPoints$.redCardAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topCardAnalysisPoints$.cardsH1Advantage">{{topCardAnalysisPoints$.cardsH1Advantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topCardAnalysisPoints$.cardsH2Advantage">{{topCardAnalysisPoints$.cardsH2Advantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="topCardAnalysisPoints$.playerCountAdvantage">{{topCardAnalysisPoints$.playerCountAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span></span>
            </td>
        </tr>
    </tbody>
</table>