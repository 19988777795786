<div class="px-4 my-5 text-center">
    <h1 class="display-5 fw-bold">H2H Analysis Points</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Head to Head analysis points against results on a given date.</p>
    </div>
</div>

<form [formGroup]="dateForm" class="row g-3">
    <div class="col-2"></div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <label class="input-group-text" for="date">Pick a Date</label>
            <input formControlName="date" id="date" type="date" class="form-control" aria-label="date-input"
                aria-describedby="inputGroup-sizing-sm" required>
        </div>
    </div>
    <div class="col-4">
        <div class="input-group input-group-sm mb-3">
            <button class="w-100 btn btn-primary btn-lg" [disabled]="true">Request Data</button>
        </div>
    </div>
    <div class="col-2"></div>
</form>

<div class="container">
    <p>
        <input #query (keyup)="filter(query.value)" type="text" class="form-control" placeholder="Search..">
    </p>
</div>

<div *ngIf="!isSearchMode">
    <div *ngIf="h2hPointsObject$">
        <table class="table table-sm flex-fill" *ngFor="let competition of competition$;let i = index">
            <thead>
                <tr class="small-tx">
                    <h5>
                        <span class="fw-bold fst-italic"
                            *ngIf="competition.competitionName">{{competition.competitionName}}<br></span>
                        <span class="fst-italic" *ngIf="competition.countryName">{{competition.countryName}}</span>
                    </h5>
                </tr>
            </thead>
            <thead class="table-light">
                <tr class="small-tx">
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Ov/Un</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">GG/GN/NN</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Result</th>
                    <th class="teams text-start"></th>
                </tr>
            </thead>
            <thead class="table-light">
                <tr class="small-tx">
                    <th class="teams text-start">Match</th>
                    <th class="teams text-start">1</th>
                    <th class="teams text-start">2</th>
                    <th class="teams text-start">3</th>
                    <th class="teams text-start">4</th>
                    <th class="teams text-start">5</th>
                    <th class="teams text-start">6</th>
                    <th class="teams text-start">Adv</th>
                    <th class="teams text-start">0.5</th>
                    <th class="teams text-start">1.5</th>
                    <th class="teams text-start">2.5</th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start"></th>
                    <th class="teams text-start">Result 1X2</th>
                    <th class="teams text-start">Result Ov/Un</th>
                </tr>
            </thead>
            <tbody *ngIf="eventObject">
                <tr class="cursor-pointer status-live small-tx"
                    *ngFor="let event of (competition.events | keyvalue);let i = index;">
                    <td class="time text-start">
                        <div class="row text-bold" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <div class="col-xs" *ngIf="eventObject[event.key]"
                                [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                                <span
                                    *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                    Vs</span><br>
                                <span
                                    *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].hAScoredAdvantageantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].hAScoredAdvantage">{{h2hPointsObject$[event.key].hAScoredAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span
                                [class]="h2hPointsObject$[event.key].hAConcededAdvantageantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].hAConcededAdvantage">{{h2hPointsObject$[event.key].hAConcededAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span
                                [class]="h2hPointsObject$[event.key].hAWinDrawLossAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].hAWinDrawLossAdvantage">{{h2hPointsObject$[event.key].hAWinDrawLossAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span
                                [class]="h2hPointsObject$[event.key].homeHomeAwayAwayScoredAdvantageantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].homeHomeAwayAwayScoredAdvantage">{{h2hPointsObject$[event.key].homeHomeAwayAwayScoredAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span
                                [class]="h2hPointsObject$[event.key].homeHomeAwayAwayConcededAdvantageantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].homeHomeAwayAwayConcededAdvantage">{{h2hPointsObject$[event.key].homeHomeAwayAwayConcededAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span
                                [class]="h2hPointsObject$[event.key].homeHomeAwayAwayWinDrawLossAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].homeHomeAwayAwayWinDrawLossAdvantage">{{h2hPointsObject$[event.key].homeHomeAwayAwayWinDrawLossAdvantage}}</span></span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].adv1X2CountAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].adv1X2CountAdvantage">{{h2hPointsObject$[event.key].adv1X2CountAdvantage}}<br></span></span>
                            <span *ngIf="h2hPointsObject$[event.key].adv1Count">1 -
                                {{h2hPointsObject$[event.key].adv1Count}}
                                <span
                                    *ngIf="h2hPointsObject$[event.key].adv1CountPercentage">({{h2hPointsObject$[event.key].adv1CountPercentage}}%)<br></span>
                            </span>
                            <span *ngIf="h2hPointsObject$[event.key].advXCount">X -
                                {{h2hPointsObject$[event.key].advXCount}}
                                <span
                                    *ngIf="h2hPointsObject$[event.key].advXCountPercentage">({{h2hPointsObject$[event.key].advXCountPercentage}}%)<br></span>
                            </span>
                            <span *ngIf="h2hPointsObject$[event.key].adv2Count">2 -
                                {{h2hPointsObject$[event.key].adv2Count}}
                                <span
                                    *ngIf="h2hPointsObject$[event.key].adv2CountPercentage">({{h2hPointsObject$[event.key].adv2CountPercentage}}%)<br></span>
                            </span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].Ov05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].Ov05">Ov0.5<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].Un05Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].Un05">Un0.5</span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].Ov15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].Ov15">Ov1.5<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].Un15Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].Un15">Un1.5</span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].Ov25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].Ov25">Ov2.5<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].Un25Rslt?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].Un25">Un2.5</span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].homeHomeAwayAwayGGAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].homeHomeAwayAwayGG">GG<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].homeHomeAwayAwayGNAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].homeHomeAwayAwayGN">GN<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].homeHomeAwayAwayNNAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].homeHomeAwayAwayNN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span [class]="h2hPointsObject$[event.key].hAGGAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].hAGG">GG<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].hAGNAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].hAGN">GN<br></span></span>
                            <span [class]="h2hPointsObject$[event.key].hANNAdvantageResults?'green-bd':'red-bg'"><span
                                    *ngIf="h2hPointsObject$[event.key].hANN">NN<br></span></span>
                        </span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span
                                *ngIf="h2hPointsObject$[event.key].result1X2">{{h2hPointsObject$[event.key].result1X2}}</span></span>
                    </td>
                    <td class="teams text-start">
                        <span *ngIf="h2hPointsObject$[event.key]">
                            <span *ngIf="h2hPointsObject$[event.key].resultOv25">Ov2.5</span>
                            <span *ngIf="!h2hPointsObject$[event.key].resultOv25">
                                <span *ngIf="h2hPointsObject$[event.key].resultOv15">Ov1.5</span>
                                <span *ngIf="!h2hPointsObject$[event.key].resultOv15">
                                    <span *ngIf="h2hPointsObject$[event.key].resultOv05">Ov0.5</span>
                                </span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="isSearchMode">
    <table class="table table-sm flex-fill">
        <thead class="table-light">
            <tr class="small-tx">
                <th>#</th>
                <th class="teams text-start">Match</th>
                <th class="teams text-start">1</th>
                <th class="teams text-start">2</th>
                <th class="teams text-start">3</th>
                <th class="teams text-start">4</th>
                <th class="teams text-start">5</th>
                <th class="teams text-start">6</th>
                <th class="teams text-start">Ov 0.5</th>
                <th class="teams text-start">Ov 1.5</th>
                <th class="teams text-start">Ov 2.5</th>
                <th class="teams text-start">Result 1X2</th>
                <th class="teams text-start">Result Ov/Un</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor-pointer status-live small-tx"
                *ngFor="let event of filteredH2HAnalysisPoints$;let i = index;">
                <td>{{i+1}}.</td>
                <td class="time text-start">
                    <div class="row text-bold" *ngIf="eventObject">
                        <div class="col-xs" *ngIf="eventObject[event.key]"
                            [class.text-decoration-line-through]="eventObject[event.key].eventStatus === 'Cancelled' || eventObject[event.key].eventStatus === 'Postponed'">
                            <span
                                *ngIf="eventObject[event.key].homeTeamNameLong">{{eventObject[event.key].homeTeamNameLong}}
                                Vs</span><br>
                            <span
                                *ngIf="eventObject[event.key].awayTeamNameLong">{{eventObject[event.key].awayTeamNameLong}}</span>
                        </div>
                    </div>
                </td>

                <td class="teams text-start">
                    <span *ngIf="event.hAScoredAdvantageantageResults">{{event.hAScoredAdvantageantageResults}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.hAConcededAdvantageantageResults">{{event.hAConcededAdvantageantageResults}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.hAWinDrawLossAdvantageResults">{{event.hAWinDrawLossAdvantageResults}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.homeHomeAwayAwayScoredAdvantageantageResults">{{event.homeHomeAwayAwayScoredAdvantageantageResults}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.homeHomeAwayAwayConcededAdvantageantageResults">{{event.homeHomeAwayAwayConcededAdvantageantageResults}}</span>
                </td>
                <td class="teams text-start">
                    <span
                        *ngIf="event.homeHomeAwayAwayWinDrawLossAdvantageResults">{{event.homeHomeAwayAwayWinDrawLossAdvantageResults}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.Ov05">Ov0.5</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.Ov15">Ov1.5</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.Ov25">Ov2.5</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.result1X2">{{event.result1X2}}</span>
                </td>
                <td class="teams text-start">
                    <span *ngIf="event.resultOv25">Ov2.5</span>
                    <span *ngIf="!event.resultOv25">
                        <span *ngIf="event.resultOv15">Ov1.5</span>
                        <span *ngIf="!event.resultOv15">
                            <span *ngIf="event.resultOv05">Ov0.5</span>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p>
    <Span>Key<br></Span>
    <Span><br></Span>
    <Span>Home Vs Away (Cumulative)<br></Span>
    <Span>1 : Scored<br></Span>
    <Span>2 : Conceded<br></Span>
    <Span>3 : Win Loss Draw<br></Span>
    <Span>Home Team at Home Vs Away Team at Away<br></Span>
    <Span>4 : Scored<br></Span>
    <Span>5 : Conceded<br></Span>
    <Span>6 : Win Loss Draw<br></Span>
</p>