<!-- team intro -->
<div class="d-inline-flex my-md-3" *ngIf="matchEvent">
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
            </p>
        </div>

    </div>
    <div class="col-2 bg-light text-center">
        <div class="pt-3 my-md-3 py-md-3">
            <p class="fs-6 lh-1">
                <span *ngIf="matchEvent.eventStatus">{{matchEvent.eventStatus | uppercase }}</span>
            </p>
            <h4 class="mb-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span [class.fw-lighter]="matchEvent.eventResultHome < matchEvent.eventResultAway"
                    *ngIf="matchEvent.eventResultHome">{{matchEvent.eventResultHome}}</span>
                <span *ngIf="matchEvent.eventResultHome === 0">0</span>
                <span class="fw-lighter">:</span>
                <span [class.fw-lighter]="matchEvent.eventResultAway < matchEvent.eventResultHome"
                    *ngIf="matchEvent.eventResultAway">{{matchEvent.eventResultAway}}</span>
                <span *ngIf="matchEvent.eventResultAway === 0">0</span>
            </h4>
            <h4 class="mb-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                <span>-</span>
                <span class="fw-lighter">:</span>
                <span>-</span>
            </h4>
            <p class="fw-lighter lh-1"
                *ngIf="!(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span [class.fw-bold]="matchEvent.awayTeamHT < matchEvent.homeTeamHT"
                    *ngIf="matchEvent.homeTeamHT">{{matchEvent.homeTeamHT}}</span>
                <span *ngIf="matchEvent.homeTeamHT === 0">0</span>
                :<span [class.fw-bold]="matchEvent.awayTeamHT > matchEvent.homeTeamHT"
                    *ngIf="matchEvent.awayTeamHT">{{matchEvent.awayTeamHT}}</span>
                <span *ngIf="matchEvent.awayTeamHT === 0">0</span>)
            </p>
            <p class="fw-lighter lh-1"
                *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                (<span>-</span>:<span>-</span>)
            </p>
            <p class="fs-5 lh-1">
                <span
                    *ngIf="(matchEvent.eventStatus === 'Not started' || matchEvent.eventStatus === 'Cancelled' || matchEvent.eventStatus === 'Postponed')">
                    <span *ngIf="matchEvent.eventTime">{{matchEvent.eventTime}}</span>
                </span>
        </div>
    </div>
    <div class="col-5 bg-light text-center">
        <img src="../../../assets/IMG/miyagi_global_limited_logo.svg" class="pt-3 shadow-sm mx-auto"
            style="width: 30%; height: auto;">
        <div>
            <p class="fs-3">
                <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
            </p>
        </div>
    </div>
</div>

<!-- table -->
<div *ngFor="let tableNo of tableList|keyvalue">
    <div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center">
        <h4 class="display-6 fw-normal mb-0">Season Table</h4>
    </div>
    <table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" >
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Team</th>
                <th scope="col">Played</th>
                <th scope="col">Win</th>
                <th scope="col">Loss</th>
                <th scope="col">Draw</th>
                <th scope="col">Goals</th>
                <th scope="col">Pts</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let t of table[tableNo.key];let i = index;">
                <td scope="col"
                    [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong">
                    {{i+1}}</td>
                <td scope="col">
                    <span *ngIf="t.team">
                        <span
                            [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                            *ngIf="t.team.mediumname">{{t.team.mediumname}}</span>
                    </span>
                </td>
                <td scope="col">
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.total">{{t.total}}</span>
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.total === 0">0</span>
                </td>
                <td scope="col">
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.winTotal">{{t.winTotal}}</span>
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.winTotal === 0">0</span>
                </td>
                <td scope="col">
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.drawTotal">{{t.drawTotal}}</span>
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.drawTotal === 0">0</span>
                </td>
                <td scope="col">
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.lossTotal">{{t.lossTotal}}</span>
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.lossTotal === 0">0</span>
                </td>
                <td scope="col">
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.goalsForTotal">{{t.goalsForTotal}}</span><span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="!t.goalsForTotal">0</span>/<span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.goalsAgainstTotal">{{t.goalsAgainstTotal}}</span><span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.goalsAgainstTotal === 0">0</span>
                </td>
                <td scope="col">
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.pointsTotal">{{t.pointsTotal}}</span>
                    <span
                        [class.fw-bold]="t.team.mediumname === matchEvent.homeTeamNameLong || t.team.mediumname === matchEvent.awayTeamNameLong"
                        *ngIf="t.pointsTotal === 0">0</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- event table -->
<div class="bg-light pricing-header p-3 pb-md-4 mx-auto text-center" *ngIf="eventTable || eventTablePoints">
    <h4 class="display-6 fw-normal mb-0">Season Table</h4>
</div>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="eventTable">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Team</th>
            <th scope="col">Played</th>
            <th scope="col">Win</th>
            <th scope="col">Loss</th>
            <th scope="col">Draw</th>
            <th scope="col">Goals</th>
            <th scope="col">Pts</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="eventTable.h">
            <td scope="col">
                <span *ngIf="eventTable.h.pos">{{eventTable.h.pos}}</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.team">
                    <span *ngIf="eventTable.h.team.mediumname">{{eventTable.h.team.mediumname}}</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.total">{{eventTable.h.total}}</span>
                <span *ngIf="eventTable.h.total === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.winTotal">{{eventTable.h.winTotal}}</span>
                <span *ngIf="eventTable.h.winTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.drawTotal">{{eventTable.h.drawTotal}}</span>
                <span *ngIf="eventTable.h.drawTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.lossTotal">{{eventTable.h.lossTotal}}</span>
                <span *ngIf="eventTable.h.lossTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.goalsForTotal">{{eventTable.h.goalsForTotal}}</span><span
                    *ngIf="!eventTable.h.goalsForTotal">0</span>/<span
                    *ngIf="eventTable.h.goalsAgainstTotal">{{eventTable.h.goalsAgainstTotal}}</span><span
                    *ngIf="eventTable.h.goalsAgainstTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.h.pointsTotal">{{eventTable.h.pointsTotal}}</span>
                <span *ngIf="eventTable.h.pointsTotal === 0">0</span>
            </td>
        </tr>
    </tbody>
    <tbody>
        <tr *ngIf="eventTable.a">
            <td scope="col">
                <span *ngIf="eventTable.a.pos">{{eventTable.a.pos}}</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.team">
                    <span *ngIf="eventTable.a.team.mediumname">{{eventTable.a.team.mediumname}}</span>
                </span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.total">{{eventTable.a.total}}</span>
                <span *ngIf="eventTable.a.total === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.winTotal">{{eventTable.a.winTotal}}</span>
                <span *ngIf="eventTable.a.winTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.drawTotal">{{eventTable.a.drawTotal}}</span>
                <span *ngIf="eventTable.a.drawTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.lossTotal">{{eventTable.a.lossTotal}}</span>
                <span *ngIf="eventTable.a.lossTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.goalsForTotal">{{eventTable.a.goalsForTotal}}</span><span
                    *ngIf="!eventTable.a.goalsForTotal">0</span>/<span
                    *ngIf="eventTable.a.goalsAgainstTotal">{{eventTable.a.goalsAgainstTotal}}</span><span
                    *ngIf="eventTable.a.goalsAgainstTotal === 0">0</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTable.a.pointsTotal">{{eventTable.a.pointsTotal}}</span>
                <span *ngIf="eventTable.a.pointsTotal === 0">0</span>
            </td>
        </tr>
    </tbody>
</table>

<!-- table points data -->
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="eventTablePoints">
    <caption>Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col">Away</th>
            <th scope="col">All</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">Scored</td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.goalsForAdvantageH">{{eventTablePoints.goalsForAdvantageH}}</span>
            </td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.goalsForAdvantageA">{{eventTablePoints.goalsForAdvantageA}}</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.goalsForAdvantage">{{eventTablePoints.goalsForAdvantage}}</span>
            </td>

        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">Conceded</td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.goalsForAdvantageH">{{eventTablePoints.goalsForAdvantageH}}</span>
            </td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.goalsForAdvantageA">{{eventTablePoints.goalsForAdvantageA}}</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.goalsForAdvantage">{{eventTablePoints.goalsForAdvantage}}</span>
            </td>

        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">Points</td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.pointsTeamAdvantageH">{{eventTablePoints.pointsTeamAdvantageH}}</span>
            </td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.pointsTeamAdvantageA">{{eventTablePoints.pointsTeamAdvantageA}}</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.pointsTeamAdvantage">{{eventTablePoints.pointsTeamAdvantage}}</span>
            </td>

        </tr>
    </tbody>
    <tbody>
        <tr>
            <td scope="col">Rank</td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.rankTeamAdvantageH">{{eventTablePoints.rankTeamAdvantageH}}</span>
            </td>
            <td scope="col">
                <span
                    *ngIf="eventTablePoints.rankTeamAdvantageA">{{eventTablePoints.rankTeamAdvantageA}}</span>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.rankTeamAdvantage">{{eventTablePoints.rankTeamAdvantage}}</span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="eventTablePoints">
    <caption>Cumulative Advantage</caption>
    <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Home</th>
            <th scope="col">Neutral</th>
            <th scope="col">Away</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">Count</td>
            <td scope="col" [class.fw-bold]="eventTablePoints.hAdvantageCountPercentage > 50">
                <span *ngIf="eventTablePoints.hAdvantageCount">{{eventTablePoints.hAdvantageCount}}</span><br>
                <span
                    *ngIf="eventTablePoints.hAdvantageCountPercentage">{{eventTablePoints.hAdvantageCountPercentage}}%</span>
            </td>
            <td scope="col" [class.fw-bold]="eventTablePoints.neutralAdvantageCountPercentage > 50">
                <span
                    *ngIf="eventTablePoints.neutralAdvantageCount">{{eventTablePoints.neutralAdvantageCount}}</span><br>
                <span
                    *ngIf="eventTablePoints.neutralAdvantageCountPercentage">{{eventTablePoints.neutralAdvantageCountPercentage}}%</span>
            </td>
            <td scope="col" [class.fw-bold]="eventTablePoints.aAdvantageCountPercentage > 50">
                <span *ngIf="eventTablePoints.aAdvantageCount">{{eventTablePoints.aAdvantageCount}}</span><br>
                <span
                    *ngIf="eventTablePoints.aAdvantageCountPercentage">{{eventTablePoints.aAdvantageCountPercentage}}%</span>
            </td>
        </tr>
    </tbody>
</table>
<table class="table my-md-3 bg-light table-borderless table-sm text-center fw-light" *ngIf="eventTablePoints">
    <caption>Conclusion</caption>
    <thead>
        <tr>
            <th scope="col">Greater Than</th>
            <th scope="col">50%</th>
            <th scope="col">60%</th>
            <th scope="col">70%</th>
            <th scope="col">80%</th>
            <th scope="col">90%</th>
            <th scope="col">100%</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td scope="col">Advantage</td>
            <td scope="col">
                <span *ngIf="eventTablePoints.advantage51">{{eventTablePoints.advantage51}}</span><br>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.advantage60">{{eventTablePoints.advantage60}}</span><br>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.advantage70">{{eventTablePoints.advantage70}}</span><br>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.advantage80">{{eventTablePoints.advantage80}}</span><br>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.advantage90">{{eventTablePoints.advantage90}}</span><br>
            </td>
            <td scope="col">
                <span *ngIf="eventTablePoints.advantage100">{{eventTablePoints.advantage100}}</span><br>
            </td>

        </tr>
    </tbody>
</table>

<!-- table analysis -->
<table class="table table-sm flex-fill" *ngIf="eventTableAnalysis">
    <caption>
        <span *ngIf="matchEvent">
            <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}} Vs<br></span>
            <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
        </span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th>#</th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.homeTeamNameLong">{{matchEvent.homeTeamNameLong}}</span>
                </span>
            </th>
            <th class="teams text-start w-25">
                <span *ngIf="matchEvent">
                    <span *ngIf="matchEvent.awayTeamNameLong">{{matchEvent.awayTeamNameLong}}</span>
                </span>
            </th>
        </tr>
    </thead>

    <tbody *ngIf="
    eventTableAnalysis.posDiffHHomePercentage01 || 
    eventTableAnalysis.posDiffHHomePercentage20 ||
    eventTableAnalysis.posDiffHHomePercentage30 ||
    eventTableAnalysis.posDiffHHomePercentage40 ||
    eventTableAnalysis.posDiffHHomePercentage50 ||
    eventTableAnalysis.posDiffHAwayPercentage01 || 
    eventTableAnalysis.posDiffHAwayPercentage20 ||
    eventTableAnalysis.posDiffHAwayPercentage30 ||
    eventTableAnalysis.posDiffHAwayPercentage40 ||
    eventTableAnalysis.posDiffHAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Pos Home</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.posDiffHHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.posDiffHAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.posDiffAHomePercentage01 || 
    eventTableAnalysis.posDiffAHomePercentage20 ||
    eventTableAnalysis.posDiffAHomePercentage30 ||
    eventTableAnalysis.posDiffAHomePercentage40 ||
    eventTableAnalysis.posDiffAHomePercentage50 ||
    eventTableAnalysis.posDiffAAwayPercentage01 || 
    eventTableAnalysis.posDiffAAwayPercentage20 ||
    eventTableAnalysis.posDiffAAwayPercentage30 ||
    eventTableAnalysis.posDiffAAwayPercentage40 ||
    eventTableAnalysis.posDiffAAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Pos Away</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.posDiffAHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.posDiffAAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.posDiffHomePercentage01 || 
    eventTableAnalysis.posDiffHomePercentage20 ||
    eventTableAnalysis.posDiffHomePercentage30 ||
    eventTableAnalysis.posDiffHomePercentage40 ||
    eventTableAnalysis.posDiffHomePercentage50 ||
    eventTableAnalysis.posDiffAwayPercentage01 || 
    eventTableAnalysis.posDiffAwayPercentage20 ||
    eventTableAnalysis.posDiffAwayPercentage30 ||
    eventTableAnalysis.posDiffAwayPercentage40 ||
    eventTableAnalysis.posDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Pos Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.posDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.posDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.posDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamWinDiffHHomePercentage01 || 
    eventTableAnalysis.teamWinDiffHHomePercentage20 ||
    eventTableAnalysis.teamWinDiffHHomePercentage30 ||
    eventTableAnalysis.teamWinDiffHHomePercentage40 ||
    eventTableAnalysis.teamWinDiffHHomePercentage50 ||
    eventTableAnalysis.teamWinDiffHAwayPercentage01 || 
    eventTableAnalysis.teamWinDiffHAwayPercentage20 ||
    eventTableAnalysis.teamWinDiffHAwayPercentage30 ||
    eventTableAnalysis.teamWinDiffHAwayPercentage40 ||
    eventTableAnalysis.teamWinDiffHAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win Home</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamWinDiffHHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamWinDiffHAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamWinDiffAHomePercentage01 || 
    eventTableAnalysis.teamWinDiffAHomePercentage20 ||
    eventTableAnalysis.teamWinDiffAHomePercentage30 ||
    eventTableAnalysis.teamWinDiffAHomePercentage40 ||
    eventTableAnalysis.teamWinDiffAHomePercentage50 ||
    eventTableAnalysis.teamWinDiffAAwayPercentage01 || 
    eventTableAnalysis.teamWinDiffAAwayPercentage20 ||
    eventTableAnalysis.teamWinDiffAAwayPercentage30 ||
    eventTableAnalysis.teamWinDiffAAwayPercentage40 ||
    eventTableAnalysis.teamWinDiffAAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win Away</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamWinDiffAHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamWinDiffAAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamWinDiffHomePercentage01 || 
    eventTableAnalysis.teamWinDiffHomePercentage20 ||
    eventTableAnalysis.teamWinDiffHomePercentage30 ||
    eventTableAnalysis.teamWinDiffHomePercentage40 ||
    eventTableAnalysis.teamWinDiffHomePercentage50 ||
    eventTableAnalysis.teamWinDiffAwayPercentage01 || 
    eventTableAnalysis.teamWinDiffAwayPercentage20 ||
    eventTableAnalysis.teamWinDiffAwayPercentage30 ||
    eventTableAnalysis.teamWinDiffAwayPercentage40 ||
    eventTableAnalysis.teamWinDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Win Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamWinDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamWinDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamWinDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamLossDiffHHomePercentage01 || 
    eventTableAnalysis.teamLossDiffHHomePercentage20 ||
    eventTableAnalysis.teamLossDiffHHomePercentage30 ||
    eventTableAnalysis.teamLossDiffHHomePercentage40 ||
    eventTableAnalysis.teamLossDiffHHomePercentage50 ||
    eventTableAnalysis.teamLossDiffHAwayPercentage01 || 
    eventTableAnalysis.teamLossDiffHAwayPercentage20 ||
    eventTableAnalysis.teamLossDiffHAwayPercentage30 ||
    eventTableAnalysis.teamLossDiffHAwayPercentage40 ||
    eventTableAnalysis.teamLossDiffHAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss Home</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamLossDiffHHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamLossDiffHAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamLossDiffAHomePercentage01 || 
    eventTableAnalysis.teamLossDiffAHomePercentage20 ||
    eventTableAnalysis.teamLossDiffAHomePercentage30 ||
    eventTableAnalysis.teamLossDiffAHomePercentage40 ||
    eventTableAnalysis.teamLossDiffAHomePercentage50 ||
    eventTableAnalysis.teamLossDiffAAwayPercentage01 || 
    eventTableAnalysis.teamLossDiffAAwayPercentage20 ||
    eventTableAnalysis.teamLossDiffAAwayPercentage30 ||
    eventTableAnalysis.teamLossDiffAAwayPercentage40 ||
    eventTableAnalysis.teamLossDiffAAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss Away</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamLossDiffAHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamLossDiffAAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamLossDiffHomePercentage01 || 
    eventTableAnalysis.teamLossDiffHomePercentage20 ||
    eventTableAnalysis.teamLossDiffHomePercentage30 ||
    eventTableAnalysis.teamLossDiffHomePercentage40 ||
    eventTableAnalysis.teamLossDiffHomePercentage50 ||
    eventTableAnalysis.teamLossDiffAwayPercentage01 || 
    eventTableAnalysis.teamLossDiffAwayPercentage20 ||
    eventTableAnalysis.teamLossDiffAwayPercentage30 ||
    eventTableAnalysis.teamLossDiffAwayPercentage40 ||
    eventTableAnalysis.teamLossDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Loss Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamLossDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamLossDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamLossDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamDrawDiffHHomePercentage01 || 
    eventTableAnalysis.teamDrawDiffHHomePercentage20 ||
    eventTableAnalysis.teamDrawDiffHHomePercentage30 ||
    eventTableAnalysis.teamDrawDiffHHomePercentage40 ||
    eventTableAnalysis.teamDrawDiffHHomePercentage50 ||
    eventTableAnalysis.teamDrawDiffHAwayPercentage01 || 
    eventTableAnalysis.teamDrawDiffHAwayPercentage20 ||
    eventTableAnalysis.teamDrawDiffHAwayPercentage30 ||
    eventTableAnalysis.teamDrawDiffHAwayPercentage40 ||
    eventTableAnalysis.teamDrawDiffHAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw Home</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamDrawDiffHHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamDrawDiffHAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamDrawDiffAHomePercentage01 || 
    eventTableAnalysis.teamDrawDiffAHomePercentage20 ||
    eventTableAnalysis.teamDrawDiffAHomePercentage30 ||
    eventTableAnalysis.teamDrawDiffAHomePercentage40 ||
    eventTableAnalysis.teamDrawDiffAHomePercentage50 ||
    eventTableAnalysis.teamDrawDiffAAwayPercentage01 || 
    eventTableAnalysis.teamDrawDiffAAwayPercentage20 ||
    eventTableAnalysis.teamDrawDiffAAwayPercentage30 ||
    eventTableAnalysis.teamDrawDiffAAwayPercentage40 ||
    eventTableAnalysis.teamDrawDiffAAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw Away</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamDrawDiffAHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamDrawDiffAAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.teamDrawDiffHomePercentage01 || 
    eventTableAnalysis.teamDrawDiffHomePercentage20 ||
    eventTableAnalysis.teamDrawDiffHomePercentage30 ||
    eventTableAnalysis.teamDrawDiffHomePercentage40 ||
    eventTableAnalysis.teamDrawDiffHomePercentage50 ||
    eventTableAnalysis.teamDrawDiffAwayPercentage01 || 
    eventTableAnalysis.teamDrawDiffAwayPercentage20 ||
    eventTableAnalysis.teamDrawDiffAwayPercentage30 ||
    eventTableAnalysis.teamDrawDiffAwayPercentage40 ||
    eventTableAnalysis.teamDrawDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Draw Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.teamDrawDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.teamDrawDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.teamDrawDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>

    <tbody *ngIf="
    eventTableAnalysis.goalDiffHHomePercentage01 || 
    eventTableAnalysis.goalDiffHHomePercentage20 ||
    eventTableAnalysis.goalDiffHHomePercentage30 ||
    eventTableAnalysis.goalDiffHHomePercentage40 ||
    eventTableAnalysis.goalDiffHHomePercentage50 ||
    eventTableAnalysis.goalDiffHAwayPercentage01 || 
    eventTableAnalysis.goalDiffHAwayPercentage20 ||
    eventTableAnalysis.goalDiffHAwayPercentage30 ||
    eventTableAnalysis.goalDiffHAwayPercentage40 ||
    eventTableAnalysis.goalDiffHAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals Home</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.goalDiffHHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.goalDiffHAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.goalDiffAHomePercentage01 || 
    eventTableAnalysis.goalDiffAHomePercentage20 ||
    eventTableAnalysis.goalDiffAHomePercentage30 ||
    eventTableAnalysis.goalDiffAHomePercentage40 ||
    eventTableAnalysis.goalDiffAHomePercentage50 ||
    eventTableAnalysis.goalDiffAAwayPercentage01 || 
    eventTableAnalysis.goalDiffAAwayPercentage20 ||
    eventTableAnalysis.goalDiffAAwayPercentage30 ||
    eventTableAnalysis.goalDiffAAwayPercentage40 ||
    eventTableAnalysis.goalDiffAAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals Away</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.goalDiffAHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.goalDiffAAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="
    eventTableAnalysis.goalDiffHomePercentage01 || 
    eventTableAnalysis.goalDiffHomePercentage20 ||
    eventTableAnalysis.goalDiffHomePercentage30 ||
    eventTableAnalysis.goalDiffHomePercentage40 ||
    eventTableAnalysis.goalDiffHomePercentage50 ||
    eventTableAnalysis.goalDiffAwayPercentage01 || 
    eventTableAnalysis.goalDiffAwayPercentage20 ||
    eventTableAnalysis.goalDiffAwayPercentage30 ||
    eventTableAnalysis.goalDiffAwayPercentage40 ||
    eventTableAnalysis.goalDiffAwayPercentage50 ">
        <tr class="cursor-pointer status-live small-tx fw-light">
            <td>Goals Overall</td>
            <td class="time text-start w-25">
                <span *ngIf="eventTableAnalysis.goalDiffHomePercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHomePercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHomePercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHomePercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffHomePercentage50">Ov50%<br></span>
            </td>
            <td class="teams text-start w-25">
                <span *ngIf="eventTableAnalysis.goalDiffAwayPercentage01">Ov0%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAwayPercentage20">Ov20%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAwayPercentage30">Ov30%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAwayPercentage40">Ov40%<br></span>
                <span *ngIf="eventTableAnalysis.goalDiffAwayPercentage50">Ov50%<br></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- analysis points -->
<table class="table table-sm flex-fill" *ngIf="tableAnalysisPoints$">
    <caption>
        <Span>Key<br></Span>
        <Span><br></Span>
        <Span>While at Home<br></Span>
        <Span>1 :    Win<br></Span>
        <Span>2 :    Draw<br></Span>
        <Span>3 :    Loss<br></Span>
        <Span>4 :    Position<br></Span>
        <Span>While Away<br></Span>
        <Span>5 :    Win<br></Span>
        <Span>6 :    Draw<br></Span>
        <Span>7 :    Loss<br></Span>
        <Span>8 :    Position<br></Span>
        <Span>Cumulative<br></Span>
        <Span>9 :    Win<br></Span>
        <Span>10 :    Draw<br></Span>
        <Span>11 :    Loss<br></Span>
        <Span>12 :    Position<br></Span>
    </caption>
    <thead class="table-light">
        <tr class="small-tx">
            <th class="teams text-start">1</th>
            <th class="teams text-start">2</th>
            <th class="teams text-start">3</th>
            <th class="teams text-start">4</th>
            <th class="teams text-start">5</th>
            <th class="teams text-start">6</th>
            <th class="teams text-start">7</th>
            <th class="teams text-start">8</th>
            <th class="teams text-start">9</th>
            <th class="teams text-start">10</th>
            <th class="teams text-start">11</th>
            <th class="teams text-start">12</th>
            <th class="teams text-start">Result 1X2</th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor-pointer status-live small-tx">
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.winsHomeAdvantage">{{tableAnalysisPoints$.winsHomeAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.drawHomeAdvantage">{{tableAnalysisPoints$.drawHomeAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.lossHomeAdvantage">{{tableAnalysisPoints$.lossHomeAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.posHomeAdvantage">{{tableAnalysisPoints$.posHomeAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.winsAwayAdvantage">{{tableAnalysisPoints$.winsAwayAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.drawAwayAdvantage">{{tableAnalysisPoints$.drawAwayAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.lossAwayAdvantage">{{tableAnalysisPoints$.lossAwayAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.posAwayAdvantage">{{tableAnalysisPoints$.posAwayAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.winsAdvantage">{{tableAnalysisPoints$.winsAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.drawAdvantage">{{tableAnalysisPoints$.drawAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.lossAdvantage">{{tableAnalysisPoints$.lossAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span *ngIf="tableAnalysisPoints$.posAdvantage">{{tableAnalysisPoints$.posAdvantage}}</span>
            </td>
            <td class="teams text-start">
                <span></span>
            </td>
        </tr>
    </tbody>
</table>