import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Competition } from 'src/app/core/interface/competition';
import { DateTime } from 'src/app/core/interface/date-time';
import { ApiService } from 'src/app/core/service/api.service';
import { DateTimeService } from 'src/app/core/service/date-time.service';
import { FootballService } from 'src/app/core/service/football.service';

@Component({
  selector: 'app-analysis-onextwo-s3-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './analysis-onextwo-s3-list.component.html',
  styleUrls: ['./analysis-onextwo-s3-list.component.scss']
})
export class AnalysisOnextwoS3ListComponent implements OnInit, OnDestroy {
  id: any;
  dateForm: FormGroup;
  /* dateToday: string; */
  subscription: Subscription = new Subscription();
  todayData: any;
  dates = [];
  datePicked: string;
  requestClick: boolean;
  date$: string;
  analysisPointsList$: any;
  analysisPointsObject$: any;
  filteredAnalysisPointsList$: any;
  eventObject: any;
  filteredEventObject: any;
  competition$: (Competition & { key: string; })[];
  isSearchMode: boolean = false;

  constructor(
    private footballService: FootballService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('id1');
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.checkDataAvailableToday();
  }
  checkDataAvailableToday() {
    /* get date today */
    /* check if events today are more than 1 */
    /* date today add day six times while checking more than 1 event */
    /* display date boxes green/red */

    this.subscription.add(this.dateTimeService.getCurrentDate().pipe(first()).subscribe(
      (dateTime: DateTime) => {
        this.date$ = dateTime.date;
      }));
  }

  initializeForm() {
    this.dateForm = this.fb.group({
      date: [0],
    });

    this.dateForm.get("date").valueChanges.subscribe(x => {
      this.requestClick = false;

      let day = x.slice(8, 10);
      let month = x.slice(5, 7);
      let year = x.slice(0, 4);
      let date = `${year}-${month}-${day}`;
      this.datePicked = date;
      this.subscription.add(this.footballService.getAllCompetitionByDate(date)
        .subscribe((competitions) => {
          //this.responses$ = response;
          this.competition$ = competitions;
        }));

      //Get H2H Date List
      this.subscription.add(this.footballService.get1X2S3ExtractList(date).pipe(first())
        .subscribe((analysisPointsList) => {
          //this.responses$ = response;
          this.filteredAnalysisPointsList$ = this.analysisPointsList$ = analysisPointsList;
        }))
      this.subscription.add(this.footballService.get1X2S3ExtractList_Object(date).pipe(first())
        .subscribe((analysisPointsObject) => {
          //this.responses$ = response;
          this.analysisPointsObject$ = analysisPointsObject;
        }))
      this.subscription.add(this.footballService.getAllEventsByDate_Object(date)
        .subscribe((eventObject) => {
          //this.responses$ = response;
          this.filteredEventObject = this.eventObject = eventObject;
        }));
    })
  }

  edit(countryId: string) {

  }

  filter(query) {
    if (query === "" || !query) {
      this.isSearchMode = false;
    } else {
      this.isSearchMode = true;
    }

    this.filteredAnalysisPointsList$ = (query) ?
      this.analysisPointsList$.filter(c => (c.homeTeamNameLong + " " + c.awayTeamNameLong).toLowerCase().includes(query.toLowerCase())) :
      this.analysisPointsList$;
  }

  redirect(eDate, eventKey: number | string) {
    //Create interest data for date
    //Record Click ${date}/interest/${gameId}
    //*Note Time ZOne Matters!!!
    if (eDate && eventKey) {
      this.router.navigate([`/football/match/${eventKey}`], { queryParams: { "date": eDate } });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}